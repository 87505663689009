import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import 'react-chat-elements/dist/main.css';
import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
// import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Avatar,
  ConversationHeader,
  Sidebar,
  ConversationList,
  Search,
  InputToolbox
} from '@chatscope/chat-ui-kit-react';
import { RiGroupLine } from 'react-icons/ri';
import AddGroupModal from './group/add-group-modal';
import { useGetGroupMessagesById, useGetGroups } from 'src/hooks/query/vrc';
import i18next from 'i18next';
import {
  getImageFromServer,
  getUserImageFromServer
} from 'src/utils/helpers/get-image-from-server';
import { vrcGroupMessage, vrcGroupResponse } from 'src/models/vrc';
import VrcInput from './components/vrc-input';
import ConversationComponent from './components/conversation-component';
import MessageComponent from './components/message-component';
import MessageSkeleton from './components/message-skeleton';
import VrcHeaderActions from './components/vrc-header-actions';
import emptyChat from '../../assets/images/vrc/empty_chat.png';
import { useTranslation } from 'react-i18next';

const pusherKey = process.env.REACT_APP_PUSHER_KEY;
Pusher.logToConsole = true;

const VirtualClassRoom: React.FC = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));

  //init pusher and disconnect on unmount
  const [pusher, setPusher] = useState<any>(null);
  useEffect(() => {
    const pusher = new Pusher(pusherKey!, {
      cluster: 'mt1'
    });
    setPusher(pusher);

    return () => {
      pusher.disconnect();
    };
  }, []);

  //states for current group messages and active conversation and my groups
  const [groupMessages, setGroupMessages] = useState<vrcGroupMessage[]>([]);
  const [activeConversation, setActiveConversation] =
    useState<vrcGroupResponse>({} as vrcGroupResponse);

  const [myGroups, setMyGroups] = useState<vrcGroupResponse[]>(
    [] as vrcGroupResponse[]
  );

  //get groups
  const {
    data: groups,
    isLoading,
    isError,
    isFetching
  } = useGetGroups((data) => {
    setMyGroups(data);
  });

  //get group messages
  const {
    data: Messages,
    refetch: refetchMessages,
    isLoading: messagesLoading,
    isFetching: messagesFetching
  } = useGetGroupMessagesById(activeConversation.id, (data) => {
    setGroupMessages(data);
  });

  //setting active conversation logic
  const handleConversationClick = (id: number) => {
    myGroups?.forEach((conversation) => {
      if (conversation.id === id) {
        conversation.unread_message_count = 0;
      }
    });
    const activeConversationFound = myGroups.find(
      (conversation) => conversation.id == id
    );
    if (activeConversationFound) setActiveConversation(activeConversationFound);
    refetchMessages();
  };

  //listen to group channel on change of active conversation
  useEffect(() => {
    if (activeConversation && activeConversation.id) {
      const channel = pusher?.subscribe(`channel${activeConversation.id}`);

      // Bind to the specific channel related to activeConversation.id
      channel?.bind(`message`, (data: any) => {
        console.log('new message', data);
        setGroupMessages((prevMessages) => [...prevMessages, data]);
      });

      // Clean up subscription on unmount
      return () => {
        channel?.unbind(`message`);
        pusher?.unsubscribe(`channel${activeConversation.id}`);
      };
    }
  }, [activeConversation.id, pusher]);

  useEffect(() => {
    if (user && user.id) {
      const channel = pusher?.subscribe(`user${user.id}`);
      // Bind to the specific channel related to user.id
      channel?.bind(`UserEvents`, (data: any) => {
        console.log('new User Event', data);
        setMyGroups((prevGroups) => {
          const editedGroupIndex = prevGroups?.findIndex(
            (group) => group.id === data.room.id
          );
          if (editedGroupIndex !== -1) {
            const updatedGroups = [...prevGroups];
            updatedGroups.splice(editedGroupIndex, 1); // Remove the edited group from its current position
            updatedGroups.unshift({ ...data.room }); // Insert the edited group at the beginning of the array
            return updatedGroups;
          } else {
            return [data.room, ...prevGroups]; // Add new group to the beginning
          }
        });
        // Update active conversation with the latest value
        setActiveConversation((prevActiveConversation) => {
          if (
            prevActiveConversation &&
            prevActiveConversation.id === data.room.id
          ) {
            return data.room;
          }
          return prevActiveConversation;
        });
      });

      // Clean up subscription on unmount
      return () => {
        channel?.unbind(`UserEvents`);
        pusher?.unsubscribe(`user${user.id}`);
      };
    }
  }, [user?.id, pusher]);

  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} height={'80vh'}>
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <>
      <AddGroupModal open={open} setOpen={setOpen} />
      <Box
        sx={{
          height: '90vh',
          position: 'relative',
          padding: { xs: '0', sm: '40px 0' }
        }}
        className="container"
      >
        <MainContainer responsive={activeConversation?.id != undefined}>
          <Sidebar
            position="left"
            scrollable={true}
            style={{
              minWidth: activeConversation?.id == undefined ? '100%' : ''
            }}
          >
            <Button
              variant="contained"
              sx={{ margin: 1.5 }}
              onClick={() => setOpen(true)}
            >
              <Stack
                gap={1}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography>Create New Group</Typography>
                <RiGroupLine fontSize={'17px'} />
              </Stack>
            </Button>
            <Search
              placeholder={t('HeaderNavSearch')}
              onChange={(value) => {
                if (groups)
                  setMyGroups(
                    groups.filter((conversation) =>
                      conversation.name
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    )
                  );
              }}
              onClearClick={() => setMyGroups(groups!)}
            />
            {myGroups && myGroups.length > 0 ? (
              <>
                <ConversationList>
                  {myGroups?.map((conversation) => (
                    <ConversationComponent
                      key={conversation.id}
                      conversation={conversation}
                      handleConversationClick={handleConversationClick}
                      activeConversation={activeConversation}
                      userId={user.id}
                    />
                  ))}
                </ConversationList>
              </>
            ) : !isFetching ? (
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                height={'80vh'}
              >
                <img
                  src={emptyChat}
                  alt="empty-chat"
                  width={400}
                  height={400}
                />
                <Typography>You Don't Have Any Class Room</Typography>
              </Stack>
            ) : null}
          </Sidebar>
          {activeConversation?.name && (
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Back
                  onClick={() => setActiveConversation({} as vrcGroupResponse)}
                  style={{
                    transform:
                      i18next.language === 'ar' ? 'rotateY(180deg)' : 'none', // Conditionally apply transform based on language
                    cursor: 'pointer' // Add cursor pointer for better UX
                  }}
                />
                <Avatar
                  src={getUserImageFromServer(activeConversation.image)}
                  name={activeConversation.name}
                />
                <ConversationHeader.Content
                  userName={activeConversation.name}
                  // info={activeConversation.info}
                />
                <ConversationHeader.Actions style={{ gap: 10 }}>
                  <VrcHeaderActions activeConversations={activeConversation} />
                </ConversationHeader.Actions>
              </ConversationHeader>

              {messagesLoading || messagesFetching ? (
                <MessageList>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <MessageSkeleton key={index} index={index} />
                  ))}
                </MessageList>
              ) : (
                <MessageList>
                  {groupMessages?.map((msg, index) => (
                    <MessageComponent msg={msg} userId={user?.id} key={index} />
                  ))}
                </MessageList>
              )}
              <InputToolbox style={{ width: '100%' }}>
                <VrcInput activeConversationId={activeConversation.id} />
              </InputToolbox>
            </ChatContainer>
          )}
        </MainContainer>
      </Box>
    </>
  );
};

export default VirtualClassRoom;

import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { groupResponse } from 'src/models/CRM';

type Props = {
  groups: groupResponse[];
  control: any;
  errors: any;
};

const AddGroups = (props: Props) => {
  return (
    <Controller
      name="group"
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="groups-filled"
            filterSelectedOptions
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option === value}
            options={props.groups.map((group: groupResponse) => group.id)}
            getOptionLabel={(option) => {
              const group = props.groups.find((group) => group.id === option);
              return group ? group.name : option;
            }}
            freeSolo
            renderTags={(value: number[], getgroupProps) =>
              value.map((option: number, index: number) => {
                const group = props.groups.find((group) => group.id === option);
                return (
                  <Chip
                    variant="outlined"
                    label={group ? group.name : option}
                    {...getgroupProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add groups"
                error={props.errors && !!props.errors.group}
                helperText={
                  props.errors &&
                  props.errors.group &&
                  props.errors?.group?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the group IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddGroups;

import { Box, Container, Tab, Tabs } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/components/PageHeader';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import useDebounce from 'src/hooks/useDebounce';
import CustomersTable from './customers-table';
import AddCustomerModal from './create-customer/add-customer-modal';
import useCustomers from 'src/hooks/query/CRM/customers/use-customers';
import { fetchStudents } from 'src/services/students';
import { useQuery } from 'react-query';
import UsersTable from './users-table';
import AddStudentModal from 'src/content/students/CreateStudent/add-student-modal';
import AddToGroupModal from './add-to-group-modal';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import SendEmailModal from './send-email-modal';
import SendWhatsappModal from './send-whatsapp-modal';
import CustomerDeleteModal from './customer-delete-modal';
import SendSmsModal from './send-sms-modal';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

const Customers = () => {
  const [searchValue, setSearchValue] = React.useState('');
  const search = useDebounce(searchValue);

  const { data, isLoading, isFetching } = useCustomers(search);
  const {
    data: students,
    isLoading: loadingStudents,
    isError,
    isFetching: fetchingStudents
  } = useQuery(['students'], () => fetchStudents());

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [id, setId] = useState();

  const [userOpen, setUserOpen] = React.useState(false);
  const [userId, setUserId] = useState();

  const [groupsOpen, setGroupsOpen] = React.useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [WahtsappOpen, setWhatsappOpen] = React.useState(false);
  const [smsOpen, setSmsOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const [selected, setSelected] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [mergedSelected, setMergedSelected] = React.useState([]);

  useEffect(() => {
    setMergedSelected([...selected, ...selectedUsers]);
  }, [selected, selectedUsers]);

  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //select menu for send button state and methods
  const [menuItems, setMenuItems] = useState([
    {
      label: 'Email',
      icon: <MdOutlineAlternateEmail />,
      action: () => setEmailOpen(true)
    },
    {
      label: 'Whatsapp',
      icon: <FaWhatsapp />,
      // action: () => setWhatsappOpen(true)
      action: () => {}
    },
    {
      label: 'SMS',
      icon: <TfiEmail />,
      // action: () => setEmailOpen(true)
      action: () => {
        setSmsOpen(true);
      }
    }
  ]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          title="Customers"
          menuItems={menuItems}
          handleClick={handleClickSelect}
          handleClose={handleCloseSelect}
          anchorEl={anchorEl}
          contained={
            value == 0 && selected?.length == 0 ? 'Create Customer' : ''
          }
          onClick={handleClick}
          selected={
            selected.length + selectedUsers.length > 0
              ? [
                  `Send a Message ${
                    mergedSelected.length > 0
                      ? ` (${mergedSelected.length})`
                      : ''
                  }`,
                  `Add To Group ${
                    mergedSelected.length > 0
                      ? ` (${mergedSelected.length})`
                      : ''
                  }`
                ]
              : ''
          }
          onClickSelected={[
            () => {},
            () => {
              setGroupsOpen(true);
            }
          ]}
        />
        <Box sx={{ paddingTop: '20px' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Customers" {...a11yProps(0)} />
            <Tab label="Users" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </PageTitleWrapper>
      <Container>
        <CustomTabPanel value={value} index={0}>
          <CustomersTable
            setId={setId}
            setOpen={setOpen}
            isLoading={isLoading}
            data={data}
            isFetching={isFetching}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selected={selected}
            setSelected={setSelected}
            setDeleteOpen={setDeleteOpen}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UsersTable
            setId={setUserId}
            setOpen={setUserOpen}
            isLoading={loadingStudents}
            data={students}
            isFetching={fetchingStudents}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selected={selectedUsers}
            setSelected={setSelectedUsers}
          />
        </CustomTabPanel>
      </Container>
      <CustomerDeleteModal
        customerId={id}
        modalOpen={deleteOpen}
        handleModalClose={() => setDeleteOpen(false)}
      />
      <AddCustomerModal setId={setId} id={id} open={open} setOpen={setOpen} />
      <AddStudentModal
        setId={setUserId}
        id={userId}
        open={userOpen}
        setOpen={setUserOpen}
      />
      <AddToGroupModal
        open={groupsOpen}
        setOpen={setGroupsOpen}
        selectedCustomers={mergedSelected}
      />
      <SendEmailModal
        open={emailOpen}
        setOpen={setEmailOpen}
        selectedCustomers={mergedSelected}
      />
      <SendWhatsappModal
        open={WahtsappOpen}
        setOpen={setWhatsappOpen}
        selectedCustomers={mergedSelected}
      />
      <SendSmsModal
        open={smsOpen}
        setOpen={setSmsOpen}
        selectedCustomers={mergedSelected}
      />
    </>
  );
};

export default Customers;

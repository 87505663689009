import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { tagResponse } from 'src/models/tag';
import { defaultTranslation } from 'src/utils/helpers/default-translation';

type Props = {
  tags: tagResponse[];
  control: any;
  errors: any;
};

const AddTags = (props: Props) => {
  return (
    <Controller
      name="tags"
      control={props.control}
      defaultValue={[]}
      rules={{ required: 'Tags is required!' }}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="tags-filled"
            options={props.tags.map((tag: tagResponse) => tag.id)}
            getOptionLabel={(option) => {
              const tag = props.tags.find((tag) => tag.id === option);
              return tag ? defaultTranslation(tag, 'name') : option;
            }}
            freeSolo
            renderTags={(value: number[], getTagProps) =>
              value.map((option: number, index: number) => {
                const tag = props.tags.find((tag) => tag.id === option);
                return (
                  <Chip
                    variant="outlined"
                    label={tag ? defaultTranslation(tag, 'name') : option}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Tags *"
                error={props.errors && !!props.errors.tags}
                helperText={
                  props.errors &&
                  props.errors.tags &&
                  props.errors?.tags?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the tag IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddTags;

import axios from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { fetchApi } from 'src/api/api';

interface Props {
  testWatch: Function;
  testReset: Function;
  handleaddTestModalClose: Function;
  courseId: number;
}

const useAddTestMutation = ({
  testWatch,
  testReset,
  handleaddTestModalClose,
  courseId
}: Props) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem('token')?.toString().slice(1, -1);
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [excelLoading, setExcelLoading] = useState(false);

  const {
    isLoading,
    isError,
    mutateAsync: createTest
  } = useMutation((data: any) => fetchApi('/test/test', 'POST', data));

  const addTest = async (data: any) => {
    if (!data.file_url) delete data.file_url;
    if (!data.total_mark) delete data.total_mark;
    const response = await createTest(data);
    if (response.status === 200 || response.status === 201) {
      const testId = response.data.data.id;
      if (testWatch('is_excel')) {
        setExcelLoading(true);
        const formData = new FormData();
        formData.append('test_id', testId);
        formData.append('file', data.file_url);
        formData.append('test_type', 'test');
        try {
          const contentResponse = await axios.post(
            `${apiRoot}/test/test/createTestContent`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          if (
            contentResponse.status === 200 ||
            contentResponse.status === 201
          ) {
            setExcelLoading(false);
            toast.success(
              i18next.language === 'en'
                ? 'Test created successfully'
                : 'تم انشاء الاختبار بنجاح'
            );
            testReset({});
            handleaddTestModalClose();
            queryClient.invalidateQueries(['get-sections', courseId]);
          } else {
            setExcelLoading(false);
            handleaddTestModalClose();
            testReset({});
            queryClient.invalidateQueries(['get-sections', courseId]);
            toast.error(
              i18next.language === 'en'
                ? 'Something went wrong, please try again later.'
                : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
            );
          }
        } catch (error) {
          setExcelLoading(false);
          testReset({});
          handleaddTestModalClose();
          queryClient.invalidateQueries(['get-sections', courseId]);
          toast.error(
            i18next.language === 'en'
              ? 'Something went wrong, please try again later.'
              : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
          );
        }
      } else {
        toast.success(
          i18next.language === 'en'
            ? 'Test Added successfully'
            : 'تم إنشاء الاختبار بنجاح'
        );
        testReset({});
        handleaddTestModalClose();
        queryClient.invalidateQueries(['get-sections', courseId]);
      }
    } else {
      toast.error(
        i18next.language === 'en'
          ? 'Something went wrong, please try again later.'
          : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
      );
    }
  };

  return {
    addTest,
    isLoading: isLoading || excelLoading
  };
};

export default useAddTestMutation;

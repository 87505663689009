import { fetchApi } from 'src/api/api';
import { register, user, userStudent } from 'src/models/user';

export const fetchStudents = async (): Promise<userStudent[]> => {
  const response = await fetchApi(`/auth/user?role=student`, 'GET', {});
  return response.data.data as userStudent[];
};

export const postStudent = async (data: register): Promise<any> => {
  const response = await fetchApi(`/auth/user`, 'POST', data);
  return response.data as register;
};

export const updateStudent = async (
  data: register,
  id: number
): Promise<any> => {
  const response = await fetchApi(`/auth/user/${id}`, 'PUT', data);
  return response.data as register;
};

import { fetchApi } from 'src/api/api';
import { NotificationResponse } from 'src/models/notification';

export const getNotificationsNumber = async (): Promise<{ number: number }> => {
  const response = await fetchApi('/auth/notification/count/un-read');
  return response.data.data as { number: number };
};

export const getNotifications = async (
  page: number,
  perPage: number,
  isRead: boolean
): Promise<NotificationResponse> => {
  const isReadFilter = isRead ? '&filter[is_read]=1' : '';
  const response = await fetchApi(
    `/auth/notification?page=${page}&items_per_page=${perPage}${isReadFilter}`
  );
  return response.data as NotificationResponse;
};

export const markNotificationAsRead = async (id: number): Promise<any> => {
  const response = await fetchApi(`/auth/notification/${id}`, 'PUT');
  return response.data;
};

import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchApi } from 'src/api/api';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { fetchSections } from 'src/services/courses';
import { courseLessonUpdate, courseSection } from 'src/models/course';
import SectionModal from './section-modal';
import SectionItem from './section-item';
import { ErrorFallBackNotFound } from 'src/components/error-not-found-fallback';
import SectionDeleteModal from './section-delete-modal';

interface Props {
  id: number;
  handleNext?: any;
}

const CourseDND: React.FC<Props> = ({ id, handleNext }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [sections, setSections] = useState<courseSection[]>([]);
  const [saveStatus, setSaveStatus] = useState(false);

  const { isLoading, mutate } = useMutation(
    (data: any) => fetchApi(`/course/course/updateOrders`, 'PUT', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-sections', id]);
        toast.success(
          i18next.language === 'en'
            ? 'Course Edited successfully'
            : 'تم تعديل الدورة بنجاح'
        );
        setSaveStatus(false);
      },
      onError: (error: any) => {
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const {
    isLoading: sectionsLoading,
    isError,
    data
  } = useQuery(['get-sections', id], () => fetchSections(`${id}`), {
    onSuccess: (data) => {
      setSections(data!);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const handleDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    //if no destenation
    if (!result.destination) return;

    const tempSections = [...sections!];
    setSaveStatus(true);

    if (destination.droppableId != 'course') {
      const destinationId = parseInt(destination.droppableId.match(/\d+/)[0]);
      const sourceId = parseInt(source.droppableId.match(/\d+/)[0]);

      const sourceSection = tempSections.find((obj) => obj.id === sourceId);
      const destenationSection = tempSections.find(
        (obj) => obj.id === destinationId
      );
      const [movedItem] = sourceSection!.lessons.splice(source.index, 1);
      destenationSection!.lessons.splice(destination.index, 0, {
        ...movedItem,
        order: destination.index + 1,
        section_id: destinationId
      });
    } else {
      const sourceId = parseInt(draggableId.match(/\d+/)[0]);
      const sourceSection = tempSections.find((obj) => obj.id === sourceId);

      const [movedItem] = tempSections!.splice(source.index, 1);
      tempSections!.splice(destination.index, 0, {
        ...movedItem,
        order: destination.index + 1
      });
    }

    const sectionsWithOrder = tempSections.map((section, index) => ({
      ...section,
      order: index + 1,
      lessons: section.lessons.map((lesson, lessonIndex) => ({
        ...lesson,
        order: lessonIndex + 1
      }))
    }));

    setSections(sectionsWithOrder);
  };

  const handleUpdateOrder = (section: courseSection[]) => {
    const sectionsWithOrder = section.map((section, index) => ({
      id: section.id,
      order: index + 1,
      lessons: section.lessons.map((lesson, lessonIndex) => ({
        id: lesson.id,
        section_id: lesson.section_id,
        order: lessonIndex + 1
      }))
    }));

    mutate({ sections: sectionsWithOrder });
  };

  const {
    control: sectionControl,
    handleSubmit: sectionHandleSubmit,
    formState: { isValid: sectionIsValid },
    reset: sectionReset
  } = useForm<courseLessonUpdate>({
    mode: 'onChange'
  });

  //section modal states
  const [sectionMethod, setSectionMethod] = useState<'create' | 'update'>(
    'create'
  );
  const [sectionModalInfo, setSectionModalInfo] = useState<courseSection>();
  const [sectionModalOpen, setSectionModalOpen] = useState(false);
  const handleSectionModalOpen = () => setSectionModalOpen(true);
  const handleSectionModalClose = () => setSectionModalOpen(false);

  //section  Delete modal states
  const [sectionDeleteModalOpen, setSectionDeleteModalOpen] = useState(false);
  const handleSectionDeleteModalOpen = () => setSectionDeleteModalOpen(true);
  const handleSectionDeleteModalClose = () => setSectionDeleteModalOpen(false);

  if (isError) return <ErrorFallBackNotFound />;
  if (sectionsLoading || isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <SectionModal
        modalInfo={sectionModalInfo}
        control={sectionControl}
        modalOpen={sectionModalOpen}
        handleModalClose={handleSectionModalClose}
        handleSubmit={sectionHandleSubmit}
        isValid={sectionIsValid}
        sectionMethod={sectionMethod}
        order={sections.length + 1}
      />

      <SectionDeleteModal
        sectionId={sectionModalInfo?.id}
        modalOpen={sectionDeleteModalOpen}
        courseId={id}
        handleModalClose={handleSectionDeleteModalClose}
        sectionOrder={sectionModalInfo?.order}
      />

      <Box sx={{ padding: '50px 0 50px 0' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            gap: '20px'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSectionMethod('create');
              sectionReset({
                name: '',
                name_en: '',
                description: '',
                description_en: ''
              });
              handleSectionModalOpen();
              setSectionModalInfo({ ...sectionModalInfo!, course_id: id });
            }}
            sx={{ marginBottom: '20px' }}
          >
            Add New Section
          </Button>
          <Button
            variant="contained"
            onClick={() => handleUpdateOrder(sections)}
            sx={{ marginBottom: '20px' }}
            disabled={!saveStatus}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ marginBottom: '20px' }}
            color="success"
          >
            Finish
          </Button>
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`course`} type="droppableItem">
            {(provided) => (
              <div ref={provided.innerRef}>
                {sections
                  ?.sort((a, b) => a.order - b.order)
                  .map((section, index: number) => (
                    <SectionItem
                      key={section.id}
                      id={`section-${section.id}`}
                      title={defaultTranslation(section, 'name')}
                      tasks={section.lessons}
                      index={index}
                      section_id={section.id}
                      section={section}
                      order={sections.length + 1}
                      sectionModalOpen={sectionModalOpen}
                      handleSectionModalOpen={handleSectionModalOpen}
                      handleSectionModalClose={handleSectionModalClose}
                      setSectionMethod={setSectionMethod}
                      setSectionModalInfo={setSectionModalInfo}
                      sectionReset={sectionReset}
                      handleSectionDeleteModalOpen={
                        handleSectionDeleteModalOpen
                      }
                    />
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {sections.length == 0 && (
        <Box sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5">Course Have No Sections Yet</Typography>
        </Box>
      )}
    </>
  );
};

export default CourseDND;

import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { customerResponse } from 'src/models/CRM';

type Props = {
  customers: customerResponse[];
  control: any;
  errors: any;
};

const AddCustomers = (props: Props) => {
  return (
    <Controller
      name="customers"
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="customers-filled"
            filterSelectedOptions
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={props.customers?.map((customer: customerResponse) => ({
              id: customer.id,
              type: 'customer'
            }))}
            getOptionLabel={(option) => {
              const customer = props.customers?.find(
                (customer) => customer.id == option.id
              );
              return customer
                ? customer.first_name + ' ' + customer.last_name
                : option.id;
            }}
            renderTags={(
              value: { id: number; type: 'customer' }[],
              getcustomerProps
            ) =>
              value.map(
                (option: { id: number; type: 'customer' }, index: number) => {
                  const customer = props.customers?.find(
                    (customer) => customer.id == option.id
                  );
                  return (
                    <Chip
                      variant="outlined"
                      label={
                        customer
                          ? customer.first_name + ' ' + customer.last_name
                          : option.id
                      }
                      {...getcustomerProps({ index })}
                    />
                  );
                }
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add customers"
                error={props.errors && !!props.errors.customer}
                helperText={
                  props.errors &&
                  props.errors.customer &&
                  props.errors?.customer?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the customer IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddCustomers;

import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { customerRequest } from 'src/models/CRM';
import { MuiPhone } from 'src/components/mui-phone';
import AddCustomer from 'src/hooks/query/CRM/customers/add-customer';
import useCustomer from 'src/hooks/query/CRM/customers/use-customer';
import UpdateCustomer from 'src/hooks/query/CRM/customers/update-customer';
import AddGroups from './add-groups';
import useGroups from 'src/hooks/query/CRM/groups/use-groups';

const inputStyle = {
  width: { xs: '100%', sm: '48%' },
  marginBottom: '30px',
  marginLeft: { sm: '16px' }
};
const AddCustomerForm = ({ setOpen, id }) => {
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<customerRequest>({
    defaultValues: {}
  });

  const queryClient = useQueryClient();

  const addCustomer = AddCustomer();

  const update = UpdateCustomer();

  const { data: customerData, isLoading, isFetching } = useCustomer(id);
  const {
    data: groups,
    isLoading: isLoadingGroups,
    isFetching: isFetchingGroups
  } = useGroups('');

  const onSubmit = (data) => {
    if (data?.group?.length == 0) delete data?.group;
    addCustomer
      .mutateAsync(data)
      .then((res) => {
        console.log(res);
        if (res.status === 201 || res.status === 202) {
          toast.success('Customer Added Successfuly');
          queryClient.invalidateQueries('customers');
          setOpen(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status == 422) {
          if (error?.response?.data?.errors?.email) {
            setError('email', {
              type: 'manual',
              message: 'Email already exist'
            });
          }
          if (error?.response?.data?.errors?.phone_number) {
            setError('phone_number', {
              type: 'manual',
              message: 'phone number already exist'
            });
          }
          if (error?.response?.data?.errors?.whatsapp_number) {
            setError('whatsapp_number', {
              type: 'manual',
              message: 'whatsapp number already exist'
            });
          }
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  const onUpdate = (data: customerRequest) => {
    if (data?.group?.length == 0) delete data?.group;
    update
      .mutateAsync({
        id,
        data
      })
      .then((res) => {
        if (res.status === 201 || res.status === 202) {
          toast.success('Customer Updated Successfuly');
          queryClient.invalidateQueries('customers');
          setOpen(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status == 422) {
          if (error?.response?.data?.errors?.email) {
            setError('email', {
              type: 'manual',
              message: 'Email already exist'
            });
          }
          if (error?.response?.data?.errors?.phone_number) {
            setError('phone_number', {
              type: 'manual',
              message: 'phone number already exist'
            });
          }
          if (error?.response?.data?.errors?.whatsapp_number) {
            setError('whatsapp_number', {
              type: 'manual',
              message: 'whatsapp number already exist'
            });
          }
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  useEffect(() => {
    if (customerData && id !== 0) {
      reset({
        ...customerData,
        group: customerData.groups.map((group) => group.id)
      });
      clearErrors();
      if (customerData.phone_number == customerData.whatsapp_number)
        setSameAsPhone(true);
    }
  }, [customerData, reset, id]);

  const [sameAsPhone, setSameAsPhone] = useState(false);

  const handleCheckboxChange = (event) => {
    setSameAsPhone(event.target.checked);
    if (event.target.checked) {
      setValue('whatsapp_number', watch('phone_number'));
    } else {
      setValue('whatsapp_number', ''); // Clear the value if the checkbox is unchecked
    }
  };

  useEffect(() => {
    if (sameAsPhone) {
      setValue('whatsapp_number', watch('phone_number'));
    }
  }, [watch('phone_number')]);

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  return (
    <Box>
      {isLoading || isFetching ? (
        <SuspenseLoader />
      ) : (
        <>
          <form
            onSubmit={
              id === 0 ? handleSubmit(onSubmit) : handleSubmit(onUpdate)
            }
          >
            <div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem'
                }}
              ></Box>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={inputStyle}
                    {...field}
                    label="First Name"
                    placeholder="First Name"
                    required
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    placeholder="Last Name"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  //   <TextField
                  //     {...field}
                  //     label="Phone Number"
                  //     required
                  //     sx={inputStyle}
                  //     error={!!errors.phone_number}
                  //     helperText={errors.phone_number?.message}
                  //     type="tel"
                  //   />
                  <MuiPhone
                    onChange={setValue}
                    value={watch('phone_number')}
                    controle_name="phone_number"
                    label={'Phone Number'}
                    sx={inputStyle}
                    forceDialCode={true}
                    setError={setError}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message}
                    clearError={() => clearErrors('phone_number')}
                    setIsPhoneValid={setIsPhoneValid}
                  />
                )}
              />

              <Box
                sx={{
                  ...inputStyle,
                  display: 'inline-flex',
                  alignItems: 'flex-start'
                }}
              >
                <FormControlLabel
                  sx={{ width: '30%' }}
                  control={
                    <Checkbox
                      checked={sameAsPhone}
                      onChange={handleCheckboxChange}
                      name="sameAsPhone"
                    />
                  }
                  label="The same as phone"
                />

                <Controller
                  name="whatsapp_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    // <TextField
                    //   {...field}
                    //   label="Whatsapp Number"
                    //   required
                    //   sx={{ width: '70%' }}
                    //   error={!!errors.whatsapp_number}
                    //   helperText={errors.whatsapp_number?.message}
                    //   type="tel"
                    //   disabled={sameAsPhone}
                    // />
                    <MuiPhone
                      onChange={setValue}
                      value={watch('whatsapp_number')}
                      controle_name="whatsapp_number"
                      disabled={sameAsPhone}
                      setError={setError}
                      label={'Whatsapp Number'}
                      sx={{ width: '70%' }}
                      forceDialCode={sameAsPhone ? false : true}
                      error={!!errors.whatsapp_number}
                      helperText={errors.whatsapp_number?.message}
                      clearError={() => clearErrors('whatsapp_number')}
                      setIsPhoneValid={setIsPhoneValid}
                    />
                  )}
                />
              </Box>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      clearErrors('email');
                      setValue('email', e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={inputStyle}
                  />
                )}
              />

              <Controller
                name="job_title"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Job Title"
                    placeholder="Job Title"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Box sx={inputStyle}>
                <AddGroups groups={groups} control={control} errors={errors} />
              </Box>
              <Controller
                name="is_vip"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={inputStyle}
                    control={<Checkbox {...field} checked={field.value} />}
                    label="VIP"
                  />
                )}
              />

              <Box sx={{ marginLeft: '16px' }}>
                {id === 0 ? (
                  <LoadingButton
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={addCustomer.isLoading}
                    disabled={isPhoneValid == false}
                  >
                    Add Customer
                  </LoadingButton>
                ) : (
                  <Stack gap={1}>
                    <LoadingButton
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={update.isLoading}
                      disabled={isPhoneValid == false}
                    >
                      Update Customer
                    </LoadingButton>
                  </Stack>
                )}
              </Box>
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default AddCustomerForm;

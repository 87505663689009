import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { userStudent } from "src/models/user";
import { defaultTranslation } from "src/utils/helpers/default-translation";

type Props = {
  users: userStudent[];
  control: any;
  errors: any;
};

const AddUsers = (props: Props) => {
  return (
    <Controller
      name="users"
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="users-filled"
            filterSelectedOptions
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option === value}
            options={props.users?.map((user: userStudent) => user.id)}
            getOptionLabel={(option) => {
              const user = props.users?.find((user) => user.id === option);
              return user
                ? defaultTranslation(user, "first_name") +
                    " " +
                    defaultTranslation(user, "last_name")
                : option.id;
            }}
            freeSolo
            renderTags={(value: number[], getuserProps) =>
              value.map((option: number, index: number) => {
                const user = props.users?.find((user) => user.id === option);
                return (
                  <Chip
                    variant="outlined"
                    label={
                      user
                        ? defaultTranslation(user, "first_name") +
                          " " +
                          defaultTranslation(user, "last_name")
                        : option
                    }
                    {...getuserProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Students"
                error={props.errors && !!props.errors.user}
                helperText={
                  props.errors &&
                  props.errors.user &&
                  props.errors?.user?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the user IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddUsers;

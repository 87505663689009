import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import {
  emailMessageRequest,
  groupRequest,
  smsMessageRequest
} from 'src/models/CRM';
import useSendSms from 'src/hooks/query/CRM/useSendSms';

const inputStyle = {
  width: { xs: '100%', sm: '90%' },
  marginBottom: '30px'
};

interface Props {
  setOpen: Function;
  selectedCustomers: { id: number; type: string }[];
}
const SendSmsForm = ({ setOpen, selectedCustomers }: Props) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    formState: { errors }
  } = useForm<smsMessageRequest>({
    defaultValues: {}
  });

  const sendSms = useSendSms();
  const queryClient = useQueryClient();

  const onSubmit = (data) => {
    console.log(errors);
    sendSms
      .mutateAsync(
        { ...data, data: selectedCustomers },
        {
          onSuccess: () => {
            toast.success('SMS Sent Successfully');
            setOpen(false);
          }
        }
      )
      .catch((error) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  return (
    <Box>
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1.5rem'
              }}
            ></Box>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  sx={inputStyle}
                  {...field}
                  label="SMS Subject"
                  placeholder="SMS Subject"
                  required
                  error={!!errors?.subject}
                  helperText={errors?.subject?.message}
                />
              )}
            />
            <Controller
              name="content"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SMS Content"
                  placeholder="SMS Content"
                  required
                  sx={inputStyle}
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                  multiline
                  rows={1}
                />
              )}
            />

            <Box sx={inputStyle}>
              <LoadingButton
                loadingPosition="start"
                variant="contained"
                fullWidth
                type="submit"
                loading={sendSms.isLoading}
                disabled={
                  !watch('content') || watch('content') == '<p><br></p>'
                }
              >
                Send SMS
              </LoadingButton>
            </Box>
          </div>
        </form>
      </>
    </Box>
  );
};

export default SendSmsForm;

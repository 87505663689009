import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  TextField
} from '@mui/material';
import i18next from 'i18next';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { fetchApi } from 'src/api/api';
import { resetUserPassword } from 'src/models/user';

const inputStyle = {
  width: '100%',
  marginBottom: '30px'
};

type Props = { open: boolean; onClose: () => void; user_id: number };

const ResetPasswordModal = ({ open, onClose, user_id }: Props) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm<resetUserPassword>({
    defaultValues: {}
  });

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const {
    isLoading,
    mutateAsync: ResetPassword,
    isError
  } = useMutation(
    (data: any) => fetchApi('/auth/user/admin/ResetPassword', 'PUT', data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const onSubmit: SubmitHandler<resetUserPassword> = async (data) => {
    try {
      ResetPassword({ ...data, user_id: user_id }).then((response) => {
        if (
          response.status === 200 ||
          response.status == 201 ||
          response.status == 202
        ) {
          toast.success(
            i18next.language === 'en'
              ? 'Password edited successfully'
              : 'تم تعديل كلمة المرور بنجاح'
          );
          onClose();
        }
      });
      reset();
      setConfirmPassword('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{
        width: '30vw',
        height: '20vh',
        position: 'absolute',
        top: '35vh',
        left: '35vw'
      }}
    >
      <Paper
        sx={{
          flex: 1,
          mx: 'auto',
          width: '100%',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <form
          style={{
            position: 'relative',
            width: '100%',
            paddingTop: '10px',
            gap: '2%',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          className="register-form d-flex justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="new_password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Confirm Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long'
              },
              validate: (value) =>
                value == confirmPassword ||
                'Password and Confirmation do not match'
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                required
                sx={inputStyle}
                error={errors.new_password?.type == 'minLength' ? true : false}
                helperText={
                  errors.new_password?.type == 'minLength'
                    ? errors.new_password?.message
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
          <TextField
            label="Confirm Password"
            type="password"
            id="passwordOne"
            name="confirm_password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            sx={inputStyle}
            error={
              confirmPassword !== watch('new_password') && confirmPassword != ''
            }
            helperText={
              confirmPassword !== watch('new_password') &&
              confirmPassword != 'Password and Confirmation do not match'
            }
          />

          <Stack direction="row" width="100%" gap={3}>
            <LoadingButton
              loadingPosition="start"
              variant="contained"
              fullWidth
              type="submit"
              loading={isLoading}
            >
              Reset Password
            </LoadingButton>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Stack>
        </form>
      </Paper>
    </Modal>
  );
};

export default ResetPasswordModal;

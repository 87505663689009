import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import BaseLayout from './layouts/BaseLayout';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/auth/login')));
const Forget = Loader(lazy(() => import('src/content/auth/forget')));

const authRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forget',
        element: <Forget />
      },
      {
        path: '*',
        element: <Navigate to="/login" />
      },
      {
        path: '/',
        element: <Navigate to="/login" />
      }
    ]
  }
];

export default authRoutes;

import { InputToolbox, MessageInput } from '@chatscope/chat-ui-kit-react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack
} from '@mui/material';
import React, { useState } from 'react';
import { AiOutlineAudio } from 'react-icons/ai';
import { CiFileOn, CiImageOn, CiVideoOn } from 'react-icons/ci';
import { useMutation } from 'react-query';
import { fetchApi } from 'src/api/api';
import AddVrcFileModal from './add-vrc-file-modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import MessageComponent from './message-component';
import { useSendMessage } from 'src/hooks/query/vrc/use-send-message';
import { useTranslation } from 'react-i18next';

type Props = {
  activeConversationId: number;
};

function VrcInput({ activeConversationId }: Props) {
  const { isLoading, isError, sendMessage } = useSendMessage();
  //add files state and control
  const [open, setOpen] = React.useState(false);
  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<{ file: File[] }>({
    defaultValues: {}
  });

  async function sendMessagesWithBase64(
    urls,
    attachmentType,
    activeConversationId
  ) {
    try {
      // Transform each file into base64
      const base64Promises = urls.map(async (url) => {
        const base64 = await blobToBase64(url); // Convert Blob to base64
        return base64;
      });

      // Wait for all base64 transformations to complete
      const base64Results = await Promise.all(base64Promises);

      // Now you have an array of base64 strings, you can send your message
      await sendMessage({
        type: attachmentType,
        id: activeConversationId,
        file: base64Results
      });

      // Reset your state or do other necessary operations
      setOpen(false);
      setAttachmentType('');
      reset({ file: [] });
    } catch (error) {
      console.error('Error sending messages:', error);
      // Handle error as necessary
    }
  }

  const [message, setMessage] = useState('');
  const handleNewMessage = () => {
    if (attachmentType == '') {
      sendMessage({
        type: 'text',
        message: message,
        id: activeConversationId
      });
      setMessage('');
    } else {
      sendMessagesWithBase64(
        getValues('file'),
        attachmentType,
        activeConversationId
      );
    }
  };

  const onSubmit: SubmitHandler<{ file: File[] }> = async () => {
    handleNewMessage();
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const [attachmentType, setAttachmentType] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showAttachmentSelect, setShowAttachmentSelect] = useState(false);

  const handleAttachClick = (target: HTMLElement) => {
    setAnchorEl(target); // Set the anchor element
    setShowAttachmentSelect(true);
  };

  const handleAttachmentSelect = (type: any) => {
    setAttachmentType(type);
    setShowAttachmentSelect(false);
    setOpen(true);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();

  return (
    <>
      <AddVrcFileModal
        open={open}
        onClose={() => {
          setOpen(false);
          setAttachmentType('');
          reset({ file: [] });
        }}
        control={control}
        type={attachmentType}
        sendFunction={onSubmit}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
      <Stack width={'99%'} gap={1}>
        <MessageInput
          placeholder={t('TypeMessagePlaceHolder')}
          typeof="text"
          inputMode="text"
          value={message}
          onChange={(innerHtml: string, textContent: string) => {
            setMessage(textContent);
          }}
          onSend={() => handleNewMessage()}
          onAttachClick={(event) => handleAttachClick(event.currentTarget)}
          style={{ width: '98%', gap: 10, padding: 5, margin: 10 }}
          id="attachment-button"
          aria-controls={showAttachmentSelect ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={showAttachmentSelect ? 'true' : undefined}
        />
      </Stack>
      {showAttachmentSelect && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={showAttachmentSelect}
          onClose={() => setShowAttachmentSelect(false)}
          MenuListProps={{
            'aria-labelledby': 'attachment-button'
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem
            sx={{ justifyContent: 'space-around', gap: 1 }}
            onClick={() => handleAttachmentSelect('image')}
          >
            <ListItemText>Image</ListItemText>
            <ListItemIcon>
              <CiImageOn />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: 'space-around', gap: 1 }}
            onClick={() => handleAttachmentSelect('video')}
          >
            <ListItemText>Video</ListItemText>
            <ListItemIcon>
              <CiVideoOn />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: 'space-around', gap: 1 }}
            onClick={() => handleAttachmentSelect('audio')}
          >
            <ListItemText>Audio</ListItemText>
            <ListItemIcon>
              <AiOutlineAudio />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: 'space-around', gap: 1 }}
            onClick={() => handleAttachmentSelect('asset')}
          >
            <ListItemText>File</ListItemText>
            <ListItemIcon>
              <CiFileOn />
            </ListItemIcon>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default VrcInput;

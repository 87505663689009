import React from "react";
import { Box, Button, Typography } from "@mui/material";

export const ErrorFallBackNotFound: React.FC<any> = () => {
  console.log("boundary");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        padding: "50px",
      }}
      className="container"
    >
      <Box style={{ textAlign: "center" }}>
        <Typography variant="h4">404 No Data Found</Typography>
      </Box>
      {/*<pre>{error.message}</pre>*/}
      <Box>
        {/* <img src={ErrorSvg} width="400px" height="400px" /> */}
      </Box>
    </Box>
  );
};

import { fetchApi } from 'src/api/api';
import { customerRequest, customerResponse, groupRequest, groupResponse } from 'src/models/CRM';

export const postGroup = async (data: groupRequest): Promise<any> => {
  const response = await fetchApi(`/crm/group`, 'POST', data);
  return response.data as any;
};

export const fetchGroups = async (
  search?: string
): Promise<groupResponse[]> => {
  const response = await fetchApi(
    `/crm/group?filter[search]=${search}`,
    'GET',
    {}
  );
  return response.data.data as groupResponse[];
};

export const fetchGroup = async (id): Promise<any> => {
  const response = await fetchApi(`/crm/group/${id}`);
  return response.data.data as groupResponse;
};

export const updateGroup = async (
  id: number,
  data: groupRequest
): Promise<any> => {
  const response = await fetchApi(`/crm/group/${id}`, 'PUT', data);
  return response.data as any;
};

import { useMutation, useQueryClient } from 'react-query';
import { markNotificationAsRead } from 'src/services/notification';

export const MarkAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => markNotificationAsRead(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('nots-number');
    },
    onError: () => {}
  });
};

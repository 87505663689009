import { useQuery } from 'react-query';
import { getNotifications } from 'src/services/notification';

export const useNotifications = (
  page: number,
  perPage: number,
  isRead: boolean
) => {
  return useQuery(
    ['notifications', page, isRead],
    () => getNotifications(page, perPage, isRead),
    {
      keepPreviousData: true
    }
  );
};

import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router';
import { MarkAsRead } from 'src/hooks/query/notification/MarkAsRed';
import { Notification } from 'src/models/notification';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
const NotificationCard = ({
  item,
  setOpen
}: {
  item: Notification;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const read = MarkAsRead();
  const navigate = useNavigate();
  const handleClick = () => {
    let path: string;
    if (item.pointerable_type === 'globaltest') {
      path = '/update-test';
      navigate(
        `${path}${item.pointerable_id ? `/${item.pointerable_id}` : ''}`
      );
    } else if (item.pointerable_type === 'course') {
      path = '/update-course';
      navigate(
        `${path}${item.pointerable_id ? `/${item.pointerable_id}` : ''}`
      );
    } else if (item.pointerable_type == 'chat') {
      path = '/vrc';
      navigate(`${path}`);
    }
    if (!item.pivot.read_at) {
      read.mutateAsync(item.id);
    }
    setOpen(false);
  };
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        backgroundColor: !item.pivot.read_at ? '#efefef' : '',
        borderRadius: '15px',
        display: 'block',
        ':not(:last-child)': {
          marginBottom: '8px !important'
        }
      }}
      button
      onClick={handleClick}
    >
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primary" variant="h5">
              {defaultTranslation(item, 'title')}
            </Typography>
            <Typography
              color="primary"
              variant="body2"
              fontSize="12px"
              fontWeight="bold"
            >
              {item.created_at}
            </Typography>
          </Stack>
        }
        secondary={
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {defaultTranslation(item, 'message')}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NotificationCard;

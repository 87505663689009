import { fetchApi } from 'src/api/api';
import {
  addMembersToGroupsRequest,
  customerRequest,
  customerResponse,
  emailMessageRequest,
  smsMessageRequest,
  whatsappMessageRequest
} from 'src/models/CRM';

export const postCustomer = async (data: customerRequest): Promise<any> => {
  const response = await fetchApi(`/crm/customer`, 'POST', data);
  return response.data as any;
};

export const fetchCustomers = async (
  search?: string
): Promise<customerResponse[]> => {
  const response = await fetchApi(
    `/crm/customer?filter[search]=${search}`,
    'GET',
    {}
  );
  return response.data.data as customerResponse[];
};

export const fetchCustomer = async (id): Promise<any> => {
  const response = await fetchApi(`/crm/customer/${id}`);
  return response.data.data as customerResponse;
};

export const updateCustomer = async (
  id: number,
  data: customerRequest
): Promise<any> => {
  const response = await fetchApi(`/crm/customer/${id}`, 'PUT', data);
  return response.data as any;
};

export const postEmailMessage = async (
  data: emailMessageRequest
): Promise<any> => {
  const response = await fetchApi(`/crm/message/send`, 'POST', data);
  return response.data as any;
};

export const postSmsMessage = async (data: smsMessageRequest): Promise<any> => {
  const response = await fetchApi(`/crm/message/send-sms`, 'POST', data);
  return response.data as any;
};

export const postWhatsappMessage = async (
  data: whatsappMessageRequest
): Promise<any> => {
  const response = await fetchApi(`/crm/message/send-whatsapp`, 'POST', data);
  return response.data as any;
};

export const PostMembersToGroups = async (
  data: addMembersToGroupsRequest
): Promise<any> => {
  const response = await fetchApi(`/crm/group/assign/members`, 'PUT', data);
  return response.data as any;
};

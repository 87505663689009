import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PhotoAlbumContent from 'src/components/photo-album-content';
import useGetALbum from 'src/hooks/query/gallery/useGetAlbum';
import { Stack, Typography } from '@mui/material';
import SuspenseLoader from 'src/components/SuspenseLoader';
import FilepondUploader from 'src/components/filepond-uploader';
import { useForm } from 'react-hook-form';
import { albumRequest } from 'src/models/gallery';
import useAddAlbumPhotos from 'src/hooks/query/gallery/useAddAlbumPhotos';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import useDeleteAlbumPhoto from 'src/hooks/query/gallery/useDeleteAlbumPhoto';
import { useState } from 'react';

export default function AlbumImagesModal({ open, setOpen, id, setId }) {
  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  const { handleSubmit, control, watch, reset } = useForm<{ image: File[] }>(
    {}
  );
  const { data: albumData, isLoading, isFetching } = useGetALbum(id);

  const addAlbumPhotos = useAddAlbumPhotos(id);
  const queryClient = useQueryClient();

  const onSubmit = (data: { image: File[] }) => {
    addAlbumPhotos
      .mutateAsync(data)
      .then((res) => {
        console.log(res);
        if (res.status === 201 || res.status === 202) {
          toast.success('Album Edited Successfuly');
          reset();
          queryClient.invalidateQueries('albums');
          handleClose();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h4">Edit Album Images</Typography>
        </DialogTitle>
        <DialogContent>
          {!isLoading && (
            <Stack>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FilepondUploader control={control} name="image" type="image" />
                <LoadingButton
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={addAlbumPhotos.isLoading}
                  disabled={!watch('image') || watch('image')?.length == 0}
                >
                  Add Photos To Album
                </LoadingButton>
              </form>
              <PhotoAlbumContent data={albumData} />
            </Stack>
          )}
          {isLoading && <SuspenseLoader size={32} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

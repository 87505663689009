import i18n from 'i18next';
import { fetchApi } from '../../../api/api';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { courseRequest } from 'src/models/course';
import { fetchCategories } from 'src/services/categories';
import { fetchTags } from 'src/services/tags';
import CustomImageInput from 'src/components/CustomImageInput';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import AddCategories from 'src/components/categories/add-categories';
import AddTags from 'src/components/tags/add-tags';
import FileUploader from 'src/components/file-uploader';
import CustomColorPicker from 'src/components/customColorPicker';
import NormalFileUploader from 'src/components/normal-file-uploader';
import fallback from '../../../assets/images/placeholder.png';
import { fetchInstructors } from 'src/services/instructors';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import ExcelOrByHandRadio from 'src/components/excel-or-by-hand-radio';

const inputStyle = {
  width: { xs: '100%', sm: '45%' },
  marginBottom: '30px'
};

interface Props {
  setCourseId: Function;
  handleNext: Function;
}

const AddCourse: React.FC<Props> = ({ setCourseId, handleNext }) => {
  const {
    data: categories,
    isLoading: l1,
    isError: e1
  } = useQuery(['get-categories'], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2
  } = useQuery(['get-tags'], () => fetchTags());

  const {
    data: instructors,
    isLoading: l4,
    isError: e4
  } = useQuery(['get-instructors'], () => fetchInstructors());

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    setError
  } = useForm<courseRequest>({});

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const user = JSON.parse(localStorage.getItem('user')!);
  const { t } = useTranslation();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: createCourse
  } = useMutation((data: any) => fetchApi('/course/course', 'POST', data));

  const onSubmit: SubmitHandler<courseRequest> = async (data) => {
    let modefiedData = { ...data };
    //@ts-ignore
    modefiedData.instructor_id = modefiedData.instructor_id.id;
    if (data.color == '' || data.color == undefined) {
      modefiedData.color = '#fff';
    }
    try {
      createCourse({
        ...modefiedData,
        mobile_description: data.web_description,
        mobile_description_en: data.web_description_en,
        rating: 0,
        file: null
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          setFileLoading(true);
          const courseId = response.data.data.id;
          // Use the course ID in the createContent mutation

          if (data.file && watch('is_excel')) {
            const formData = new FormData();
            formData.append('file', data.file);

            fetchApi(
              `/course/course/createCourseContent/${courseId}`,
              'POST',
              formData
            )
              .then((contentResponse) => {
                setFileLoading(false);
                if (
                  contentResponse.status === 200 ||
                  contentResponse.status === 201
                ) {
                  toast.success(
                    i18n.language === 'en'
                      ? 'course created successfully'
                      : 'تم انشاء الكورس بنجاح'
                  );
                  setCourseId(courseId);
                  handleNext();
                }
              })
              .catch((error) => {
                setFileLoading(false);
                reset({ file: null, guide_file: '', description_video: '' });
                console.error('Error creating course content:', error);
                toast.error('Failed to create course content');
              });
          } else {
            setFileLoading(false);
            toast.success(
              i18n.language === 'en'
                ? 'course created successfully'
                : 'تم انشاء الكورس بنجاح'
            );
            setCourseId(courseId);
            handleNext();
          }
        }
      });
    } catch (error) {
      reset({ file: null, guide_file: '', description_video: '' });
      console.log(error);
    }
  };

  if (l1 || l2 || l3 || l4 || fileLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2%',
        padding: '20px 0 50px 0',
        justifyContent: 'center'
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="course_image"
        rules={{ required: 'Course Image is required' }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: '5px' }}>Card Image *</Typography>
            <CustomImageInput
              fallback={fallback}
              file={field.value}
              id={field.name}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    console.log('card', getValues('course_image'));
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
            <Typography color="error" sx={{ paddingBottom: '5px' }}>
              {errors?.course_image?.message}
            </Typography>
          </Box>
        )}
      />

      <Controller
        name="overview_image"
        rules={{ required: 'Panner Image is required' }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: '5px' }}>
              Panner Image *
            </Typography>
            <CustomImageInput
              fallback={fallback}
              file={field.value}
              id={field.name}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    console.log('over', getValues('overview_image'));
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
            />
            <Typography color="error" sx={{ paddingBottom: '5px' }}>
              {errors?.overview_image?.message}
            </Typography>
          </Box>
        )}
      />

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('course name in Arabic')}
            placeholder={t('course name in Arabic')}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('course Name in English')}
            placeholder={t('course Name in English')}
            required
          />
        )}
      />
      <Controller
        name="web_description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Description in Arabic')}
            placeholder={t('Description in Arabic')}
            required
          />
        )}
      />

      <Controller
        name="web_description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Description in English')}
            placeholder={t('Description in English')}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            sx={inputStyle}
            {...field}
            label={t('Price')}
            placeholder={t('Price')}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories
          errors={errors}
          control={control}
          categories={categories!}
        />
      </Box>
      <Box sx={inputStyle}>
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>
      <Box sx={inputStyle}>
        <Controller
          name="instructor_id"
          control={control}
          rules={{ required: 'Instructor is required!' }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="combo-box-demo"
              options={instructors}
              getOptionLabel={(instructor) => {
                return (
                  defaultTranslation(instructor, 'first_name') +
                  ' ' +
                  defaultTranslation(instructor, 'last_name')
                );
              }}
              onChange={(e, value) => field.onChange(value)} // Manually propagate the change event to the React Hook Form
              value={field.value || null} // Ensure the value is defined to keep the Autocomplete controlled
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Instructor *"
                  error={!!errors.instructor_id}
                  helperText={errors.instructor_id?.message}
                />
              )}
            />
          )}
        />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="guide_file"
          setValue={setValue}
          accept=".pdf,.doc,.docx"
          label="upload guide file *"
          setError={setError}
        />
        <Typography color="error" sx={{ paddingBottom: '5px' }}>
          {errors?.guide_file?.message}
        </Typography>
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="description_video"
          setValue={setValue}
          accept="video/mp4"
          label="upload Introduction video *"
        />
        <Typography color="error" sx={{ paddingBottom: '5px' }}>
          {errors?.description_video?.message}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '92%' },
          marginBottom: '30px'
        }}
      >
        <CustomColorPicker name="color" control={control} setValue={setValue} />
      </Box>

      <ExcelOrByHandRadio control={control} />
      {watch('is_excel') != false && (
        <Box
          sx={{
            width: { xs: '100%', sm: '92%' },
            marginBottom: '30px'
          }}
        >
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <NormalFileUploader
              control={control}
              setValue={setValue}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="file"
              label="Upload Excel File *"
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                const link = document.createElement('a');
                link.href =
                  'https://backend.uh-training.com/ExcelFiles/Course_Form_with_translation.xlsx';
                link.download = 'course_template.xlsx';
                link.click();
              }}
            >
              Download Template
            </Button>
          </Box>
        </Box>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '20px'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: '100%', sm: '92%', lg: '30%' } }}
          disabled={watch('is_excel') != false && !watch('file')}
        >
          <Typography fontSize="large" color="#fff">
            {t('Create Course')}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default AddCourse;

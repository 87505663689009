import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddCustomerForm from './add-customer-form';

export default function AddCustomerModal({ open, setOpen, id, setId }) {
  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {id === 0 ? 'Add New Customer' : "Edit Customer's Information"}
        </DialogTitle>
        <DialogContent>
          <AddCustomerForm id={id ? id : 0} setOpen={setOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Typography, Button, Grid, Stack, Menu, MenuItem } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { GrGroup } from 'react-icons/gr';
import { TbBrandTelegram } from 'react-icons/tb';
import { useState } from 'react';
import { TfiEmail } from 'react-icons/tfi';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';

function PageHeader(props) {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="subtitle2">{props.subtitle}</Typography>
      </Grid>

      <Grid item>
        <Stack spacing={2} direction="row">
          {props.altBtn && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={props.altClick}
            >
              {props.altBtn}
            </Button>
          )}
          {props.selected?.length > 0 && (
            <>
              <div>
                <Button
                  variant="contained"
                  startIcon={<TbBrandTelegram fontSize="small" />}
                  onClick={props?.handleClick}
                  color="info"
                >
                  {props?.selected[0]}
                </Button>
                <Menu
                  anchorEl={props?.anchorEl}
                  open={Boolean(props?.anchorEl)}
                  onClose={props?.handleClose}
                >
                  {props?.menuItems?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        item.action();
                        props.handleClose();
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                      >
                        <Typography sx={{ fontSize: 15 }}>
                          {item?.icon}
                        </Typography>
                        <Typography sx={{ fontSize: 15 }}>
                          {item?.label}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              {props.selected?.length > 1 && (
                <Button
                  sx={{ mt: { xs: 2, md: 0 } }}
                  variant="contained"
                  startIcon={<GrGroup fontSize="small" />}
                  onClick={props.onClickSelected[1]}
                  color="secondary"
                >
                  <Typography>{props.selected[1]}</Typography>
                </Button>
              )}
            </>
          )}
          {props.contained && (
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={props.onClick}
            >
              {props.contained}
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default PageHeader;

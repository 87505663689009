import image from '../../assets/images/placeholder.png';
import userImage from '../../assets/images/user-holder.png';

export const getImageFromServer = (imgUrl: string) =>
  imgUrl ? 'https://backend.uh-training.com' + `${imgUrl}` : image;

export const getUserImageFromServer = (imgUrl: string) =>
  imgUrl ? 'https://backend.uh-training.com' + `${imgUrl}` : userImage;

  export const getLinkFromServer = (link: string) =>
  link ? "https://backend.uh-training.com" + `${link}` : "";

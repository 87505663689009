import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useMutation, useQueryClient } from 'react-query';
import { fetchApi } from 'src/api/api';

type Props = {
  groupId: number;
  modalOpen: boolean;
  handleModalClose: Function;
};

function GroupDeleteModal({ modalOpen, groupId, handleModalClose }: Props) {
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync: DeleteGroup } = useMutation(
    (id: any) => fetchApi(`/crm/group/${id}`, 'Delete'),
    {
      onError: (error: any) => {
        handleModalClose();
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['groups']);
        handleModalClose();
        toast.success(
          i18next.language === 'en'
            ? 'Group Deleted successfully'
            : 'تم حذف العميل بنجاح'
        );
      }
    }
  );

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        width: '40vw',
        height: '20vh',
        position: 'absolute',
        top: '35vh',
        left: '30vw'
      }}
    >
      <Stack
        sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: 'auto',
            width: '90%',
            p: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!isLoading && (
            <>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap="2%"
                justifyContent="flex-start"
                sx={{ width: '100%' }}
              >
                <Typography
                  variant="h4"
                  sx={{ width: '100%', paddingBottom: '20px' }}
                >
                  {`Are You Sure You Want To Delete Group With ID: ${groupId}`}
                </Typography>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ padding: '10px' }}
                  onClick={() => DeleteGroup(groupId)}
                >
                  <Typography fontSize="1rem" color="#fff">
                    Delete
                  </Typography>
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  size="small"
                  sx={{ padding: '10px' }}
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  <Typography fontSize="1rem" color="primary">
                    Cancel
                  </Typography>
                </Button>
              </Stack>
            </>
          )}

          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
}

export default GroupDeleteModal;

import { Box, Typography } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fullMarks: number;
  currentMarks: number;
};

const TotalCurrentMarksStatus = ({ fullMarks, currentMarks }: Props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography
        fontSize="18px"
        fontWeight={600}
        color={currentMarks == fullMarks ? 'success.main' : 'primary.main'}
      >
        {`Full Mark : ${fullMarks}  /   Current Total : ${currentMarks}`}
      </Typography>
      {currentMarks != fullMarks && (
        <Typography color="error">
          {
            'Warning : inputed Questions Marks Total is not equal to the full mark'
          }
        </Typography>
      )}
    </Box>
  );
};

export default TotalCurrentMarksStatus;

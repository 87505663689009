import { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import React from 'react';
import AddCourse from './add-course';
import AddRelatedCourseItems from './add-related-course-items';
import CourseDND from './course-dnd';
import AddCourseCertificates from './course-certificates';

const AddCourseHOC = () => {
  const steps = [
    'Add Course Information',
    'Add Related Items',
    'Add Course Resources',
    'Add Course Certificates'
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [courseId, setCourseId] = useState(0);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <>
      <Container>
        <Paper elevation={3} sx={{ marginY: '50px', padding: '20px' }}>
          <Stepper activeStep={activeStep} sx={{ background: 'transparent' }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep == 0 ? (
            <React.Fragment>
              <AddCourse setCourseId={setCourseId} handleNext={handleNext} />
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          ) : activeStep == 1 ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                )}
              </Box>
              <AddRelatedCourseItems id={courseId} handleNext={handleNext} />
            </React.Fragment>
          ) : activeStep == 2 ? (
            <React.Fragment>
              <CourseDND handleNext={handleNext} id={courseId} />
            </React.Fragment>
          ) : (
            <AddCourseCertificates id={courseId} />
          )}
        </Paper>
      </Container>
    </>
  );
};

export default AddCourseHOC;

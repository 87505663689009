import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { updateGroupRequest, vrcGroupResponse } from 'src/models/vrc';
import { useQueryClient } from 'react-query';
import { useUpdateGroup } from 'src/hooks/query/vrc';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import CustomImageInput from 'src/components/CustomImageInput';
import CustomTextField from 'src/components/custom-text-field';
import fallback from '../../../assets/images/placeholder.png';

const inputStyle = {
  width: { xs: '100%', sm: '48%' },
  marginBottom: '30px',
  marginLeft: { sm: '16px' }
};

interface Props {
  setOpen: Function;
  activeConversation: vrcGroupResponse;
}
const EditGroupForm = ({ setOpen, activeConversation }: Props) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors }
  } = useForm<{ name: string; image: string }>({
    defaultValues: {}
  });

  const queryClient = useQueryClient();

  const UpdateGroup = useUpdateGroup();

  const onUpdate = (data: updateGroupRequest) => {
    console.log(data);
    UpdateGroup.mutateAsync({ ...data, id: activeConversation?.id })
      .then((res) => {
        if (res.status === 201 || res.status === 202) {
          toast.success('Group Updated Successfuly');
          queryClient.invalidateQueries(['groups']);
          activeConversation.image = res.data.image;
          activeConversation.name = res.data.name;
          setOpen(false);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status == 422 &&
          error?.response?.data?.errors?.name
        ) {
          setError('name', {
            type: 'manual',
            message: 'name already exist'
          });
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  useEffect(() => {
    if (activeConversation) {
      reset({ name: activeConversation.name, image: activeConversation.image });
    }
  }, [activeConversation, reset]);

  return (
    <Box>
      <>
        <form onSubmit={handleSubmit(onUpdate)}>
          <div>
            <Stack
              width={'100%'}
              flexDirection={'column'}
              spacing={2}
              justifyContent={'space-around'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem',
                  width: { xs: '90%',sm:'50%' }
                }}
              >
                <Controller
                  name="image"
                  rules={{
                    required:
                      i18next.language == 'en'
                        ? 'Image is required'
                        : 'الصورة مطلوبة'
                  }}
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ width: '100%' }}>
                      <Typography sx={{ paddingBottom: '5px' }}>
                        Group Image
                      </Typography>
                      <CustomImageInput
                        id="group-image"
                        file={field.value}
                        fallback={fallback}
                        handleChange={(e: any) => {
                          blobToBase64(e)
                            .then((value) => {
                              field.onChange(value); // Update the field value with the base64 string
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        fieldName={field.name}
                        classesForStyles=""
                      />
                      <Typography color="error" sx={{ paddingBottom: '5px' }}>
                        {errors?.image?.message}
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '90%' }
                }}
              >
                <CustomTextField
                  name="name"
                  control={control}
                  defaultValue=""
                  label={'Group Name'}
                  placeholder={'Group Name'}
                  required
                  errors={errors}
                  inputStyle={{ width: '100%', marginBottom: '30px' }}
                />
              </Box>
            </Stack>

            <Stack  sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{ width: '90%' }} 
                disabled={UpdateGroup.isLoading} // Disable the button when loading
                startIcon={
                  UpdateGroup.isLoading && <CircularProgress size={20} />
                } // Show CircularProgress when loading
              >
                Update Group
              </Button>
            </Stack>
          </div>
        </form>
      </>
    </Box>
  );
};

export default EditGroupForm;

import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { TableSearch } from 'src/components/table-search';
import LoadingTable from 'src/components/loading-table';
import { albumResponse } from 'src/models/gallery';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { TbPhotoEdit } from 'react-icons/tb';

interface Props {
  data: albumResponse[];
  isLoading: boolean;
  setOpen?: Function;
  setId?: Function;
  isFetching: boolean;
  selected: { id: number; type: string }[];
  setSelected: Function;
  setDeleteOpen?: Function;
  setImagesOpen: Function;
}

export default function AlbumsTable({
  data,
  isLoading,
  setOpen,
  setId,
  isFetching,
  selected,
  setSelected,
  setDeleteOpen,
  setImagesOpen
}: Props) {
  const [page, setPage] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(5);
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLimit(parseInt(event.target.value));
  };

  const applyPagination = (data: albumResponse[], page, limit) => {
    return data && data.slice(page * limit, page * limit + limit);
  };

  const paginatedData = applyPagination(data, page, limit);

  const theme = useTheme();

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardHeader title="Albums Table" />
        {/* <TableSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        /> */}
      </Stack>
      {selected?.length > 0 && (
        <CardHeader
          title={`${selected.length} Albums Selected`}
          sx={{ paddingTop: 0 }}
        />
      )}
      <Divider />
      <LoadingTable isLoading={isLoading} isFetching={isFetching} columns={5}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography>ID</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Images Count</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Members Count</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Action</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Edit Images</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {paginatedData && paginatedData.length > 0 ? (
              <TableBody>
                {paginatedData.map((item) => (
                  <TableRow>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {defaultTranslation(item, 'name')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.media.length}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.users.length}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        <Tooltip title="Edit Album" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpen(true);
                              setId(item.id);
                            }}
                          >
                            <EditTwoTone fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Album" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.error.lighter
                              },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setId(item.id);
                              setDeleteOpen(true);
                            }}
                          >
                            <DeleteTwoTone />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        <Tooltip title="Edit Images" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setId(item.id);
                              setImagesOpen(true);
                            }}
                          >
                            <TbPhotoEdit fontSize="x-large" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                minHeight={100}
                width="100%"
              >
                <Typography>No Data Found</Typography>
              </Stack>
            )}
          </Table>
        </TableContainer>
      </LoadingTable>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data?.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
}

import i18n from 'i18next';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { fetchCategories } from 'src/services/categories';
import { fetchTags } from 'src/services/tags';
import { fetchApi } from 'src/api/api';
import CustomImageInput from 'src/components/CustomImageInput';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import AddCategories from 'src/components/categories/add-categories';
import AddTags from 'src/components/tags/add-tags';
import NormalFileUploader from 'src/components/normal-file-uploader';
import { globalTestRequest } from 'src/models/global-test';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { fetchInstructors } from 'src/services/instructors';
import fallback from '../../../assets/images/placeholder.png';
import IsGradedRadio from './is-graded-radio';
import GradeInputSection from './grade-input-section';
import ExcelOrByHandRadio from 'src/components/excel-or-by-hand-radio';
import i18next from 'i18next';
import { Stack } from '@mui/system';
import { getImageFromServer } from 'src/utils/helpers/get-image-from-server';
import { CertificateResponse } from 'src/models/certificate';
import QuizzCertificates from './QuizzCertificates';
import { Close } from '@mui/icons-material';

const inputStyle = {
  width: { xs: '100%', sm: '45%' },
  marginBottom: '30px'
};

interface Props {}

const CreateGlobalTest: React.FC<Props> = ({}) => {
  const {
    data: categories,
    isLoading: l1,
    isError: e1
  } = useQuery(['get-categories'], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2
  } = useQuery(['get-tags'], () => fetchTags());

  const {
    data: instructors,
    isLoading: l4,
    isError: e4
  } = useQuery(['get-instructors'], () => fetchInstructors());

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    setError
  } = useForm<globalTestRequest>({
    defaultValues: {}
  });

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const [certificates, setCertificates] = useState<number[]>([]);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState<
    CertificateResponse[]
  >([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: createCourse
  } = useMutation((data: any) => fetchApi('/test/globalTest', 'POST', data));

  const onSubmit: SubmitHandler<globalTestRequest> = async (data) => {
    let modefiedData = { ...data };
    //@ts-ignore
    modefiedData.instructor_id = modefiedData.instructor_id.id;
    try {
      createCourse({
        ...modefiedData,
        is_show: 0,
        isDeleted: false,
        file: null,
        certificate: certificates.map((id) => id)
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          const testId = response.data.data.id;
          if (data.is_excel) {
            setFileLoading(true);

            // Use the course ID in the createContent mutation

            if (data.file) {
              const formData = new FormData();
              formData.append('file', data.file);
              formData.append('test_type', 'globaltest');
              formData.append('test_id', testId);

              fetchApi(`/test/test/createTestContent/`, 'POST', formData)
                .then((contentResponse) => {
                  setFileLoading(false);
                  if (
                    contentResponse.status === 200 ||
                    contentResponse.status === 201
                  ) {
                    toast.success(
                      i18n.language === 'en'
                        ? 'Test created successfully'
                        : 'تم انشاء الاختبار بنجاح'
                    );
                    navigate('/tests');
                  }
                })
                .catch((error) => {
                  setFileLoading(false);
                  reset({ file: null });
                  console.error('Error creating course content:', error);
                  toast.error('Failed to create course content');
                });
            }
          } else {
            toast.success(
              i18n.language === 'en'
                ? 'Test created successfully'
                : 'تم انشاء الاختبار بنجاح'
            );
            navigate(`/update-test/${testId}`, { state: 1 });
          }
        }
      });
    } catch (error) {
      reset({ file: null });
      console.log(error);
    }
  };

  const handleRemoveCertificate = (id: number) => {
    setSelectedCertificates((prev) => prev.filter((item) => item.id !== id));
    setCertificates((prev) => prev.filter((crId) => crId !== id));
  };

  if (l1 || l2 || l3 || fileLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Container>
      <Paper elevation={3} sx={{ marginY: '50px', padding: '20px' }}>
        <form
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            padding: '20px 0 50px 0',
            justifyContent: 'center'
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box width="100%" sx={{ textAlign: 'center' }}>
            <Typography
              variant="h3"
              color="primary"
              sx={{ paddingTop: '20px' }}
            >
              Add New Test
            </Typography>
          </Box>
          <Controller
            name="image"
            rules={{ required: 'Image is required' }}
            control={control}
            render={({ field }) => (
              <Box sx={inputStyle}>
                <Typography sx={{ paddingBottom: '5px' }}>
                  Test Image
                </Typography>
                <CustomImageInput
                  file={field.value}
                  handleChange={(e: any) => {
                    blobToBase64(e)
                      .then((value) => {
                        field.onChange(value); // Update the field value with the base64 string
                      })
                      .catch((err) => {
                        console.log(err);
                        setLocalError(err);
                      });
                  }}
                  fieldName={field.name}
                  isCover={true}
                  isContain={true}
                  id={field.name}
                  fallback={fallback}
                />
                <Typography color="error" sx={{ paddingBottom: '5px' }}>
                  {errors?.image?.message}
                </Typography>
              </Box>
            )}
          />
          <Box sx={inputStyle}></Box>

          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={inputStyle}
                {...field}
                label={t('Test name in Arabic')}
                placeholder={t('Test name in Arabic')}
                required
              />
            )}
          />
          <Controller
            name="name_en"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={inputStyle}
                {...field}
                label={t('Test Name in English')}
                placeholder={t('Test Name in English')}
                required
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={inputStyle}
                {...field}
                label={t('Description in Arabic')}
                placeholder={t('Description in Arabic')}
                required
              />
            )}
          />

          <Controller
            name="description_en"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={inputStyle}
                {...field}
                label={t('Description in English')}
                placeholder={t('Description in English')}
                required
              />
            )}
          />

          <Controller
            name="price"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                type="text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                sx={inputStyle}
                {...field}
                label={t('Price')}
                placeholder={t('Price')}
                required
              />
            )}
          />

          <Box sx={inputStyle}>
            <AddCategories
              errors={errors}
              control={control}
              categories={categories!}
            />
          </Box>
          <Box sx={inputStyle}>
            <AddTags errors={errors} tags={tags!} control={control} />
          </Box>
          <Box sx={inputStyle}>
            <Controller
              name="instructor_id"
              control={control}
              rules={{ required: 'Instructor Id is required' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="combo-box-demo"
                  options={instructors}
                  getOptionLabel={(instructor) => {
                    return (
                      defaultTranslation(instructor, 'first_name') +
                      ' ' +
                      defaultTranslation(instructor, 'last_name')
                    );
                  }}
                  onChange={(e, value) => field.onChange(value)} // Manually propagate the change event to the React Hook Form
                  value={field.value || null} // Ensure the value is defined to keep the Autocomplete controlled
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Instructor"
                      error={errors && !!errors.instructor_id}
                      helperText={
                        errors &&
                        errors.instructor_id &&
                        errors?.instructor_id?.message
                      }
                    />
                  )}
                />
              )}
            />
          </Box>

          <Controller
            name="attempts_number"
            control={control}
            defaultValue={1}
            render={({ field }) => (
              <TextField
                type="number"
                inputProps={{ inputMode: 'numeric', min: 1 }}
                sx={{ width: '92%', marginBottom: '30px' }}
                {...field}
                label="number of allowed attempts"
                placeholder={'number of allowed attempts'}
                required
              />
            )}
          />

          <IsGradedRadio control={control} />
          {watch('is_graded') == true && (
            <GradeInputSection
              getValues={getValues}
              setValues={setValue}
              errors={errors}
              testId={0}
              control={control}
              formMethod="create"
            />
          )}
          {watch('is_graded') && (
            <Box width="90%">
              <Button
                onClick={() => setIsCertificateOpen(true)}
                variant="contained"
              >
                {i18next.language === 'ar'
                  ? 'إضافة شهادات'
                  : 'Add Certificates'}
              </Button>
              <Box display="flex" gap="1rem" flexWrap="wrap" my="1.5rem">
                {selectedCertificates.map((item: CertificateResponse) => (
                  <Card sx={{ position: 'relative' }}>
                    <IconButton
                      size="small"
                      sx={{ position: 'absolute', right: '5px', top: '5px' }}
                      onClick={() => handleRemoveCertificate(item.id)}
                    >
                      <Close />
                    </IconButton>
                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        <Avatar src={getImageFromServer(item.logo)} />
                        <Box>
                          <Typography variant="h6">
                            {defaultTranslation(item, 'donor')}
                          </Typography>
                          <Typography variant="body2">
                            {defaultTranslation(item, 'description')}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
          )}
          {watch('is_graded') && isCertificateOpen && (
            <QuizzCertificates
              selectedIds={certificates}
              setSelectedIds={setCertificates}
              open={isCertificateOpen}
              setOpen={setIsCertificateOpen}
              setSelectedCertificates={setSelectedCertificates}
            />
          )}

          <ExcelOrByHandRadio control={control} />
          {watch('is_excel') != false && (
            <Box
              sx={{
                width: { xs: '100%', sm: '92%' },
                marginBottom: '30px'
              }}
            >
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <NormalFileUploader
                  control={control}
                  setValue={setValue}
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  name="file"
                  label="Upload Excel File"
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href =
                      'https://backend.uh-training.com/ExcelFiles/test_form_with_translation_order.xlsx';
                    link.download = 'course_template.xlsx';
                    link.click();
                  }}
                >
                  Download Template
                </Button>
              </Box>
            </Box>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="mb-2 btn-hover-secondary btn-width-100"
              type="submit"
              sx={{ width: { xs: '100%', sm: '92%', lg: '30%' } }}
              disabled={
                (watch('is_excel') != false && !watch('file')) ||
                (watch('is_graded') &&
                  (!watch('evaluatable') || watch('evaluatable')?.length == 0))
              }
            >
              <Typography fontSize="large" color="#fff">
                {t('Create Test')}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateGlobalTest;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Paper } from '@mui/material';
import TestDND from '../update-global-test/test-dnd';

type Props = {};

const EditCourseQuestions = (props: Props) => {
  const params = useParams();
  return (
    <Container>
      <Paper elevation={3} sx={{ marginY: '50px', padding: '20px' }}>
        <TestDND id={params.id!} testType="test" />
      </Paper>
    </Container>
  );
};

export default EditCourseQuestions;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Checkbox, Stack, Typography } from '@mui/material';
import FilepondUploader from 'src/components/filepond-uploader';
import { LoadingButton } from '@mui/lab';
import { vrcGroupResponse } from 'src/models/vrc';
import { getImageFromServer, getUserImageFromServer } from 'src/utils/helpers/get-image-from-server';
import { PiUsersBold } from 'react-icons/pi';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { useDeleteGroupUsers } from 'src/hooks/query/vrc';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AddGroupMembersModal from './add-group-members-modal';
import AddGroupModal from './add-group-modal';

interface Props {
  open: boolean;
  onClose: () => void;
  activeConversation: vrcGroupResponse;
}

export default function GroupInfoModal({
  open,
  onClose,
  activeConversation
}: Props) {
  const handleClose = () => {
    onClose();
    setSelectedUsers([]);
  };

  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);
  const handleUserToggle = (userId: number) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const deleteGroupUsers = useDeleteGroupUsers();
  const queryClient = useQueryClient();

  const handleDeleteUsers = () => {
    deleteGroupUsers.mutate(
      {
        users: selectedUsers,
        id: activeConversation.id
      },
      {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success('Users Deleted Successfully');
          queryClient.invalidateQueries('groups');
          onClose();
        }
      }
    );
  };

  const [addUsersOpen, setAddUsersOpen] = React.useState(false);
  const [editGroupOpen, setEditGroupOpen] = React.useState(false);
  const [editId, setEditId] = React.useState<number>(0);

  return (
    <>
      {addUsersOpen && (
        <AddGroupMembersModal
          open={addUsersOpen}
          onClose={() => setAddUsersOpen(false)}
          groupId={activeConversation.id}
          activeConversation={activeConversation}
          onCloseParent={() => onClose()}
        />
      )}
      <AddGroupModal
        open={editGroupOpen}
        setOpen={setEditGroupOpen}
        activeConversation={activeConversation}
        update={true}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Stack flexDirection={'row'} gap={2}>
            <Stack
              sx={{
                width: { xs: "100%", sm: "50%" },
              }}
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap={'wrap'}
              gap={3}
            >
              <Avatar
                src={getUserImageFromServer(activeConversation.image)}
                alt={activeConversation.name}
                sx={{ width: 250, height: 250 }}
              />
              <Stack flexDirection={'row'} gap={2}>
                <Typography variant="h5">{activeConversation.name}</Typography>
                <Typography
                  variant="h5"
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <PiUsersBold />
                  {activeConversation.users.length} members
                </Typography>
              </Stack>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditId(activeConversation.id);
                    setEditGroupOpen(true);
                  }}
                >
                  Edit Info
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setAddUsersOpen(true)}
                >
                  Add Members
                </Button>
              </DialogActions>
            </Stack>
            <Stack
              sx={{
                width: { xs: "100%", sm: "50%" },
                maxHeight: 400,
                overflowY: 'scroll'
              }}
            >
              {activeConversation.users.map((user) => (
                <Stack
                  key={user.id}
                  flexDirection={'row'}
                  gap={1}
                  alignItems={'center'}
                  onClick={() => {
                    handleUserToggle(user.id);
                  }}
                  sx={{
                    cursor: 'pointer',
                    padding: 1,
                    transition: 'background-color 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserToggle(user.id);
                    }}
                  />
                  <Avatar
                    src={getImageFromServer(user.profile_image)}
                    alt={
                      defaultTranslation(user, 'first_name') +
                      ' ' +
                      defaultTranslation(user, 'last_name')
                    }
                  />
                  <Typography>
                    {defaultTranslation(user, 'first_name') +
                      ' ' +
                      defaultTranslation(user, 'last_name')}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {selectedUsers.length > 0 && (
            <LoadingButton
              onClick={handleDeleteUsers}
              color="error"
              variant="outlined"
              loading={deleteGroupUsers.isLoading}
            >
              Delete Members
            </LoadingButton>
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useState } from 'react';
import { Box } from '@mui/system';
import { Container, Paper, Step, StepButton, Stepper } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import EditGlobalTest from './edit-global-test';
import TestDND from './test-dnd';

const EditTestHOC = () => {
  const steps = ['Edit Test Information', 'Edit Test Content'];
  const location = useLocation();
  const testState = location?.state;
  //@ts-ignore
  const [activeStep, setActiveStep] = useState<number>(testState ? testState : 0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const testId = useParams();
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ marginY: '50px', padding: '20px' }}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{ background: 'transparent' }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep == 0 ? (
          <React.Fragment>
            <EditGlobalTest testId={testId.id!} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        ) : activeStep == 1 ? (
          <React.Fragment>
            <TestDND id={testId.id!} testType="globaltest" />
          </React.Fragment>
        ) : null}
      </Paper>
    </Container>
  );
};

export default EditTestHOC;

import { Editor } from '@tinymce/tinymce-react';

export default function TextEditor({
  value,
  setValue,
  dir = 'ltr'
}: {
  value: string;
  setValue: any;
  dir?: 'ltr' | 'rtl';
}) {
  const handleEditorChange = (content, editor) => {
    console.log(content);
    setValue(content);
  };
  return (
    <Editor
      apiKey="6ohcoh9sw1pndp954g3qenjqh8xh343podfzolra2jg8p3c3"
      init={{
        plugins: 'link lists table emoticons charmap forecolor backcolor ',
        toolbar:
          'undo redo | blocks fontfamily fontsize | forecolor backcolor bold italic underline link removeformat | strikethrough image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap',
        directionality: dir,
        height: '100%',
        branding: false,
        menubar: false
      }}
      onEditorChange={handleEditorChange}
      value={value}
    />
  );
}

import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/system';
import { CircularProgress, Grid } from '@mui/material';
import CertificateCard from './CertificateCard';
import { useNavigate } from 'react-router';
import { CertificateResponse } from 'src/models/certificate';
import { useCertificates } from 'src/hooks/query/certificate/useCertificates';
import { AssignCertificate } from 'src/hooks/query/certificate/AssignCertificate';
import { LoadingButton } from '@mui/lab';

const CourseCertificates = ({
  id,
  assignedCertificates,
  isEdit = false,
  isLoading,
  isFetching
}: {
  id: number;
  assignedCertificates?: CertificateResponse[];
  isEdit?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
}) => {
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const { data, isLoading: certificatesLoading } = useCertificates();

  const navigate = useNavigate();

  useEffect(() => {
    if (assignedCertificates && isEdit) {
      if (assignedCertificates.length > 0) {
        assignedCertificates.forEach((item) => {
          setSelectedIds((prev) => [...prev, item.id]);
        });
      } else {
        setSelectedIds([]);
      }
    }
  }, [assignedCertificates]);

  const assign = AssignCertificate();

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('certificatable_id', id.toString());
    formData.append('certificatable_type', 'course');
    if (selectedIds && selectedIds.length > 0) {
      selectedIds.forEach((id, index) => {
        formData.append(`certificate[${index}]`, id);
      });
    }
    assign.mutateAsync(formData).then(() => {
      setSelectedIds([]);
      navigate(`/update-course/${id}`);
    });
  };

  return isLoading || certificatesLoading || isFetching ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh'
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box my={6}>
      <Stack mb={2} justifyContent="end" direction="row" spacing={1}>
        <LoadingButton
          variant="contained"
          disabled={selectedIds.length === 0}
          onClick={handleSubmit}
          loading={assign.isLoading}
        >
          {isEdit ? 'Edit' : 'Submit'}
        </LoadingButton>
      </Stack>
      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid sx={{ display: 'flex' }} item xs={12} sm={6} md={3}>
            <CertificateCard
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
              key={item.id}
              item={item}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseCertificates;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import CreateCourse from './content/courses/create-course';
import UpdateCourse from './content/courses/update-course';
import CreateGlobalTest from './content/tests/create-global-test';
import UpdateGlobalTest from './content/tests/update-global-test';
import UpdateCourseTest from './content/tests/update-course-test';
import Customers from './content/CRM/customers';
import Groups from './content/CRM/groups';
import Gallery from './content/gallery';
import VirtualClassRoom from './content/vrc';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

/* ----------- Our Works ------------ */

const Courses = Loader(lazy(() => import('src/content/courses')));
const Tests = Loader(lazy(() => import('src/content/tests')));
const Instructors = Loader(lazy(() => import('src/content/Instructors')));
const Students = Loader(lazy(() => import('src/content/students')));
const Admins = Loader(lazy(() => import('src/content/Admins')));
const Blogs = Loader(lazy(() => import('src/content/blogs')));
const CreateBlog = Loader(lazy(() => import('src/content/blogs/create-blog')));
const EditBlogs = Loader(lazy(() => import('src/content/blogs/edit-blog')));
const ShopItems = Loader(lazy(() => import('src/content/shop-items')));
const HomePage = Loader(lazy(() => import('src/content/home-page')));
const Categories = Loader(lazy(() => import('src/content/categories')));
const Tags = Loader(lazy(() => import('src/content/tags')));
const MessagesArchive = Loader(
  lazy(() => import('src/content/CRM/messages-archive'))
);
const Consultations = Loader(lazy(() => import('src/content/consultations')));
const VideoConsultations = Loader(
  lazy(() => import('src/content/video-consultations'))
);
const Profile = Loader(lazy(() => import('src/content/profile')));

/* ----------- Our Works ------------ */

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/home-page" replace />
      },
      {
        path: '*',
        element: <Navigate to="/home-page" replace />
      },
      {
        path: 'courses',
        element: <Courses />
      },
      {
        path: 'create-course',
        element: <CreateCourse />
      },
      {
        path: 'update-course/:id',
        element: <UpdateCourse />
      },
      {
        path: 'instructors',
        element: <Instructors />
      },
      {
        path: 'students',
        element: <Students />
      },
      {
        path: 'admins',
        element: <Admins />
      },
      {
        path: 'tests',
        element: <Tests />
      },
      {
        path: 'create-test',
        element: <CreateGlobalTest />
      },
      {
        path: 'update-test/:id',
        element: <UpdateGlobalTest />
      },
      {
        path: '/edit-course-test/:id',
        element: <UpdateCourseTest />
      },
      {
        path: 'blogs',
        element: <Blogs />
      },
      {
        path: 'blogs/new',
        element: <CreateBlog />
      },
      {
        path: 'blogs/:id',
        element: <EditBlogs />
      },
      {
        path: 'shop-items',
        element: <ShopItems />
      },

      {
        path: 'categories',
        element: <Categories />
      },
      {
        path: '/gallery-management',
        element: <Gallery />
      },
      {
        path: 'tags',
        element: <Tags />
      },
      {
        path: '/home-page',
        element: <HomePage />
      },
      {
        path: '/customers',
        element: <Customers />
      },
      {
        path: '/groups',
        element: <Groups />
      },
      {
        path: '/messages-archive',
        element: <MessagesArchive />
      },
      {
        path: '/text-consultations',
        element: <Consultations />
      },
      {
        path: 'video-consultations',
        element: <VideoConsultations />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'vrc',
        element: <VirtualClassRoom />
      },
      {
        path: 'table',
        element: <Transactions />
      },

      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/login',
    element: <BaseLayout />
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Box } from '@mui/material';

type Props = {
  searchValue: string;
  setSearchValue: Function;
};

export function TableSearch({ searchValue, setSearchValue }: Props) {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '20px' }}>
      <TextField
        variant="outlined"
        placeholder={t('HeaderNavSearch')}
        label={t('HeaderNavSearch')}
        size="small"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </Box>
  );
}

import i18n from 'i18next';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { courseRequest } from 'src/models/course';
import { useMutation, useQuery } from 'react-query';
import { fetchCategories } from 'src/services/categories';
import { fetchTags } from 'src/services/tags';
import { fetchApi } from 'src/api/api';
import { ErrorFallBackNotFound } from 'src/components/error-not-found-fallback';
import CustomImageInput from 'src/components/CustomImageInput';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import AddCategories from 'src/components/categories/add-categories';
import AddTags from 'src/components/tags/add-tags';
import FileUploader from 'src/components/file-uploader';
import CustomColorPicker from 'src/components/customColorPicker';
import { fetchCourse } from 'src/services/courses';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { fetchInstructors } from 'src/services/instructors';

const inputStyle = {
  width: { xs: '100%', sm: '45%' },
  marginBottom: '30px'
};

interface Props {
  handleNext: Function;
  courseId: string;
}

const EditCourse: React.FC<Props> = ({ handleNext, courseId }) => {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
    setError
  } = useForm<courseRequest>({
    defaultValues: {}
  });

  //get old data
  const {
    data: course,
    isError,
    isLoading: profileLoading
  } = useQuery(['get-course', courseId], () => fetchCourse(courseId), {
    refetchOnWindowFocus: false, // Disable refetch on focus
    refetchOnReconnect: false
  });

  useEffect(() => {
    if (course) {
      const {
        instructors,
        lesson_count,
        in_cart,
        in_wishlist,
        translations,
        ...mCourse
      } = course;
      reset({
        ...mCourse,
        instructor_id: course.instructors.id,
        name: course.translations.ar.name || '',
        name_en: course.translations.en.name || '',
        web_description: course.translations.ar?.web_description || '',
        web_description_en: course.translations.en?.web_description || '',
        mobile_description: course.translations.ar?.mobile_description || '',
        mobile_description_en: course.translations.en.mobile_description || '',
        categories: course.categories.map((category) => category.id),
        tags: course.tags.map((tag) => tag.id)
      });
    }
  }, [course, reset]);

  const {
    data: categories,
    isLoading: l1,
    isError: e1
  } = useQuery(['get-categories'], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2
  } = useQuery(['get-tags'], () => fetchTags());

  const {
    data: instructors,
    isLoading: l4,
    isError: e4
  } = useQuery(['get-instructors'], () => fetchInstructors());

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  //   const myInstructorId = useAtomValue(instructorIdAtom);
  const { t } = useTranslation();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: editCourse
  } = useMutation(
    (data: any) => fetchApi(`/course/course/${courseId}`, 'PUT', data),
    {
      onError: () => {
        toast.error(
          i18n.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const onSubmit: SubmitHandler<courseRequest> = async (data) => {
    let modefiedData = { ...data };

    try {
      editCourse({
        ...modefiedData,
        mobile_description: data.web_description,
        mobile_description_en: data.web_description_en
      }).then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          toast.success(
            i18n.language === 'en'
              ? 'course Edited successfully'
              : 'تم تعديل الكورس بنجاح'
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  if (isError) return <ErrorFallBackNotFound />;
  if (l1 || l2 || l3 || l4 || profileLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2%',
        padding: '20px 0 50px 0',
        justifyContent: 'center'
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="course_image"
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: '5px' }}>Card Image</Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
              id={field.name}
            />
          </Box>
        )}
      />

      <Controller
        name="overview_image"
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: '5px' }}>Panner Image</Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isCover={true}
              isContain={true}
              id={field.name}
            />
          </Box>
        )}
      />

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('course name in Arabic')}
            placeholder={t('course name in Arabic')}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('course Name in English')}
            placeholder={t('course Name in English')}
            required
          />
        )}
      />
      <Controller
        name="web_description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('web_description')}
            placeholder={t('web_description')}
            required
          />
        )}
      />

      <Controller
        name="web_description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('web_description_en')}
            placeholder={t('web_description_en')}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            sx={inputStyle}
            {...field}
            label={t('Price')}
            placeholder={t('Price')}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories errors={errors} control={control} categories={categories!} />
      </Box>
      <Box sx={inputStyle}>
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>
      <Box sx={inputStyle}>
        <Controller
          name="instructor_id"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="combo-box-demo"
              options={instructors}
              getOptionLabel={(instructor) => {
                return (
                  defaultTranslation(instructor, 'first_name') +
                  ' ' +
                  defaultTranslation(instructor, 'last_name')
                );
              }}
              onChange={(e, value) => field.onChange(value ? value.id : null)} // Manually propagate the change event to the React Hook Form
              value={
                instructors.find(
                  (instructor) => instructor.id === field.value
                ) || null
              } // Find the instructor object that matches the selected instructor_id
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Select Instructor" />
              )}
            />
          )}
        />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="guide_file"
          setValue={setValue}
          accept=".pdf,.doc,.docx"
          label="upload guide file"
          value={course?.guide_file}
        />
      </Box>
      <Box sx={inputStyle}>
        <FileUploader
          control={control}
          name="description_video"
          setValue={setValue}
          accept="video/mp4"
          label="upload Introduction video"
          value={course?.description_video}
        />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '92%' },
          marginBottom: '30px'
        }}
      >
        <CustomColorPicker
          name="color"
          control={control}
          setValue={setValue}
          value={course?.color}
        />
      </Box>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '20px'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: '100%', sm: '92%', lg: '30%' } }}
        >
          <Typography fontSize="large" color="#fff">
            {t('Edit Course')}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default EditCourse;

import { CertificateResponse } from 'src/models/certificate';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import { getImageFromServer } from 'src/utils/helpers/get-image-from-server';
import { Box, Stack } from '@mui/system';
import { defaultTranslation } from 'src/utils/helpers/default-translation';

const CertificateCard = ({
  item,
  setSelectedIds,
  selectedIds,
  isPreview = false
}: {
  item: CertificateResponse;
  selectedIds?: any[];
  setSelectedIds?: any;
  isPreview?: boolean;
}) => {
  const handleSelect = (id: number) => {
    if (!selectedIds.includes(id)) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((item) => item !== id));
    }
  };
  return (
    <Card
      sx={{
        border:
          !isPreview && selectedIds.includes(item.id)
            ? '2px solid #3f3a64'
            : '',
        borderRadius: '15px'
      }}
    >
      <CardActionArea
        onClick={() => (!isPreview ? handleSelect(item.id) : null)}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <CardMedia
            component="img"
            src={getImageFromServer(item.template)}
            alt={defaultTranslation(item, 'description')}
          />
        </Box>
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Avatar src={getImageFromServer(item.logo)} />
            <Box>
              <Typography gutterBottom variant="h5" component="div">
                {defaultTranslation(item, 'donor')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {defaultTranslation(item, 'description')}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CertificateCard;

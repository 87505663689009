import CourseCertificates from '../../create-course/course-certificates/CourseCertificates';
import { useItemCertificates } from 'src/hooks/query/certificate/useItemCertificates';

const EditCourseCertificates = ({ id }: { id: number }) => {
  const { data, isLoading, isFetching } = useItemCertificates('course', id);

  return (
    <CourseCertificates
      id={id}
      assignedCertificates={data}
      isEdit={true}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};

export default EditCourseCertificates;

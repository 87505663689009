import { Skeleton, Stack } from '@mui/material';
import React from 'react';

type Props = {
  index: number;
};

function MessageSkeleton({ index }: Props) {
  return (
    <Stack
      flexDirection={index % 2 == 0 ? 'row-reverse' : 'row'}
      gap={1}
      alignItems={'center'}
      justifyContent={index % 2 == 0 ? 'flex-end' : 'flex-start'}
      sx={{ marginBottom: 1, width: '100%' }}
    >
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="text" animation="wave" width={'95%'} />
    </Stack>
  );
}

export default MessageSkeleton;

import {
  EllipsisButton,
  InfoButton,
  VideoCallButton
} from '@chatscope/chat-ui-kit-react';
import React, { useState } from 'react';
import GroupInfoModal from '../group/group-info-modal';
import { vrcGroupResponse } from 'src/models/vrc';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { CiImageOn } from 'react-icons/ci';
import { MdCommentsDisabled } from 'react-icons/md';
import { useDiableGroupChat } from 'src/hooks/query/vrc';
import { toast } from 'react-toastify';
import { BsChatRightText } from 'react-icons/bs';
import useCreateVrcGoogleMeet from 'src/hooks/query/vrc/useCreateVrcGoogleMeet';

type Props = {
  activeConversations: vrcGroupResponse;
};

function VrcHeaderActions({ activeConversations }: Props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionsButtonClick = (target: HTMLElement) => {
    setAnchorEl(target); // Set the anchor element
    setShowOptions(true);
  };

  const { mutate, isLoading } = useDiableGroupChat();

  const handleTriggerChatStatus = (read_only: boolean) => {
    setShowOptions(false);
    mutate(
      { is_read_only: read_only, id: activeConversations.id },
      {
        onSuccess: () => {
          toast.success(
            read_only
              ? 'Chat Disabled Successfully'
              : 'Chat Enabled Successfully'
          );
        },
        onError: (error: any) => {
          toast.error(error.response.data.message);
        }
      }
    );
  };

  const createMeeting = useCreateVrcGoogleMeet(activeConversations.id);
  return (
    <>
      <GroupInfoModal
        activeConversation={activeConversations}
        open={open}
        onClose={() => setOpen(false)}
      />
      <VideoCallButton
        onClick={() => createMeeting.googleLogin()}
        disabled={createMeeting.isLoading}
      />
      <InfoButton style={{ display: 'block' }} onClick={() => setOpen(true)} />
      <EllipsisButton
        orientation="vertical"
        onClick={(event) => handleOptionsButtonClick(event.currentTarget)}
        aria-controls={showOptions ? 'options-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={showOptions ? 'true' : undefined}
        id="options-button"
      />
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={showOptions}
        onClose={() => setShowOptions(false)}
        MenuListProps={{
          'aria-labelledby': 'options-button'
        }}
        anchorOrigin={{
          vertical: 'bottom', // Position the menu below the button
          horizontal: 'left' // Align the left edges of the menu and button
        }}
        transformOrigin={{
          vertical: 'top', // Align the top edges of the menu and button
          horizontal: 'left'
        }}
      >
        <MenuItem
          sx={{ justifyContent: 'space-around', gap: 1 }}
          onClick={() => {
            handleTriggerChatStatus(!activeConversations.is_read_only);
          }}
        >
          <ListItemText>
            {activeConversations.is_read_only
              ? 'Enable Group Chat'
              : 'Disable Group Chat'}
          </ListItemText>
          <ListItemIcon>
            {!activeConversations.is_read_only ? (
              <MdCommentsDisabled />
            ) : (
              <BsChatRightText />
            )}
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  );
}

export default VrcHeaderActions;

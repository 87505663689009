import { Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import React from 'react';
import { vrcGroupResponse } from 'src/models/vrc';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { getImageFromServer, getUserImageFromServer } from 'src/utils/helpers/get-image-from-server';

type Props = {
  conversation: vrcGroupResponse;
  handleConversationClick: Function;
  activeConversation: vrcGroupResponse;
  userId: number;
};

function ConversationComponent({
  conversation,
  handleConversationClick,
  activeConversation,
  userId
}: Props) {
  return (
    <Conversation
      name={conversation.name}
      lastSenderName={
        conversation.last_message
          ? conversation.last_message.user.id == userId
            ? 'You'
            : defaultTranslation(conversation.last_message.user, 'first_name') +
              ' ' +
              defaultTranslation(conversation.last_message.user, 'last_name')
          : ''
      }
      info={
        conversation.last_message
          ? conversation.last_message.type == 'text' ||
            conversation.last_message.type == 'system'
            ? conversation.last_message.message.slice(0, 20) +
              (conversation.last_message.message.length > 20 ? '...' : '')
            : conversation.last_message.type
          : null
      }
      unreadCnt={
        conversation.id == activeConversation.id
          ? 0
          : conversation.unread_message_count
      }
      unreadDot={
        conversation.id == activeConversation.id
          ? false
          : conversation.unread_message_count > 0
      }
      active={conversation.id == activeConversation.id}
      onClick={() => handleConversationClick(conversation.id)}
    >
      <Avatar
        src={getUserImageFromServer(conversation.image)}
        name={conversation.name}
        //@ts-ignore
        status={conversation.status}
      />
    </Conversation>
  );
}

export default ConversationComponent;

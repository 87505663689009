import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import AddUsers from './add-users';
import i18next from 'i18next';
import { createGroupRequest } from 'src/models/vrc';
import { useQuery, useQueryClient } from 'react-query';
import { useCreateGroup } from 'src/hooks/query/vrc';
import { fetchStudents } from 'src/services/students';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import CustomImageInput from 'src/components/CustomImageInput';
import CustomTextField from 'src/components/custom-text-field';
import fallback from '../../../assets/images/placeholder.png';
import { fetchInstructors } from 'src/services/instructors';
import AddInstructors from './add-instructors';

const inputStyle = {
  width: { xs: '100%', sm: '48%' },
  marginBottom: '30px',
  marginLeft: { sm: '16px' }
};

interface Props {
  setOpen: Function;
}
const AddGroupForm = ({ setOpen }: Props) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors }
  } = useForm<createGroupRequest>({
    defaultValues: {}
  });

  const queryClient = useQueryClient();

  const CreateGroup = useCreateGroup();

  const {
    data: students,
    isLoading: loadingStudents,
    isError,
    isFetching: fetchingStudents
  } = useQuery(['students'], () => fetchStudents());
  const {
    data: instructors,
    isLoading: loadingInstructors,
    isError: isErrorInstructors,
    isFetching: fetchingInstructors
  } = useQuery(['instructors'], () => fetchInstructors());

  const onSubmit = (data: createGroupRequest) => {
    CreateGroup.mutateAsync({
      ...data,
      users: [...data.users, ...data.instructors]
    })
      .then((res) => {
        console.log(res);
        if (res.status === 201 || res.status === 202) {
          toast.success('Group Added Successfuly');
          queryClient.invalidateQueries(['groups']);
          setOpen(false);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status == 422 &&
          error?.response?.data?.errors?.name
        ) {
          setError('name', {
            type: 'manual',
            message: 'name already exist'
          });
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  return (
    <Box>
      {loadingStudents || loadingInstructors ? (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Stack
                width={'100%'}
                flexDirection={'row'}
                spacing={2}
                justifyContent={'space-around'}
                alignItems={'center'}
                flexWrap={'wrap'}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1rem',
                    width: { xs: '100%', sm: '60%' }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: { xs: '100%' }
                    }}
                  >
                    <CustomTextField
                      name="name"
                      control={control}
                      defaultValue=""
                      label={'Group Name'}
                      placeholder={'Group Name'}
                      required
                      errors={errors}
                      inputStyle={{ width: '100%', marginBottom: '30px' }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: { xs: '100%' },
                      marginBottom: '30px'
                    }}
                  >
                    <AddUsers
                      users={students!}
                      control={control}
                      errors={errors}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: { xs: '100%' }
                    }}
                  >
                    <AddInstructors
                      users={instructors!}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Stack>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1.5rem',
                    width: { xs: '100%', sm: '30%' }
                  }}
                >
                  <Controller
                    name="image"
                    rules={{
                      required:
                        i18next.language == 'en'
                          ? 'Image is required'
                          : 'الصورة مطلوبة'
                    }}
                    control={control}
                    render={({ field }) => (
                      <Box sx={{ width: '100%', marginBottom: '30px' }}>
                        <Typography sx={{ paddingBottom: '5px' }}>
                          Group Image
                        </Typography>
                        <CustomImageInput
                          id="group-image"
                          file={field.value}
                          fallback={fallback}
                          handleChange={(e: any) => {
                            blobToBase64(e)
                              .then((value) => {
                                field.onChange(value); // Update the field value with the base64 string
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                          fieldName={field.name}
                          classesForStyles=""
                        />
                        <Typography color="error" sx={{ paddingBottom: '5px' }}>
                          {errors?.image?.message}
                        </Typography>
                      </Box>
                    )}
                  />
                </Box>
              </Stack>
              <Box sx={{ marginLeft: '16px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={CreateGroup.isLoading} // Disable the button when loading
                  startIcon={
                    CreateGroup.isLoading && <CircularProgress size={20} />
                  } // Show CircularProgress when loading
                >
                  Add Group
                </Button>
              </Box>
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default AddGroupForm;

import { fetchApi } from 'src/api/api';
import { CertificateResponse } from 'src/models/certificate';
import {
  Recents,
  courseLesson,
  courseResponse,
  courseSection,
  courseTestInformation,
  courseTestResponse,
  relatedItemsResponse,
  studentCourse
} from 'src/models/course';
import { testQuestionsResponseForStudent } from 'src/models/test';

export const fetchCourses = async (search?: string): Promise<any[]> => {
  const response = await fetchApi(
    `/course/searchCourse?filter[search]=${search}&filter[is_approved]=1&sort=-created_at`,
    'GET',
    {}
  );
  return response.data.data as any[];
};

export const fetchStudentCourses = async (
  id: number,
  search?: string
): Promise<any[]> => {
  const response = await fetchApi(
    `/course/courseUser/admin/${id}?filter[search]=${search}&filter[is_approved]=1`,
    'GET',
    {}
  );
  return response.data.data as studentCourse[];
};

export const fetchTopCourses = async (): Promise<courseResponse[]> => {
  const response = await fetchApi(`/course/topCourses`, 'GET', {});
  return response.data.data as courseResponse[];
};

export const fetchUnapprovedCourses = async (
  search?: string
): Promise<courseResponse[]> => {
  const response = await fetchApi(
    `/course/searchCourse?filter[search]=${search}&filter[is_approved]=0&sort=-created_at`,
    'GET',
    {}
  );
  return response.data.data as courseResponse[];
};

export const updateTopCourses = async (data: any): Promise<any> => {
  const response = await fetchApi(
    '/course/course/updateTopCourses',
    'POST',
    data
  );
  return response.data;
};
export const fetchCourse = async (
  id: string | undefined
): Promise<courseResponse> => {
  const response = await fetchApi('/course/course/' + id, 'GET', {});
  return response.data.data as courseResponse;
};

export const fetchSections = async (
  courseId: string
): Promise<courseSection[]> => {
  const response = await fetchApi(
    `/course/section?course_id=${courseId}`,
    'GET',
    {}
  );
  const sections: courseSection[] = response.data.data;
  sections.forEach((section) => {
    section.lessons.sort((a, b) => a.order - b.order);
  });
  sections.sort((a, b) => a.order - b.order);
  return sections;
};

export const fetchCourseRelatedItems = async (
  id: number
): Promise<relatedItemsResponse> => {
  const response = await fetchApi(
    `/course/showMyRelatableItem?course_id=${id}`,
    'GET',
    {}
  );
  return response.data.data as relatedItemsResponse;
};

export const fetchSectionsWithoutFiles = async (
  courseId: string
): Promise<courseSection[]> => {
  const response = await fetchApi(
    `/course/sectionWithoutFiles?course_id=${courseId}`,
    'GET',
    {}
  );
  const sections: courseSection[] = response.data.data;
  sections.forEach((section) => {
    section.lessons.sort((a, b) => a.order - b.order);
  });
  sections.sort((a, b) => a.order - b.order);
  return sections;
};

export const fetchCourseTests = async (
  id: string
): Promise<courseTestResponse[]> => {
  const response = await fetchApi(`/course/showMyTests/${id}`, 'GET', {});
  return response.data.data as courseTestResponse[];
};

export const fetchCourseRecents = async (): Promise<Recents[]> => {
  const response = await fetchApi(`/course/RecentCourse`, 'GET', {});
  return response.data.data as Recents[];
};

export const fetchCourseTestQuestionsForStudent = async (
  id: string
): Promise<testQuestionsResponseForStudent> => {
  const response = await fetchApi(`/test/test/forStudent/${id}`, 'GET', {});
  const questions: testQuestionsResponseForStudent = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchLessonInfo = async (id: string): Promise<courseLesson> => {
  const response = await fetchApi(`/course/lesson/${id}`, 'GET', {});
  return response.data.data as courseLesson;
};

export const fetchCourseTestInformation = async (
  id: number
): Promise<courseTestInformation> => {
  const response = await fetchApi(`/test/test/${id}`, 'GET', {});

  return response.data.data as courseTestInformation;
};

export const fetchCertificates = async (): Promise<CertificateResponse[]> => {
  const response = await fetchApi('/general/certificate');
  return response.data.data as CertificateResponse[];
};

export const assignCertificate = async (data: any): Promise<any> => {
  const response = await fetchApi('/general/certificate/assign', 'POST', data);
  return response.data;
};

export const fetchItemCertificate = async (
  type: string,
  id: number
): Promise<CertificateResponse[]> => {
  const response = await fetchApi(
    `/general/certificate/getItemCertificates?type=${type}&id=${id}`
  );
  return response.data.data as CertificateResponse[];
};

import { fetchApi } from 'src/api/api';
import { categoryResponse } from 'src/models/categories';

export const fetchTopCategories = async (): Promise<any[]> => {
  const response = await fetchApi(`/category/topCategories`, 'GET', {});
  return response.data.data as any[];
};

export const updateTopCategory = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/category/category/updateTop/categories`,
    'POST',
    data
  );
  return response.data;
};

export const fetchCategories = async (): Promise<categoryResponse[]> => {
  const response = await fetchApi(`/category/category`, 'GET', {});
  return response.data.data as categoryResponse[];
};

export const fetchCategory = async (id: number): Promise<categoryResponse> => {
  const response = await fetchApi(`/category/category/${id}`, 'GET', {});
  return response.data.data as categoryResponse;
};

import { useMutation } from 'react-query';
import { addMembersToGroupsRequest } from 'src/models/CRM';
import { PostMembersToGroups } from 'src/services/CRM/customers';

export default function useAddToGroup() {
  return useMutation(
    (data: addMembersToGroupsRequest) => PostMembersToGroups(data),
    {}
  );
}

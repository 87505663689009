import { fetchApi } from 'src/api/api';
import { tagResponse } from 'src/models/tag';

export const fetchTags = async (): Promise<tagResponse[]> => {
  const response = await fetchApi(`/general/tag`, 'GET', {});
  return response.data.data as tagResponse[];
};

export const fetchTag = async (id: number): Promise<tagResponse> => {
  const response = await fetchApi(`/general/tag/${id}`, 'GET', {});
  return response.data.data as tagResponse;
};

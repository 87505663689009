import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { userInstructor, userStudent } from 'src/models/user';
import { defaultTranslation } from 'src/utils/helpers/default-translation';

type Props = {
  users: userInstructor[];
  control: any;
  errors: any;
};

const AddInstructors = (props: Props) => {
  return (
    <Controller
      name="instructors"
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="instructors-filled"
            filterSelectedOptions
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option === value}
            options={props.users?.map((user: userInstructor) => user.user_id)}
            getOptionLabel={(option) => {
              const user = props.users?.find((user) => user.user_id === option);
              return user
                ? defaultTranslation(user, 'first_name') +
                    ' ' +
                    defaultTranslation(user, 'last_name')
                : option.user_id;
            }}
            freeSolo
            renderTags={(value: number[], getuserProps) =>
              value.map((option: number, index: number) => {
                const user = props.users?.find(
                  (user) => user.user_id === option
                );
                return (
                  <Chip
                    variant="outlined"
                    label={
                      user
                        ? defaultTranslation(user, 'first_name') +
                          ' ' +
                          defaultTranslation(user, 'last_name')
                        : option
                    }
                    {...getuserProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Instructors"
                error={props.errors && !!props.errors.user}
                helperText={
                  props.errors &&
                  props.errors.user &&
                  props.errors?.user?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the user IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddInstructors;

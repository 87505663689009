//virtual class room

import { fetchApi } from 'src/api/api';
import { userStudent } from 'src/models/user';
import {
  createGroupRequest,
  createVrcMeeting,
  updateGroupRequest,
  vrcGroupMessage,
  vrcGroupResponse
} from 'src/models/vrc';

export const CreateVrcGroup = async (
  data: createGroupRequest
): Promise<any> => {
  const response = await fetchApi('/chat/room', 'POST', data);
  return response.data;
};

export const UpdateVrcGroup = async (
  data: updateGroupRequest
): Promise<any> => {
  const response = await fetchApi(`/chat/room/${data.id}`, 'PUT', data);
  return response.data;
};

export const fetchStudents = async (): Promise<userStudent[]> => {
  const response = await fetchApi(`/auth/user?role=student`, 'GET', {});
  return response.data.data as userStudent[];
};

export const fetchVrcGroups = async (): Promise<vrcGroupResponse[]> => {
  const response = await fetchApi(`/chat/room`, 'GET', {});
  return response.data.data as vrcGroupResponse[];
};

export const fetchVrcGroupById = async (
  id: number
): Promise<vrcGroupResponse> => {
  const response = await fetchApi(`/chat/room/${id}`, 'GET', {});
  return response.data.data as vrcGroupResponse;
};

export const fetchVrcGroupMessagesById = async (
  id: number
): Promise<vrcGroupMessage[]> => {
  const response = await fetchApi(
    `/chat/chat/getAllMessageForRoom/${id}`,
    'GET',
    {}
  );
  return response.data.data as vrcGroupMessage[];
};

export const DeleteVrcGroupUsers = async (data: {
  users: number[];
  id: number;
}): Promise<any> => {
  const response = await fetchApi(
    `/chat/room/unAssign/users/${data.id}`,
    'PUT',
    data
  );
  return response.data;
};

export const AddVrcGroupUsers = async (data: {
  users: number[];
  id: number;
}): Promise<any> => {
  const response = await fetchApi(
    `/chat/room/assign/users/${data.id}`,
    'PUT',
    data
  );
  return response.data;
};

export const DisableGroupChat = async (data: {
  is_read_only: boolean;
  id: number;
}): Promise<any> => {
  const response = await fetchApi(
    `/chat/room/readonly/${data.id}`,
    'PUT',
    data
  );
  return response.data;
};


export const CreateVrcMeeting = async (
  id: number,
  token: string
): Promise<createVrcMeeting> => {
  const response = await fetchApi(`/chat/chat/google/meet/${id}`, "POST", {
    token: token,
  });
  return response.data;
};


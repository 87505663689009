import { Box, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import { SlClose } from 'react-icons/sl';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css'; // import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { ConstructionOutlined } from '@mui/icons-material';
import useDeleteAlbumPhoto from 'src/hooks/query/gallery/useDeleteAlbumPhoto';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

function CustomPhotoAlbum({ images, albumId }) {
  const [index, setIndex] = useState(-1);

  const renderCustomImage = (image) => {
    const { src, alt, style, ...restImageProps } = image;
    const DeleteAlbumPhoto = useDeleteAlbumPhoto(albumId);
    const queryClient = useQueryClient();

    const handleDeleteImage = (imageId) => {
      DeleteAlbumPhoto.mutateAsync(imageId)
        .then((res) => {
          console.log(res);
          if (res.status === 203) {
            toast.success('Image Deleted Successfully');
            queryClient.invalidateQueries(['album', albumId]);
            queryClient.invalidateQueries(['albums']);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong, please try again later.');
        });
    };
    return (
      <div style={{ position: 'relative' }}>
        <IconButton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            borderRadius: '20px'
          }}
          onClick={() => handleDeleteImage(image.photo.id)}
          disabled={DeleteAlbumPhoto.isLoading}
        >
          {!DeleteAlbumPhoto.isLoading && <SlClose />}
          {DeleteAlbumPhoto.isLoading && <CircularProgress size={18} />}
        </IconButton>
        <img
          src={image.photo.src}
          style={{ borderRadius: '20px', cursor: 'pointer' }}
          height={image.layout.height}
          width={image.layout.width}
          {...restImageProps}
          onClick={() => setIndex(image.layout.index)}
        />
      </div>
    );
  };

  return (
    <Box className="container" py={'3rem'}>
      <PhotoAlbum
        photos={images}
        renderPhoto={renderCustomImage}
        layout="rows"
        targetRowHeight={150}
      />
      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </Box>
  );
}

function PhotoAlbumContent(data) {
  const [photos, setPhotos] = useState();
  const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];
  useEffect(() => {
    if (data.data && data.data.media) {
      const photos = data?.data?.media?.map((photo) => {
        return {
          src: photo.original_url,
          id: photo.id,
          width: 1440,
          height: 1080,
          srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((1440 / 1080) * breakpoint);
            return {
              src: photo.original_url,
              width: breakpoint,
              height
            };
          })
        };
      });
      setPhotos(photos);
    }
  }, [data]);

  return <CustomPhotoAlbum images={photos} albumId={data?.data?.id} />;
}

export default PhotoAlbumContent;

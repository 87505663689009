import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

// @ts-ignore
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput
} from 'react-international-phone';

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: Function;
  controle_name: string;
  disabled?: boolean;
  label: string;
  forceDialCode?: boolean;
  clearError: Function;
  sx: any;
  setError: Function;
  setIsPhoneValid: Function;
}

export const MuiPhone: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  controle_name,
  disabled,
  label,
  forceDialCode,
  clearError,
  setError,
  setIsPhoneValid,
  sx,
  ...restProps
}) => {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'sy',
      value,
      forceDialCode: forceDialCode,
      charAfterDialCode: '',
      countries: defaultCountries,
      onChange: (data) => {
        onChange(controle_name, data.phone);
        clearError();
      }
    });

  useEffect(() => {
    let phoneNumber = null;
    if (phone.length > 6) {
      phoneNumber = phoneUtil.parseAndKeepRawInput(phone, country);
      if (
        '+' + phoneNumber?.values_['1'] + phoneNumber?.values_['2'] != phone ||
        !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
      ) {
        setError(controle_name, {
          type: 'manual',
          message: `Phone Number is Not Valid ${
            country == 'sy'
              ? `, Phone Number should be like this +9639xxxxxxxx`
              : ``
          }`
        });
        setIsPhoneValid(false);
      } else {
        setIsPhoneValid(true);
        clearError(controle_name);
      }
    }
  }, [phone, country]);

  return (
    <Stack sx={{ ...sx, display: 'inline-flex' }}>
      <TextField
        variant="outlined"
        label={label}
        color="primary"
        placeholder={label}
        value={phone}
        onChange={handlePhoneValueChange}
        type="tel"
        disabled={disabled}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginRight: '2px', marginLeft: '-8px' }}
            >
              <Select
                disabled={disabled}
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                sx={{
                  width: 'max-content',
                  // Remove default outline (display only on focus)
                  fieldset: {
                    display: 'none'
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: 'block'
                    }
                  },
                  // Update default spacing
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important'
                  },
                  svg: {
                    right: 0
                  }
                }}
                value={country}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => (
                  <FlagEmoji iso2={value} style={{ display: 'flex' }} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagEmoji
                        iso2={country.iso2}
                        style={{ marginRight: '8px' }}
                      />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          )
        }}
        {...restProps}
      />
    </Stack>
  );
};

import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import './App.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import authRoutes from './auth-router';
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  const content = useRoutes(router);
  const authContent = useRoutes(authRoutes);
  const { isAuth } = useContext(AuthContext);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <CssBaseline />
          {isAuth && content}
          {!isAuth && authContent}
        </GoogleOAuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

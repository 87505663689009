import { toast } from 'react-toastify'; // Assuming you are using react-toastify
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation, useQueryClient } from 'react-query';
import { CreateVrcMeeting } from 'src/services/vrc';
import { createVrcMeeting } from 'src/models/vrc';

const useCreateVrcGoogleMeet = (id: number) => {
  const queryClient = useQueryClient();

  const { mutateAsync: accept, isLoading } = useMutation(
    ({ id, token }: createVrcMeeting) => CreateVrcMeeting(id, token),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
        toast.error('something went wrong, please try again later');
      }
    }
  );

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      accept({
        id: id,
        token: tokenResponse.access_token
      });
    },
    scope:
      'https://www.googleapis.com/auth/meetings.space.created email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
  });

  return { googleLogin, isLoading };
};

export default useCreateVrcGoogleMeet;

import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { TableSearch } from 'src/components/table-search';
import LoadingTable from 'src/components/loading-table';
import { customerResponse } from 'src/models/CRM';

interface Props {
  data: customerResponse[];
  isLoading: boolean;
  setOpen: Function;
  setId: Function;
  isFetching: boolean;
  setSearchValue: Function;
  searchValue: string;
  selected: { id: number; type: string }[];
  setSelected: Function;
  setDeleteOpen: Function;
}

export default function CustomersTable({
  data,
  isLoading,
  setOpen,
  setId,
  isFetching,
  setSearchValue,
  searchValue,
  selected,
  setSelected,
  setDeleteOpen
}: Props) {
  const [page, setPage] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(5);
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLimit(parseInt(event.target.value));
  };

  const applyPagination = (data: customerResponse[], page, limit) => {
    return data && data.slice(page * limit, page * limit + limit);
  };

  const paginatedData = applyPagination(data, page, limit);

  const theme = useTheme();

  //selection logic

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => ({ id: n.id, type: 'user' }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, item) => {
    const selectedIndex = selected.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) =>
    selected.findIndex((item) => item.id === id) !== -1;

  // end of selection logic

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardHeader title={'Customers Table'} />
        <TableSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Stack>
      {selected?.length > 0 && (
        <CardHeader
          title={`${selected.length} Customers Selected`}
          sx={{ paddingTop: 0 }}
        />
      )}
      <Divider />
      <LoadingTable isLoading={isLoading} isFetching={isFetching} columns={8}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected?.length > 0 && selected?.length < data?.length
                    }
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell align="center">
                  <Typography>ID</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Phone Number</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Email</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Job Title</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Type</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Groups Count</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Action</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {paginatedData && paginatedData.length > 0 ? (
              <TableBody>
                {paginatedData.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    onClick={(event) =>
                      handleClick(event, { id: item.id, type: 'customer' })
                    }
                    role="checkbox"
                    aria-checked={isSelected(item.id)}
                    selected={isSelected(item.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(item.id)}
                        onClick={(event) =>
                          handleClick(event, { id: item.id, type: 'customer' })
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.first_name + ' ' + item.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.phone_number}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.job_title}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.is_vip ? (
                          <Chip
                            label="VIP"
                            color="primary"
                            sx={{ width: '80px' }}
                          />
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.groups.length}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        <Tooltip title="Edit Customer" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpen(true);
                              setId(item.id);
                            }}
                          >
                            <EditTwoTone fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Customer" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.error.lighter
                              },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setId(item.id);
                              setDeleteOpen(true);
                            }}
                          >
                            <DeleteTwoTone />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                minHeight={100}
                width="100%"
              >
                <Typography>No Data Found</Typography>
              </Stack>
            )}
          </Table>
        </TableContainer>
      </LoadingTable>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data?.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
}

import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { courseResponse } from 'src/models/course';
import { shopItemResponse } from 'src/models/shop-item';
import { BlogResponse } from 'src/models/blog';
import { useMutation } from 'react-query';
import { fetchApi } from 'src/api/api';

type Props = {
  tags: any[];
  name: 'course' | 'shopitem' | 'blog' | 'globaltest';
  control: any;
  label: string;
  watch?: any;
  setValue?: any;
  oldValue?: courseResponse[] | shopItemResponse[] | BlogResponse[];
  courseId?: number;
};

const AddRelatedItem = (props: Props) => {
  const { isLoading: loadingRequest, mutateAsync: deleteTag } = useMutation(
    (data: any) =>
      fetchApi(
        `/course/relatedItem/deleteRelatableItem/${props.courseId}`,
        'DELETE',
        data
      ),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const [myOldValue, setMyOldValue] = useState<any[]>([]);

  useEffect(() => {
    if (props.oldValue) {
      setMyOldValue(props.oldValue);
    }
  }, [props.oldValue]);

  const handleDelete = (tag: any) => {
    //if there is old value mutate delete api (used in update course)
    if (props.oldValue && myOldValue.some((item: any) => item.id === tag.id)) {
      deleteTag({ relatable: [tag] });
      const updated = myOldValue.filter((item: any) => item.id != tag.id);
      setMyOldValue(updated);
    }

    //delete from react hook form value
    const newValue = props
      .watch(props.name)
      .filter((item: any) => item.id !== tag.id);
    props.setValue(props.name, newValue);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id="tags-filled"
          options={props.tags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          renderTags={(value: any[], getTagProps) =>
            value.map((option: any, index: number) => (
              <Chip
                key={option}
                variant="outlined"
                label={option.name}
                onDelete={() => handleDelete(option)}
                // {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={props.label} />
          )}
          onChange={(event, data, option) => {
            field.onChange(data); // Update the field value with the tag ids
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      )}
    />
  );
};

export default AddRelatedItem;

import i18n from 'i18next';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
  IconButton,
  CardContent,
  Stack,
  Avatar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { globalTestRequest } from 'src/models/global-test';
import { useMutation, useQuery } from 'react-query';
import { fetchCategories } from 'src/services/categories';
import { fetchTags } from 'src/services/tags';
import { fetchApi } from 'src/api/api';
import { ErrorFallBackNotFound } from 'src/components/error-not-found-fallback';
import CustomImageInput from 'src/components/CustomImageInput';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import AddCategories from 'src/components/categories/add-categories';
import AddTags from 'src/components/tags/add-tags';
import { fetchGlobalTest } from 'src/services/tests';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { fetchInstructors } from 'src/services/instructors';
import GradeInputSection from '../create-global-test/grade-input-section';
import { CertificateResponse } from 'src/models/certificate';
import { Close } from '@mui/icons-material';
import { getImageFromServer } from 'src/utils/helpers/get-image-from-server';
import QuizzCertificates from './QuizzCertificates';

const inputStyle = {
  width: { xs: '100%', sm: '45%' },
  marginBottom: '30px'
};

interface Props {
  handleNext?: Function;
  testId: string;
}

const EditGlobalTest: React.FC<Props> = ({ handleNext, testId }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
    setError
  } = useForm<globalTestRequest>({
    defaultValues: {}
  });

  //get old data
  const {
    data: test,
    isError,
    isLoading: profileLoading
  } = useQuery(['get-test', testId], () => fetchGlobalTest(testId), {
    refetchOnWindowFocus: false, // Disable refetch on focus
    refetchOnReconnect: false
  });

  const [selectedCertificates, setSelectedCertificates] = useState<
    CertificateResponse[]
  >([]);
  const [certificates, setCertificates] = useState<number[]>([]);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);

  useEffect(() => {
    if (test) {
      const { instructors, in_cart, in_wishlist, translations, ...mtest } =
        test;
      reset({
        ...mtest,
        name: test?.translations?.ar?.name || '',
        name_en: test?.translations?.en?.name || '',
        description: test?.translations?.ar?.description || '',
        description_en: test?.translations?.en?.description || '',
        categories: test.categories.map((category) => category.id),
        tags: test.tags.map((tag) => tag.id),
        instructor_id: test.instructors.id,
        evaluatable: test.evaluation?.length
          ? test.evaluation.map((item) => ({
              value: item.value,
              text: item.translations?.ar?.text,
              text_en: item.translations?.en?.text
            }))
          : []
      });
      setSelectedCertificates(test.certificates);
      const arr = test.certificates.map((item) => item.id);
      setCertificates(arr);
    }
  }, [test, reset]);

  const {
    data: categories,
    isLoading: l1,
    isError: e1
  } = useQuery(['get-categories'], () => fetchCategories());

  const {
    data: tags,
    isLoading: l2,
    isError: e2
  } = useQuery(['get-tags'], () => fetchTags());

  const {
    data: instructors,
    isLoading: l4,
    isError: e4
  } = useQuery(['get-instructors'], () => fetchInstructors());

  const [localError, setLocalError] = useState(null); //to handle file updloading errors
  const { t } = useTranslation();

  const {
    isLoading: l3,
    isError: e3,
    mutateAsync: edittest
  } = useMutation(
    (data: any) => fetchApi(`/test/globalTest/${testId}`, 'PUT', data),
    {
      onError: () => {
        toast.error(
          i18n.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const onSubmit: SubmitHandler<globalTestRequest> = async (data) => {
    let modefiedData = { ...data };

    try {
      edittest({
        ...modefiedData,
        certificate: certificates.map((id) => id)
      }).then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          toast.success(
            i18n.language === 'en'
              ? 'Test Edited successfully'
              : 'تم تعديل الاختبار بنجاح'
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveCertificate = (id: number) => {
    setSelectedCertificates((prev) => prev.filter((item) => item.id !== id));
    setCertificates((prev) => prev.filter((crId) => crId !== id));
  };

  if (isError) return <ErrorFallBackNotFound />;
  if (l1 || l2 || l3 || profileLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <form
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2%',
        padding: '20px 0 50px 0',
        justifyContent: 'center'
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width="100%" sx={{ textAlign: 'center' }}>
        <Typography variant="h3" color="primary" sx={{ paddingTop: '20px' }}>
          Edit Test
        </Typography>
      </Box>
      <Controller
        name="image"
        rules={{ required: 'Image is required' }}
        control={control}
        render={({ field }) => (
          <Box sx={inputStyle}>
            <Typography sx={{ paddingBottom: '5px' }}>Test Image</Typography>
            <CustomImageInput
              file={field.value}
              handleChange={(e: any) => {
                blobToBase64(e)
                  .then((value) => {
                    field.onChange(value); // Update the field value with the base64 string
                  })
                  .catch((err) => {
                    console.log(err);
                    setLocalError(err);
                  });
              }}
              fieldName={field.name}
              isContain={true}
              isCover={true}
              id={field.name}
            />
            <Typography color="error" sx={{ paddingBottom: '5px' }}>
              {errors?.image?.message}
            </Typography>
          </Box>
        )}
      />
      <Box sx={inputStyle}></Box>

      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Test name in Arabic')}
            placeholder={t('Test name in Arabic')}
            required
          />
        )}
      />
      <Controller
        name="name_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Test Name in English')}
            placeholder={t('Test Name in English')}
            required
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Description in Arabic')}
            placeholder={t('Description in Arabic')}
            required
          />
        )}
      />

      <Controller
        name="description_en"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            sx={inputStyle}
            {...field}
            label={t('Description in English')}
            placeholder={t('Description in English')}
            required
          />
        )}
      />

      <Controller
        name="price"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            type="text"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            sx={inputStyle}
            {...field}
            label={t('Price')}
            placeholder={t('Price')}
            required
          />
        )}
      />

      <Box sx={inputStyle}>
        <AddCategories
          errors={errors}
          control={control}
          categories={categories!}
        />
      </Box>
      <Box sx={inputStyle}>
        <AddTags errors={errors} tags={tags!} control={control} />
      </Box>

      <Box sx={inputStyle}>
        <Controller
          name="instructor_id"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="combo-box-demo"
              options={instructors}
              getOptionLabel={(instructor) => {
                return (
                  defaultTranslation(instructor, 'first_name') +
                  ' ' +
                  defaultTranslation(instructor, 'last_name')
                );
              }}
              onChange={(e, value) => field.onChange(value ? value.id : null)} // Manually propagate the change event to the React Hook Form
              value={
                instructors?.find(
                  (instructor) => instructor.id === field.value
                ) || null
              } // Find the instructor object that matches the selected instructor_id
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Select Instructor" />
              )}
            />
          )}
        />
      </Box>

      <Controller
        name="attempts_number"
        control={control}
        defaultValue={1}
        render={({ field }) => (
          <TextField
            type="number"
            inputProps={{ inputMode: 'numeric', min: 1 }}
            sx={{
              width: { xs: '100%', sm: '92%' },
              marginBottom: '30px'
            }}
            {...field}
            label="number of allowed attemps"
            placeholder="number of allowed attemps"
            required
          />
        )}
      />

      {watch('is_graded') == true && (
        <GradeInputSection
          getValues={getValues}
          setValues={setValue}
          errors={errors}
          testId={0}
          control={control}
          formMethod="update"
        />
      )}
      {watch('is_graded') && (
        <Box width="90%">
          <Button
            onClick={() => setIsCertificateOpen(true)}
            variant="contained"
          >
            Add Certificates
          </Button>
          <Box display="flex" gap="1rem" flexWrap="wrap" my="1.5rem">
            {selectedCertificates.map((item: CertificateResponse) => (
              <Card sx={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', right: '5px', top: '5px' }}
                  onClick={() => handleRemoveCertificate(item.id)}
                >
                  <Close />
                </IconButton>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Avatar src={getImageFromServer(item.logo)} />
                    <Box>
                      <Typography variant="h6">
                        {defaultTranslation(item, 'donor')}
                      </Typography>
                      <Typography variant="body2">
                        {defaultTranslation(item, 'description')}
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
      {watch('is_graded') && isCertificateOpen && (
        <QuizzCertificates
          selectedIds={certificates}
          setSelectedIds={setCertificates}
          open={isCertificateOpen}
          setOpen={setIsCertificateOpen}
          setSelectedCertificates={setSelectedCertificates}
        />
      )}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '20px'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className="mb-2 btn-hover-secondary btn-width-100"
          type="submit"
          sx={{ width: { xs: '100%', sm: '92%', lg: '30%' } }}
          disabled={
            watch('is_graded') &&
            (!watch('evaluatable') || watch('evaluatable')?.length == 0)
          }
        >
          <Typography fontSize="large" color="#fff">
            {t('Edit Test')}
          </Typography>
        </Button>
      </div>
    </form>
  );
};

export default EditGlobalTest;

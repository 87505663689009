import { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Paper,
  Step,
  StepButton,
  Stepper
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import CourseDND from '../create-course/course-dnd';
import EditCourse from './edit-course';
import EditRelatedCourseItems from './edit-related-course-items';
import EditCourseCertificates from './course-certificates';

const EditCourseHOC = () => {
  const steps = [
    'Edit Course Information',
    'Edit Related Items',
    'Edit Course Resources',
    'Edit Course Certificates'
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const { id } = useParams();

  return (
    <>
      <Container>
        <Paper elevation={3} sx={{ marginY: '50px', padding: '20px' }}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{ background: 'transparent' }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {activeStep == 0 ? (
            <React.Fragment>
              <EditCourse handleNext={handleNext} courseId={id!} />
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          ) : activeStep == 1 ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                )}
              </Box>
              <EditRelatedCourseItems
                id={parseInt(id!)}
                handleNext={handleNext}
              />
            </React.Fragment>
          ) : activeStep == 2 ? (
            <React.Fragment>
              <CourseDND id={parseInt(id!)} />
            </React.Fragment>
          ) : (
            <EditCourseCertificates id={parseInt(id!)} />
          )}
        </Paper>
      </Container>
    </>
  );
};

export default EditCourseHOC;

import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
  } from "@mui/material";
  import i18next from "i18next";
  import { Controller } from "react-hook-form";
  import CheckIcon from "@mui/icons-material/Check";
  import ClearIcon from "@mui/icons-material/Clear";
  import { useTranslation } from "react-i18next";
  
  type Props = {
    control: any;
    default?: boolean;
  };
  
  const IsGradedRadio = (props: Props) => {
    const { t } = useTranslation();
    return (
      <Controller
        name="is_graded"
        control={props.control}
        defaultValue={props.default ? props.default : false}
        render={({ field }) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={3}
            sx={{
              width: "90%",
              marginBottom: "30px",
            }}
          >
            <Typography>
              {i18next.language == "en"
                ? "Is The Test Graded ?"
                : "هل هذا الاختبار مقيم ؟"}
            </Typography>
            <RadioGroup
              row
              {...field}
              onChange={(e) => field.onChange(e.target.value === "true")}
            >
              <FormControlLabel
                value={true}
                control={<Radio sx={{ display: "none" }} />} // Hide the radio circle
                label={
                  <Box
                    sx={{
                      padding: "10px 50px",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid #bbb",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderColor: field.value === true ? "primary.main" : "#bbb", // Primary color for selected "Yes"
                    }}
                  >
                    <CheckIcon
                      sx={{
                        color: field.value === true ? "primary.main" : "#bbb",
                      }}
                    />
  
                    <Typography
                      sx={{
                        color: field.value === true ? "primary.main" : "#bbb",
                      }}
                    >
                      {t("Yes")}
                    </Typography>
                  </Box>
                }
              />
  
              <FormControlLabel
                value={false}
                control={<Radio sx={{ display: "none" }} />} // Hide the radio circle
                label={
                  <Box
                    sx={{
                      padding: "10px 50px",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid #bbb",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderColor:
                        field.value === false ? "primary.main" : "#bbb", // Primary color for selected "No"
                    }}
                  >
                    <ClearIcon
                      sx={{
                        color: field.value === false ? "primary.main" : "#bbb",
                      }}
                    />
  
                    <Typography
                      sx={{
                        color: field.value === false ? "primary.main" : "#bbb",
                      }}
                    >
                      {t("No")}
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </Stack>
        )}
      />
    );
  };
  
  export default IsGradedRadio;
  
import { Box } from '@mui/system';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import CustomImageInput from 'src/components/CustomImageInput';
import { blobToBase64 } from 'src/utils/helpers/blop-top-base-64';
import fallback from '../../../assets/images/user-holder.png';
import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { register } from 'src/models/user';
import AddInstructor from 'src/hooks/query/instructors/AddInstructor';
import AddStudent from 'src/hooks/query/students/add-student';
import UpdateStudent from 'src/hooks/query/students/update-student';
import useStudent from 'src/hooks/query/students/use-student';
import ResetPasswordModal from './reset-password-modal';
import { MuiPhone } from 'src/components/mui-phone';

const inputStyle = {
  width: { xs: '100%', sm: '48%' },
  marginBottom: '30px',
  marginLeft: { sm: '16px' }
};
const AddStudentForm = ({ setOpen, id }) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm<register>({
    defaultValues: {}
  });

  const [localError, setLocalError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const queryClient = useQueryClient();

  const addStudent = AddStudent();

  const updateStudent = UpdateStudent();

  //state for reset password modal
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleOpenResetPassword = () => setOpenResetPassword(true);
  const handleCloseResetPassword = () => setOpenResetPassword(false);

  const { data: studentData, isLoading, isFetching } = useStudent(id);

  const onSubmit = (data: register) => {
    if (data.profile_image == '') delete data.profile_image;
    const formData = {
      ...data,
      role: 'student'
    };
    addStudent
      .mutateAsync(formData)
      .then((res) => {
        console.log(res);
        if (res.status == 201 || res.status == 202 || res.status == 200) {
          toast.success('student Added Successfuly');
          queryClient.invalidateQueries('students');
          setOpen(false);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status == 422 &&
          error?.response?.data?.errors?.email
        ) {
          setError('email', {
            type: 'manual',
            message: 'Email already exist'
          });
          toast.error('Email already exist');
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  const onUpdate = (data: register) => {
    if (data.profile_image == '') delete data.profile_image;
    const formData = {
      ...data,
      role: 'student'
    };
    updateStudent.mutateAsync({ id, data: formData }).then((res) => {
      if (res.status === 201 || res.status === 202 || res.status === 200) {
        toast.success('student Updated Successfuly');
        queryClient.invalidateQueries('students');
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    if (studentData && id !== 0) {
      reset({
        first_name: studentData.translations.ar.first_name || '',
        last_name: studentData.translations.ar.last_name || '',
        first_name_en: studentData.translations.en?.first_name || '',
        last_name_en: studentData.translations.en?.last_name || '',
        address: studentData.address || '',
        birth_date: studentData.birth_date || '',
        phone_number: studentData.phone_number || '',
        email: studentData.email || '',
        profile_image: studentData.profile_image || '',
        gender: studentData.gender
      });
    }

    if (id == 0) {
      reset();
    }
  }, [studentData, reset, id]);

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  return (
    <Box>
      {isLoading || isFetching ? (
        <SuspenseLoader />
      ) : (
        <>
          {id != 0 && (
            <ResetPasswordModal
              open={openResetPassword}
              onClose={handleCloseResetPassword}
              user_id={id}
            />
          )}
          <form
            onSubmit={
              id === 0 ? handleSubmit(onSubmit) : handleSubmit(onUpdate)
            }
          >
            <div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem'
                }}
              >
                <Controller
                  name="profile_image"
                  control={control}
                  render={({ field }) => (
                    <CustomImageInput
                      file={field.value}
                      handleChange={(e: any) => {
                        blobToBase64(e)
                          .then((value) => {
                            field.onChange(value); // Update the field value with the base64 string
                          })
                          .catch((err) => {
                            console.log(err);
                            setLocalError(err);
                          });
                      }}
                      fieldName={field.name}
                      classesForStyles="pb-5"
                      fallback={fallback}
                      id={field.name}
                    />
                  )}
                />
              </Box>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={inputStyle}
                    {...field}
                    label="Arabic First Name"
                    placeholder="Arabic First Name"
                    required
                  />
                )}
              />
              <Controller
                name="first_name_en"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={inputStyle}
                    {...field}
                    label="English First Name"
                    placeholder="English First Name"
                    required
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Arabic Last Name"
                    placeholder="Arabic Last Name"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Controller
                name="last_name_en"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="English Last Name"
                    placeholder="English Last Name"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    placeholder="Email"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={inputStyle}
                  />
                )}
              />

              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MuiPhone
                    onChange={setValue}
                    value={watch('phone_number')}
                    controle_name="phone_number"
                    setError={setError}
                    label={'Phone Number'}
                    sx={inputStyle}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message}
                    clearError={() => clearErrors('phone_number')}
                    setIsPhoneValid={setIsPhoneValid}
                  />
                )}
              />
              {id === 0 && (
                <>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Confirm Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must be at least 8 characters long'
                      },
                      validate: (value) =>
                        value == confirmPassword ||
                        'Password and Confirmation do not match'
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        sx={inputStyle}
                        error={
                          errors.password?.type == 'minLength' ? true : false
                        }
                        helperText={
                          errors.password?.type == 'minLength'
                            ? errors.password?.message
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    id="passwordOne"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    sx={inputStyle}
                    error={
                      confirmPassword !== watch('password') &&
                      confirmPassword != ''
                    }
                    helperText={
                      confirmPassword !== watch('password') &&
                      confirmPassword !=
                        'Password and Confirmation do not match'
                    }
                  />
                </>
              )}
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Address"
                    placeholder="Address"
                    required
                    sx={{
                      width: { xs: '100%', sm: '97%' },
                      marginBottom: '30px',
                      marginLeft: '16px'
                    }}
                  />
                )}
              />
              <Controller
                name="birth_date"
                defaultValue={`${new Date()}`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="birthDate"
                    type="date"
                    placeholder="birthDate"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                defaultValue="male"
                render={({ field }) => (
                  <FormControl sx={inputStyle}>
                    <InputLabel htmlFor="gender" id="gender-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="gender-select-label"
                      label="Gender"
                      {...field}
                      MenuProps={{ disableScrollLock: true }}
                      inputProps={{
                        id: 'gender'
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              <Box sx={{ marginLeft: '16px' }}>
                {id === 0 ? (
                  <LoadingButton
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={addStudent.isLoading}
                  >
                    Add student
                  </LoadingButton>
                ) : (
                  <Stack gap={1}>
                    <LoadingButton
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={updateStudent.isLoading}
                    >
                      Update student
                    </LoadingButton>
                    <Button onClick={() => handleOpenResetPassword()}>
                      Reset Password
                    </Button>
                  </Stack>
                )}
              </Box>
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default AddStudentForm;

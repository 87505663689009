import { fetchApi } from 'src/api/api';
import { BlogResponse } from 'src/models/blog';
import { ConsultationModel } from 'src/models/consultation';
import { InstructorCourseResponse } from 'src/models/instructor';
import { GlobalTestResponse, TestResponse } from 'src/models/test';

export const fetchInstructors = async (search?: string): Promise<any[]> => {
  const response = await fetchApi(
    search
      ? `/auth/searchInstructor?filter[search]=${search}`
      : `/auth/instructor`,
    'GET',
    {}
  );
  return response.data.data as any[];
};

export const fetchTopInstructors = async (): Promise<any[]> => {
  const response = await fetchApi(`/auth/topInstructors`, 'GET', {});
  return response.data.data as any[];
};

export const fetchUser = async (id): Promise<any> => {
  const response = await fetchApi(`/auth/user/${id}`);
  return response.data.data as any;
};

export const postInstructor = async (data: any): Promise<any> => {
  const response = await fetchApi(`/auth/user`, 'POST', data);
  return response.data as any;
};

export const updateInstuctor = async (id: any, data: any): Promise<any> => {
  const response = await fetchApi(`/auth/user/${id}`, 'PUT', data);
  return response.data as any;
};

export const updateTopInstructors = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/auth/instructor/updateTop/instructors`,
    'POST',
    data
  );
  return response.data;
};

export const fetchInstructorCourses = async (
  id: number
): Promise<InstructorCourseResponse[]> => {
  const response = await fetchApi(`/auth/instructorCourse/${id}`);
  return response.data.data as InstructorCourseResponse[];
};

export const fetchInstructorTests = async (
  id: number
): Promise<GlobalTestResponse[]> => {
  const response = await fetchApi(`/auth/instructorGlobalTest/${id}`);
  return response.data.data as GlobalTestResponse[];
};

export const fetchInstructorBlogs = async (
  id: number
): Promise<BlogResponse[]> => {
  const response = await fetchApi(`/auth/instructorBlog/${id}`);
  return response.data.data as BlogResponse[];
};

export const fetchInstructorConsultations = async (
  id: number,
  isAnswered: boolean
): Promise<ConsultationModel[]> => {
  const response = await fetchApi(
    `/consultation/consultation/showInstructorConsultation/${id}?is_answered=${
      isAnswered ? '1' : '0'
    }`
  );
  return response.data.data as ConsultationModel[];
};

import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/components/PageHeader';
import React, { useState } from 'react';
import useDebounce from 'src/hooks/useDebounce';
import GroupsTable from './groups-table';
import AddGroupModal from './create-group/add-group-modal';
import useGroups from 'src/hooks/query/CRM/groups/use-groups';
import SendMessageModal from '../customers/send-email-modal';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import SendWhatsappModal from '../customers/send-whatsapp-modal';
import GroupDeleteModal from './group-delete-modal';
import SendSmsModal from '../customers/send-sms-modal';

const Customers = () => {
  const [searchValue, setSearchValue] = React.useState('');
  const search = useDebounce(searchValue);

  const { data, isLoading, isFetching } = useGroups(search);

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [WahtsappOpen, setWhatsappOpen] = React.useState(false);
  const [smsOpen, setSmsOpen] = React.useState(false);
  const [id, setId] = useState();
  const handleClick = () => {
    setOpen(true);
  };
  const [selected, setSelected] = React.useState([]);

  //select menu for send button state and methods
  const [menuItems, setMenuItems] = useState([
    {
      label: 'Email',
      icon: <MdOutlineAlternateEmail />,
      action: () => setEmailOpen(true)
    },
    {
      label: 'Whatsapp',
      icon: <FaWhatsapp />,
      // action: () => setWhatsappOpen(true)
      action: () => {}
    },
    {
      label: 'SMS',
      icon: <TfiEmail />,
      // action: () => setEmailOpen(true)
      action: () => {setSmsOpen(true)}
    }
  ]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Helmet>
        <title>Groups</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          title="Groups"
          menuItems={menuItems}
          handleClick={handleClickSelect}
          handleClose={handleCloseSelect}
          anchorEl={anchorEl}
          contained="Create Group"
          onClick={handleClick}
          selected={
            selected.length > 0
              ? [
                  `Send a Message ${
                    selected.length > 0 ? ` (${selected.length})` : ''
                  }`
                ]
              : ''
          }
          onClickSelected={[() => {}]}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <GroupsTable
          setId={setId}
          setOpen={setOpen}
          isLoading={isLoading}
          data={data}
          isFetching={isFetching}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selected={selected}
          setSelected={setSelected}
          setDeleteOpen={setDeleteOpen}
        />
      </Container>
      <AddGroupModal setId={setId} id={id} open={open} setOpen={setOpen} />
      <GroupDeleteModal
        groupId={id}
        modalOpen={deleteOpen}
        handleModalClose={() => setDeleteOpen(false)}
      />
      <SendMessageModal
        open={emailOpen}
        setOpen={setEmailOpen}
        selectedCustomers={selected}
      />
      <SendWhatsappModal
        open={WahtsappOpen}
        setOpen={setWhatsappOpen}
        selectedCustomers={selected}
      />
      <SendSmsModal
        open={smsOpen}
        setOpen={setSmsOpen}
        selectedCustomers={selected}
      />
    </>
  );
};

export default Customers;

import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import { useNotifications } from 'src/hooks/query/notification/useNotifications';
import NotificationCard from './NotificationCard';
import {
  Box,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Popover,
  Skeleton,
  Stack,
  Switch
} from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import NotFound from '../../../../assets/images/No data-amico.svg';

import { useInView } from 'react-intersection-observer';

let isInit = true;

const NotificationPopup = ({
  open,
  anchorEl,
  setOpen
}: {
  open: boolean;
  anchorEl: any;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [unRead, setUnRead] = useState(false);
  const { data, isLoading, isFetching } = useNotifications(page, 8, unRead);

  const hasMore = page !== data?.meta?.lastPage;

  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView && hasMore && !isInit) {
      setPage((prev) => prev + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (data && hasMore) {
      isInit = false;
      setNotifications((prev) => [...prev, ...data.data.data]);
    }
  }, [data]);

  const SkeletonCard = () => (
    <ListItem sx={{ width: '100%' }}>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between">
            <Skeleton width={150} variant="text" />
            <Skeleton width={80} variant="text" />
          </Stack>
        }
        secondary={
          <>
            <Skeleton variant="text" />
            <Skeleton width={100} variant="text" />
          </>
        }
      />
    </ListItem>
  );

  return (
    <Popover
      sx={{ zIndex: 1200, borderRadius: '15px' }}
      open={open}
      anchorEl={anchorEl.current}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Paper sx={{ minWidth: '400px' }}>
        <List
          sx={{
            width: '100%',
            maxWidth: '400px',
            bgcolor: 'background.paper',
            maxHeight: '500px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            borderRadius: '15px'
          }}
          component="nav"
        >
          <ListItem sx={{ width: '100%' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h4">Notifications</Typography>
              <FormControlLabel
                control={<Switch />}
                label="Unread"
                labelPlacement="start"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  isInit = true;
                  setUnRead(e.target.checked);
                  setPage(1);
                  setNotifications([]);
                }}
                value={unRead}
              />
            </Stack>
          </ListItem>
          <Divider sx={{ marginBottom: '1rem', backgroundColor: '#aaa' }} />
          {notifications.length > 0 &&
            notifications.map((item) => (
              <NotificationCard item={item} setOpen={setOpen} key={item.id} />
            ))}
          <Box ref={ref}>
            {(isLoading || isFetching) && (
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
            )}
          </Box>
          {data && data.meta.total === 0 && (
            <Box p={4}>
              <img
                src={NotFound}
                style={{ objectFit: 'contain', width: '100%' }}
              />
              <Typography mt={3} textAlign="center" variant="h4">
                No Notifications!
              </Typography>
            </Box>
          )}
        </List>
      </Paper>
    </Popover>
  );
};

export default NotificationPopup;

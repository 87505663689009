import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { courseSection, courseSectionUpdate } from 'src/models/course';
import { useMutation, useQueryClient } from 'react-query';
import { fetchApi } from 'src/api/api';

const inputStyle = {
  width: { xs: '100%', sm: '45%' },
  marginBottom: '30px'
};

type Props = {
  modalInfo?: courseSection;
  modalOpen: boolean;
  isValid: boolean;
  control: any;
  handleModalClose: Function;
  handleSubmit: any;
  sectionMethod: 'create' | 'update';
  order: number;
};

function SectionModal({
  modalInfo,
  modalOpen,
  control,
  handleModalClose,
  handleSubmit,
  isValid,
  sectionMethod,
  order
}: Props) {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    (data: any) => fetchApi(`/course/section/${modalInfo?.id}`, 'PUT', data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const { isLoading: loadingCreate, mutateAsync: createSection } = useMutation(
    (data: any) => fetchApi(`/course/section`, 'POST', data),
    {
      onError: (error: any) => {
        toast.error(
          i18next.language === 'en'
            ? 'Something went wrong, please try again later.'
            : 'حدث خطأ ما، يرجى المحاولة مرة أخرى لاحقًا.'
        );
      }
    }
  );

  const onSubmit: SubmitHandler<courseSectionUpdate> = async (data) => {
    if (data.description_en == '') delete data.description_en;
    if (data.name_en == '') delete data.name_en;
    try {
      if (sectionMethod == 'update') {
        mutateAsync({
          ...data,
          course_id: modalInfo?.course_id,
          order: modalInfo?.order
        }).then((response) => {
          if (response.status === 200 || response.status === 202) {
            toast.success(
              i18next.language === 'en'
                ? 'Section Edited successfully'
                : 'تم تعديل القسم بنجاح'
            );
            handleModalClose();
          }
          queryClient.invalidateQueries(['get-sections', modalInfo?.course_id]);
        });
      } else {
        createSection({
          ...data,
          course_id: modalInfo?.course_id,
          order: order
        }).then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success(
              i18next.language === 'en'
                ? 'Section Created successfully'
                : 'تم إنشاء القسم بنجاح'
            );
            handleModalClose();
          }
          queryClient.invalidateQueries(['get-sections', modalInfo?.course_id]);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleModalClose()}
      sx={{
        width: '60vw',
        height: '50vh',
        position: 'absolute',
        top: '20vh',
        left: '20vw'
      }}
      disableScrollLock={true}
    >
      <Stack
        sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: 'auto',
            width: '90%',
            p: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!isLoading && !loadingCreate && (
            <>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap="2%"
                component="form"
                justifyContent="flex-start"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ width: '100%' }}
              >
                <Typography
                  variant="h6"
                  sx={{ width: '100%', paddingBottom: '20px' }}
                >
                  {sectionMethod == 'update'
                    ? `Edit Section #${modalInfo?.order}`
                    : 'Create New Section'}
                </Typography>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField
                      sx={inputStyle}
                      label="Name in Arabic"
                      size="small"
                      error={invalid}
                      fullWidth
                      {...field}
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="name_en"
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField
                      sx={inputStyle}
                      label="Name in English"
                      size="small"
                      error={invalid}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField
                      sx={inputStyle}
                      label="Description in Arabic"
                      size="small"
                      error={invalid}
                      multiline
                      rows={1}
                      {...field}
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="description_en"
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField
                      sx={inputStyle}
                      label="Description in English"
                      size="small"
                      error={invalid}
                      multiline
                      rows={1}
                      {...field}
                    />
                  )}
                />
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={!isValid}
                    sx={{ padding: '10px' }}
                  >
                    <Typography fontSize="1rem" color="#fff">
                      {sectionMethod == 'update'
                        ? 'Update Section'
                        : 'Create Section'}
                    </Typography>
                  </Button>
                </div>
              </Stack>
            </>
          )}
          {(isLoading || loadingCreate) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Stack>
    </Modal>
  );
}

export default SectionModal;

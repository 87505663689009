import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import GroupsTable from '../groups/groups-table';
import useDebounce from 'src/hooks/useDebounce';
import useGroups from 'src/hooks/query/CRM/groups/use-groups';
import useAddToGroup from 'src/hooks/query/CRM/customers/use-add-to-group';
import { toast } from 'react-toastify';
import { addMembersToGroupsRequest } from 'src/models/CRM';
import { LoadingButton } from '@mui/lab';

interface Props {
  open: boolean;
  setOpen: Function;
  selectedCustomers: { id: number; type: string }[];
}

export default function AddToGroupModal({
  open,
  setOpen,
  selectedCustomers
}: Props) {
  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const [searchValue, setSearchValue] = React.useState('');
  const search = useDebounce(searchValue);

  const { data, isLoading, isFetching } = useGroups(search);
  const addToGroup = useAddToGroup();
  const [selected, setSelected] = React.useState([]);

  const Submit = (data: addMembersToGroupsRequest) => {
    addToGroup
      .mutateAsync(data, {
        onSuccess: () => {
          toast.success('Added To Groups Successfully');
          setOpen(false);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <GroupsTable
            isLoading={isLoading}
            data={data}
            isFetching={isFetching}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selected={selected}
            setSelected={setSelected}
            isComponent={true}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <LoadingButton
            loadingPosition="start"
            variant="contained"
            fullWidth
            onClick={() =>
              Submit({
                group: selected.map((group) => group.id),
                memberable: selectedCustomers
              })
            }
            loading={addToGroup.isLoading}
            disabled={selected.length == 0}
          >
            Add To Groups
          </LoadingButton>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

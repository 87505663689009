import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/components/PageHeader';
import React, { useState } from 'react';
import AlbumsTable from './albums-table';
import AddAlbumModal from './create-album/add-album-modal';
import useListAlbums from 'src/hooks/query/gallery/useListAlbums';
import AlbumImagesModal from './album-images-modal';
import AlbumDeleteModal from './create-album/album-delete-modal';

const Customers = () => {
  const { data, isLoading, isFetching } = useListAlbums();

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [imagesOpen, setImagesOpen] = React.useState(false);
  const [id, setId] = useState(0);
  const handleClick = () => {
    setOpen(true);
  };
  const [selected, setSelected] = React.useState([]);

  //select menu for send button state and methods
  // const [menuItems, setMenuItems] = useState([
  //   {
  //     label: 'Email',
  //     icon: <MdOutlineAlternateEmail />,
  //     action: () => setEmailOpen(true)
  //   },
  //   {
  //     label: 'Whatsapp',
  //     icon: <FaWhatsapp />,
  //     action: () => setWhatsappOpen(true)
  //   },
  //   {
  //     label: 'SMS',
  //     icon: <TfiEmail />,
  //     action: () => setEmailOpen(true)
  //   }
  // ]);

  const [anchorEl, setAnchorEl] = useState(null);

  // const handleClickSelect = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleCloseSelect = () => {
  //   setAnchorEl(null);
  // };
  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          title="Gallery"
          contained="Create New Album"
          onClick={() => {
            handleClick();
            setId(0);
          }}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <AlbumsTable
          setId={setId}
          setOpen={setOpen}
          isLoading={isLoading}
          data={data}
          isFetching={isFetching}
          selected={selected}
          setSelected={setSelected}
          setDeleteOpen={setDeleteOpen}
          setImagesOpen={setImagesOpen}
        />
        {/* <PhotoAlbumContent /> */}
      </Container>
      <AddAlbumModal setId={setId} id={id} open={open} setOpen={setOpen} />
      <AlbumDeleteModal
        albumId={id}
        modalOpen={deleteOpen}
        handleModalClose={() => setDeleteOpen(false)}
      />
      <AlbumImagesModal
        open={imagesOpen}
        setOpen={setImagesOpen}
        id={id}
        setId={setId}
      />
    </>
  );
};

export default Customers;

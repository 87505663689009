import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from 'react-query';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AddGroup from 'src/hooks/query/CRM/groups/add-group';
import UpdateGroup from 'src/hooks/query/CRM/groups/update-group';
import useGroup from 'src/hooks/query/CRM/groups/use-group';
import { groupRequest } from 'src/models/CRM';
import AddCustomers from './add-customers';
import useCustomers from 'src/hooks/query/CRM/customers/use-customers';
import { fetchStudents } from 'src/services/students';
import AddUsers from './add-users';

const inputStyle = {
  width: { xs: '100%', sm: '48%' },
  marginBottom: '30px',
  marginLeft: { sm: '16px' }
};
const AddGroupForm = ({ setOpen, id }) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    formState: { errors }
  } = useForm<groupRequest>({
    defaultValues: {}
  });

  const queryClient = useQueryClient();

  const addGroup = AddGroup();

  const update = UpdateGroup();

  const { data: groupData, isLoading, isFetching } = useGroup(id);
  const {
    data: customers,
    isLoading: customersIsLoading,
    isFetching: customersIsFetching
  } = useCustomers('');

  const {
    data: students,
    isLoading: loadingStudents,
    isError,
    isFetching: fetchingStudents
  } = useQuery(['students'], () => fetchStudents());

  const onSubmit = (data: groupRequest) => {
    if (data?.memberable?.length == 0) delete data.memberable;
    addGroup
      .mutateAsync({
        ...data,
        memberable: [...data.customers, ...data.users]
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201 || res.status === 202) {
          toast.success('Group Added Successfuly');
          queryClient.invalidateQueries('groups');
          setOpen(false);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status == 422 &&
          error?.response?.data?.errors?.name
        ) {
          setError('name', {
            type: 'manual',
            message: 'name already exist'
          });
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  const onUpdate = (data: groupRequest) => {
    if (data?.memberable?.length == 0) delete data.memberable;
    console.log(data);
    update
      .mutateAsync({
        id,
        data: {
          ...data,
          memberable: [...data.customers, ...data.users]
        }
      })
      .then((res) => {
        if (res.status === 201 || res.status === 202) {
          toast.success('Group Updated Successfuly');
          queryClient.invalidateQueries('groups');
          setOpen(false);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status == 422 &&
          error?.response?.data?.errors?.name
        ) {
          setError('name', {
            type: 'manual',
            message: 'name already exist'
          });
        } else {
          toast.error('Something went wrong, please try again later.');
        }
      });
  };

  useEffect(() => {
    if (groupData && id !== 0) {
      reset({
        ...groupData,
        customers: groupData.customers.map((customer) => ({
          id: customer.id,
          type: 'customer'
        })),
        users: groupData.users.map((user) => ({
          id: user.id,
          type: 'user'
        }))
      });
    }
  }, [groupData, reset, id]);

  return (
    <Box>
      {isLoading || isFetching || customersIsLoading || loadingStudents ? (
        <SuspenseLoader />
      ) : (
        <>
          <form
            onSubmit={
              id === 0 ? handleSubmit(onSubmit) : handleSubmit(onUpdate)
            }
          >
            <div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem'
                }}
              ></Box>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={inputStyle}
                    {...field}
                    label="Group Name"
                    placeholder="Group Name"
                    required
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Group Description"
                    placeholder="Group Description"
                    required
                    sx={inputStyle}
                  />
                )}
              />
              <Box
                sx={{
                  width: '97%',
                  marginBottom: '30px',
                  marginLeft: { sm: '16px' }
                }}
              >
                <AddCustomers
                  customers={customers}
                  control={control}
                  errors={errors}
                />
              </Box>

              <Box
                sx={{
                  width: '97%',
                  marginBottom: '30px',
                  marginLeft: { sm: '16px' }
                }}
              >
                <AddUsers users={students} control={control} errors={errors} />
              </Box>

              <Box sx={{ marginLeft: '16px' }}>
                {id === 0 ? (
                  <LoadingButton
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={addGroup.isLoading}
                  >
                    Add Group
                  </LoadingButton>
                ) : (
                  <Stack gap={1}>
                    <LoadingButton
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={update.isLoading}
                    >
                      Update Group
                    </LoadingButton>
                  </Stack>
                )}
              </Box>
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default AddGroupForm;

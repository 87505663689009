import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  AddCourseTest,
  courseLesson,
  courseLessonUpdate,
  courseSection
} from 'src/models/course';
import LessonModal from './lesson-modal';
import LessonItem from './lesson-item';
import LessonDeleteModal from './lesson-delete-modal';
import useAddTestMutation from 'src/hooks/query/Tests/useAddTestMutation';
import AddCourseTestModal from './add-course-test-modal';
import { RiDeleteBinLine } from 'react-icons/ri';

const Container = {
  borderRadius: '2.5px',
  width: '80%',
  height: '300px',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  border: '1px solid gray'
};

const Title = {
  padding: '8px',
  textAlign: 'center',
  position: 'stick'
};

const TaskList = {
  padding: '3px',
  transistion: 'background-color 0.2s ease',
  flexGrow: 1,
  minHeight: '100px',
  height: '100%'
};

interface Props {
  title: string;
  tasks: courseLesson[];
  id: string;
  index: number;
  section_id: number;
  section: courseSection;
  order: number;
  sectionModalOpen: boolean;
  handleSectionModalOpen: Function;
  handleSectionModalClose: Function;
  setSectionMethod: Function;
  setSectionModalInfo: Function;
  sectionReset: any;
  handleSectionDeleteModalOpen: Function;
}

const SectionItem: React.FC<Props> = ({
  title,
  tasks,
  id,
  index,
  section_id,
  section,
  handleSectionModalOpen,
  setSectionMethod,
  setSectionModalInfo,
  sectionReset,
  handleSectionDeleteModalOpen
}) => {
  const [expande, setExpande] = useState(false);
  const [progress, setProgress] = useState([{}]);

  const {
    control: testControl,
    getValues: testGetValues,
    setValue: testSetValue,
    watch: testWatch,
    handleSubmit: testHandleSubmit,
    formState: { errors },
    reset: testReset
  } = useForm<AddCourseTest>({
    mode: 'onChange'
  });

  //lesson modal states
  const [fileState, setFileState] = useState(false);
  const [lessonModalInfo, setLessonModalInfo] = useState<courseLesson>();
  const [lessonModalOpen, setlessonModalOpen] = useState(false);
  const [lessonMethod, setLessonMethod] = useState<'create' | 'update'>(
    'update'
  );
  const handlelessonModalOpen = () => setlessonModalOpen(true);
  const handleLessonModalClose = () => setlessonModalOpen(false);

  //lesson  Delete modal states
  const [lessonDeleteModalOpen, setlessonDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setlessonDeleteModalOpen(true);
  const handleDeleteModalClose = () => setlessonDeleteModalOpen(false);

  //Add Course Test modal states
  const [addTestModalOpen, setAddTestModalOpen] = useState(false);
  const handleaddTestModalOpen = () => setAddTestModalOpen(true);
  const handleaddTestModalClose = () => {
    setAddTestModalOpen(false);
    testReset({});
  };
  const [testModalId, setTestModalId] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset
  } = useForm<courseLessonUpdate>({
    mode: 'onChange'
  });

  const { addTest, isLoading } = useAddTestMutation({
    testWatch,
    testReset,
    handleaddTestModalClose,
    courseId: section.course_id
  });

  return (
    <>
      <LessonModal
        fileState={fileState}
        modalInfo={lessonModalInfo!}
        modalOpen={lessonModalOpen}
        control={control}
        handleModalClose={handleLessonModalClose}
        handleSubmit={handleSubmit}
        isValid={isValid}
        lessonMethod={lessonMethod}
        section_id={section_id}
        order={tasks.length + 1}
        setProgress={setProgress}
        course_id={section.course_id}
        handleaddTestModalOpen={handleaddTestModalOpen}
        setTestModalId={setTestModalId}
      />

      <LessonDeleteModal
        lessonId={lessonModalInfo?.id}
        handleModalClose={handleDeleteModalClose}
        modalOpen={lessonDeleteModalOpen}
        lessonOrder={lessonModalInfo?.order}
        courseId={section.course_id}
      />

      <AddCourseTestModal
        control={testControl}
        open={addTestModalOpen}
        onClose={handleaddTestModalClose}
        getValues={testGetValues}
        setValue={testSetValue}
        watch={testWatch}
        errors={errors}
        addTest={addTest}
        lesson_id={lessonModalInfo?.id!}
        isLoading={isLoading}
        testHandleSubmit={testHandleSubmit}
        testModalId={testModalId}
        testReset={testReset}
      />

      <Draggable draggableId={id} index={index} key={id}>
        {(provided) => (
          <Accordion
            expanded={expande}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <AccordionSummary
              onClick={() => setExpande(!expande)}
              {...provided.dragHandleProps}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography sx={Title} variant="h6">
                  {`#${index + 1}  ${title}`}
                </Typography>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSectionMethod('update');
                      setSectionModalInfo(section);
                      sectionReset({
                        name: section.translations.ar.name || '',
                        name_en: section.translations.en?.name || '',
                        description: section.translations.ar.description || '',
                        description_en:
                          section.translations.en?.description || ''
                      });
                      handleSectionModalOpen();
                    }}
                  >
                    <Typography fontSize="0.8rem" color="#fff">
                      Edit Section
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      setFileState(false);
                      e.stopPropagation();
                      setLessonMethod('create');
                      reset({
                        name: '',
                        name_en: '',
                        description: '',
                        description_en: ''
                      });
                      handlelessonModalOpen();
                    }}
                  >
                    <Typography fontSize="0.8rem" color="#fff">
                      Add New Lesson
                    </Typography>
                  </Button>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSectionModalInfo(section);
                      handleSectionDeleteModalOpen();
                    }}
                    color="error"
                  >
                    <RiDeleteBinLine />
                  </IconButton>
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ display: expande ? 'block' : 'none' }}>
              <Box sx={Container} className="lessons-scrollable-side">
                <Droppable droppableId={id} type={`droppableSubItem`}>
                  {(provided) => (
                    <div style={TaskList} ref={provided.innerRef}>
                      {tasks
                        ?.sort((a, b) => a.order - b.order)
                        .map((task, index: number) => (
                          <LessonItem
                            key={index}
                            index={index}
                            task={task}
                            setModalInfo={setLessonModalInfo}
                            handleModalOpen={handlelessonModalOpen}
                            setFileState={setFileState}
                            reset={reset}
                            setLessonMethod={setLessonMethod}
                            progress={progress}
                            handleDeleteModalOpen={handleDeleteModalOpen}
                            handleaddTestModalOpen={handleaddTestModalOpen}
                            setTestModalId={setTestModalId}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Draggable>
    </>
  );
};

export default SectionItem;

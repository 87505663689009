import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { userStudent } from 'src/models/user';
import { defaultTranslation } from 'src/utils/helpers/default-translation';

type Props = {
  users: userStudent[];
  control: any;
  errors: any;
};

const AddUsers = (props: Props) => {
  return (
    <Controller
      name="users"
      control={props.control}
      defaultValue={[]}
      render={({ field }) => (
        <>
          <Autocomplete
            {...field}
            multiple
            id="users-filled"
            filterSelectedOptions
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={props.users?.map((user: userStudent) => ({
              id: user.id,
              type: 'user'
            }))}
            getOptionLabel={(option) => {
              const user = props.users?.find((user) => user.id === option.id);
              return user
                ? defaultTranslation(user, 'first_name') +
                    ' ' +
                    defaultTranslation(user, 'last_name')
                : option.id;
            }}
            freeSolo
            renderTags={(value: { id: number; type: 'user' }[], getuserProps) =>
              value.map(
                (option: { id: number; type: 'user' }, index: number) => {
                  const user = props.users?.find(
                    (user) => user.id === option.id
                  );
                  return (
                    <Chip
                      variant="outlined"
                      label={
                        user
                          ? defaultTranslation(user, 'first_name') +
                            ' ' +
                            defaultTranslation(user, 'last_name')
                          : option.id
                      }
                      {...getuserProps({ index })}
                    />
                  );
                }
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Students"
                error={props.errors && !!props.errors.user}
                helperText={
                  props.errors &&
                  props.errors.user &&
                  props.errors?.user?.message
                }
              />
            )}
            value={Array.isArray(field.value) ? field.value : []}
            onChange={(event, data) => {
              field.onChange(data); // Update the field value with the user IDs
            }}
          />
        </>
      )}
    />
  );
};

export default AddUsers;

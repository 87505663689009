import axios from 'axios';
import { fetchApi } from 'src/api/api';
import {
  albumEditRequest,
  albumRequest,
  albumResponse
} from 'src/models/gallery';

export const createAlbum = async (data: albumRequest): Promise<any> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('name_en', data.name_en);
  data.users.forEach((user, index) => {
    formData.append(`users[${index}]`, `${user}`);
  });
  data.image.forEach((file, index) => {
    formData.append(`image[${index}]`, file);
  });

  const token = localStorage.getItem('token')?.toString().slice(1, -1);
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const response = await axios.post(`${apiRoot}/gallery/album/`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data as any;
};

export const addAlbumPhotos = async (
  data: { image: File[] },
  id: number
): Promise<any> => {
  const formData = new FormData();
  data?.image?.forEach((file, index) => {
    formData.append(`image[${index}]`, file);
  });

  const token = localStorage.getItem('token')?.toString().slice(1, -1);
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const response = await axios.post(
    `${apiRoot}/gallery/album/assignImage/${id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data as any;
};

export const fetchAlbums = async (): Promise<albumResponse[]> => {
  const response = await fetchApi(`/gallery/album/`, 'GET', {});
  return response.data.data as albumResponse[];
};

export const fetchAlbum = async (id: number): Promise<albumResponse> => {
  const response = await fetchApi(`/gallery/album/${id}`, 'GET', {});
  return response.data.data as albumResponse;
};

export const deleteAlbumPhoto = async (
  albumId: number,
  ImageId: number
): Promise<any> => {
  const response = await fetchApi(
    `/gallery/album/image/${albumId}?image_id=${ImageId}`,
    'DELETE',
    {}
  );
  return response.data;
};

export const updateAlbum = async (
  data: albumEditRequest,
  id: number
): Promise<any> => {
  const response = await fetchApi(`/gallery/album/${id}`, 'PUT', data);
  return response.data;
};

export const deleteAlbum = async (id: number): Promise<any> => {
  const response = await fetchApi(`/gallery/album/${id}`, 'DELETE', {});
  return response.data;
};

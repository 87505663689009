import { fetchApi } from 'src/api/api';
import { BlogResponse } from 'src/models/blog';

export const fetchBlogs = async (search?: string): Promise<BlogResponse[]> => {
  const response = await fetchApi(
    search
      ? `/blog/searchBlog?filter[search]=${search}&sort=-created_at`
      : `/blog/searchBlog?sort=-created_at`,
    'GET',
    {}
  );
  return response.data.data as BlogResponse[];
};

export const fetchBlog = async (id: number): Promise<BlogResponse> => {
  const response = await fetchApi(`/blog/blog/${id}`);
  return response.data.data as BlogResponse;
};

export const postBlog = async (data: any): Promise<any> => {
  const response = await fetchApi(`/blog/blog`, 'POST', data);
  return response.data;
};

export const updateBlog = async (data: any, id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blog/${id}`, 'POST', data);
  return response.data;
};

export const postSections = async (data: any): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections`, 'POST', data);
  return response.data;
};

export const updateSection = async (data: any, id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections/${id}`, 'POST', data);
  return response.data;
};

export const deleteSection = async (id: number): Promise<any> => {
  const response = await fetchApi(`/blog/blogSections/${id}`, 'DELETE');
  return response.data;
};

export const updateSectionsOrder = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/blog/blogSections/updateOrders`,
    'POST',
    data
  );
  return response.data;
};

export const fetchTopBlogs = async (): Promise<BlogResponse[]> => {
  const response = await fetchApi(`/blog/topBlogs`, 'GET', {});
  return response.data.data as BlogResponse[];
};

export const updateTopBlogs = async (data: any): Promise<any> => {
  const response = await fetchApi(`/blog/blog/updateTop/blogs`, 'POST', data);
  return response.data;
};

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { getImageFromServer } from 'src/utils/helpers/get-image-from-server';
import { Close } from '@mui/icons-material';

interface FileUploaderProps {
  control: any;
  name: string;
  setValue: any;
  accept: string;
  label: any;
  value?: string;
  setError?: Function;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  control,
  name,
  setValue,
  accept,
  label,
  value,
  setError
}) => {
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (value) {
      setValue(name, value);
      const fName = value.split('/').pop();
      // Remove the file extension
      const nameWithoutExtension = fName?.split('.').slice(0, -1).join('.');
      // Extract the desired name
      const desiredName = nameWithoutExtension
        ?.split('_')
        .slice(0, -1)
        .join('_');
      setFileName(desiredName);
      setFileUrl(getImageFromServer(value));
    }
  }, [value]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setValue(name, base64);
      setFileName(file.name);
      setFileUrl(URL.createObjectURL(file)); // Set the file URL
      if (setError) setError(name, null);
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById(name);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteFile = () => {
    const fileInput = document.getElementById(name) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Reset the value of the input field
    }
    setValue(name, ''); // Reset the value in the form controller
    setFileName(undefined); // Reset the file name state
    setFileUrl(undefined); // Reset the file URL state
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: 'File is required' }}
      defaultValue=""
      render={({ field }) => (
        <Stack direction="row" gap={1} alignItems="center">
          <input
            id={name}
            type="file"
            accept={accept}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            component="span"
            onClick={handleButtonClick}
          >
            {label}
          </Button>
          {field.value && (
            <Typography variant="body1">
              {fileName && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    {fileName}
                  </a>
                  <IconButton onClick={() => handleDeleteFile()}>
                    <Close />
                  </IconButton>
                </Box>
              )}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default FileUploader;

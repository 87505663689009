import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddGroupForm from './add-group-form';
import { vrcGroupResponse } from 'src/models/vrc';
import EditGroupForm from './edit-group-form';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  update?: boolean;
  activeConversation?: vrcGroupResponse;
}

export default function AddGroupModal({
  open,
  setOpen,
  update,
  activeConversation
}: Props) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={update ? 'sm' : 'md'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {!update ? 'Create New Group' : 'Edit Group Information'}
        </DialogTitle>
        <DialogContent>
          {!update ? (
            <AddGroupForm setOpen={setOpen} />
          ) : (
            <EditGroupForm
              setOpen={setOpen}
              activeConversation={activeConversation}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

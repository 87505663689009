import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddStudentForm from './add-student-form';

export default function AddStudentModal({ open, setOpen, id, setId }) {
  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {id == 0 ? 'Add New Student' : 'Edit Student Information'}
        </DialogTitle>
        <DialogContent>
          <AddStudentForm id={id ? id : 0} setOpen={setOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

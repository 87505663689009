import React, { useRef, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  icon: any;
  type: string;
  lessonId: number;
  addResource: Function;
  handleModalClose: Function;
  updateResource: Function;
  lessonMethod: 'update' | 'create';
  handleaddTestModalOpen?: Function;
  setTestModalId?: Function;
}

const CustomAddLessonFile: React.FC<Props> = ({
  icon,
  type,
  lessonId,
  addResource,
  handleModalClose,
  updateResource,
  lessonMethod,
  handleaddTestModalOpen,
  setTestModalId
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState('');
  const { t } = useTranslation();

  const handleSquareClick = () => {
    if (fileInputRef.current && type != 'test') {
      fileInputRef.current.click();
    } else {
      handleModalClose();
      if (setTestModalId) setTestModalId(0);
      if (handleaddTestModalOpen) handleaddTestModalOpen();
    }
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setFile(base64);
      if (lessonMethod == 'create') {
        if (type != 'test') {
          addResource({
            file_url: type != 'video' ? base64 : file,
            info: type,
            lesson_id: lessonId
          });
        }
      } else {
        updateResource({
          file_url: type != 'video' ? base64 : file,
          info: type,
          lesson_id: lessonId
        });
      }
      handleModalClose();
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div
        style={{
          width: '130px',
          height: '130px',
          border: '1px solid #ddd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          padding: '20px',
          borderRadius: '5px',
          gap: '10px',
          flexDirection: 'column'
        }}
        onClick={handleSquareClick}
      >
        <IconButton edge="start" disabled sx={{ margin: 0, padding: 0 }}>
          {icon}
        </IconButton>
        <Typography color="primary.main" variant="h6">
          {t(`${type}`)}
        </Typography>
      </div>
      <input
        type="file"
        accept={
          type == 'video'
            ? 'video/mp4'
            : type == 'audio'
            ? 'audio/*'
            : type == 'asset'
            ? '.pdf,.doc,.docx'
            : type == 'test'
            ? 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : ''
        }
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
    </>
  );
};

export default CustomAddLessonFile;

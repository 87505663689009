import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { whatsappMessageRequest } from 'src/models/CRM';
import useSendWhatsapp from 'src/hooks/query/CRM/useSendWhatsapp';

const inputStyle = {
  width: "100%",
  marginBottom: '30px',
};

interface Props {
  setOpen: Function;
  selectedCustomers: { id: number; type: string }[];
}
const SendWhatsappForm = ({ setOpen, selectedCustomers }: Props) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    formState: { errors }
  } = useForm<whatsappMessageRequest>({
    defaultValues: {}
  });

  const sendWhatsapp = useSendWhatsapp();

  const onSubmit = (data) => {
    console.log(errors);

    sendWhatsapp
      .mutateAsync(
        { ...data, data: selectedCustomers },
        {
          onSuccess: () => {
            toast.success('Whatsapp Message Sent Successfully');
            setOpen(false);
          }
        }
      )
      .catch((error) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  return (
    <Box>
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1.5rem'
              }}
            ></Box>

            <Controller
              name="content"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Message Content"
                  placeholder="Message Content"
                  required
                  sx={inputStyle}
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                  multiline
                  rows={1}
                />
              )}
            />

            <Box>
              <LoadingButton
                loadingPosition="start"
                variant="contained"
                fullWidth
                type="submit"
                loading={sendWhatsapp.isLoading}
              >
                Send Message
              </LoadingButton>
            </Box>
          </div>
        </form>
      </>
    </Box>
  );
};

export default SendWhatsappForm;

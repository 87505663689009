import { fetchApi } from 'src/api/api';
import {
  shopItemResponse,
  shopItemTypeResponse,
  studentShopItem
} from 'src/models/shop-item';

export const fetchShopItems = async (
  search?: string
): Promise<shopItemResponse[]> => {
  const response = await fetchApi(
    search
      ? `/shopItem/searchShopItem?filter[search]=${search}&sort=-created_at`
      : `/shopItem/searchShopItem?sort=-created_at`,

    'GET',
    {}
  );
  return response.data.data as shopItemResponse[];
};

export const fetchShopItem = async (id): Promise<shopItemResponse> => {
  const response = await fetchApi(
    `/shopItem/shopItem/admin/ShopItem/${id}`,
    'GET',
    {}
  );
  return response.data.data as shopItemResponse;
};

export const fetchStudentShopItems = async (id): Promise<studentShopItem[]> => {
  const response = await fetchApi(
    `/shopItem/shopItemUser?user_id=${id}`,
    'GET',
    {}
  );
  return response.data.data as studentShopItem[];
};

export const fetchTopShopItems = async (): Promise<shopItemResponse[]> => {
  const response = await fetchApi(`/shopItem/topShopItems`, 'GET', {});
  return response.data.data as shopItemResponse[];
};

export const fetchShopItemTypes = async (): Promise<shopItemTypeResponse[]> => {
  const response = await fetchApi(`/shopItem/shopItemType`, 'GET', {});
  return response.data.data as shopItemTypeResponse[];
};
export const fetchShopItemType = async (
  id: number
): Promise<shopItemTypeResponse> => {
  const response = await fetchApi(`/shopItem/shopItemType/${id}`, 'GET', {});
  return response.data.data as shopItemTypeResponse;
};

export const postShopItemType = async (data: any): Promise<any> => {
  const response = await fetchApi(`/shopItem/shopItemType`, 'POST', data);
  return response.data;
};

export const updateShopItemType = async (
  data: any,
  id: number
): Promise<any> => {
  const response = await fetchApi(`/shopItem/shopItemType/${id}`, 'PUT', data);
  return response.data;
};

export const updateTopShopItems = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/shopItem/shopItem/updateTop/shopItems`,
    'POST',
    data
  );
  return response.data;
};

export const postShopItem = async (data: any): Promise<any> => {
  const response = await fetchApi(`/shopItem/shopItem`, 'POST', data);
  return response.data;
};

export const updateShopItem = async (data: any, id: number): Promise<any> => {
  const response = await fetchApi(`/shopItem/shopItem/${id}`, 'POST', data);
  return response.data;
};

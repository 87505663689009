import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import FilepondUploader from 'src/components/filepond-uploader';
import { LoadingButton } from '@mui/lab';

export default function AddVrcFileModal({
  open,
  onClose,
  control,
  type,
  sendFunction,
  handleSubmit,
  isLoading
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(sendFunction)}>
          <DialogTitle>{'Add Files'}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                width: '97%',
                marginBottom: '30px',
                marginLeft: { sm: '16px' }
              }}
            >
              <FilepondUploader
                control={control}
                name="file"
                type={type}
                required={true}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} type="submit">
              Send
            </LoadingButton>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { assignCertificate } from 'src/services/courses';

export const AssignCertificate = () => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => assignCertificate(data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('item-certificates');
      toast.success(res.data[0]);
    },
    onError: (error: any) => {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).forEach((errorKey) => {
          toast.error(error.response.data.errors[errorKey].join('\n'));
        });
      } else {
        toast.error(error.response.data.message);
      }
    }
  });
};

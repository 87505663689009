import { fetchApi } from 'src/api/api';
import { globalTestResponse, studentGlobalTest } from 'src/models/global-test';
import { testQuestionsResponse } from 'src/models/test';

export const fetchGlobalTests = async (
  search: string | null
): Promise<any[]> => {
  const response = await fetchApi(
    `/test/searchGlobalTest?filter[is_approved]=1&sort=-created_at&filter[search]=${search}&sort=-created_at`,
    'GET',
    {}
  );
  return response.data.data as any[];
};

export const fetchUnapprovedGlobalTests = async (
  search: string | null
): Promise<globalTestResponse[]> => {
  const response = await fetchApi(
    `/test/searchGlobalTest?filter[is_approved]=0&sort=-created_at&filter[search]=${search}&sort=-created_at`,
    'GET',
    {}
  );
  return response.data.data as globalTestResponse[];
};

export const fetchTopGlobalTests = async (): Promise<globalTestResponse[]> => {
  const response = await fetchApi(`/test/topGlobalTests`, 'GET', {});
  return response.data.data as globalTestResponse[];
};

export const updateTopTests = async (data: any): Promise<any> => {
  const response = await fetchApi(
    `/test/globalTest/updateTop/globalTests`,
    'POST',
    data
  );
  return response.data;
};

export const fetchGlobalTest = async (
  id: string
): Promise<globalTestResponse> => {
  const response = await fetchApi(`/test/globalTest/${id}`, 'GET', {});
  return response.data.data as globalTestResponse;
};

export const fetchTestQuestions = async (
  id: string
): Promise<testQuestionsResponse> => {
  const response = await fetchApi(`/test/test/content/${id}`, 'GET', {});
  const questions: testQuestionsResponse = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchGlobalTestQuestions = async (
  id: string
): Promise<testQuestionsResponse> => {
  const response = await fetchApi(
    `/test/globalTest/getTestQuestions/${id}`,
    'GET',
    {}
  );
  const questions: testQuestionsResponse = response.data.data;
  questions.questions.sort((a, b) => a.order - b.order);
  return questions;
};

export const fetchStudentsGlobalTest = async (
  id: string
): Promise<studentGlobalTest[]> => {
  const response = await fetchApi(
    `/test/globalTestUser?user_id=${id}`,
    'GET',
    {}
  );
  return response.data.data as studentGlobalTest[];
};

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SendWhatsappForm from './send-whatsapp-form';

interface Props {
  open: boolean;
  setOpen: Function;
  selectedCustomers: { id: number; type: string }[];
}

export default function SendWhatsappModal({
  open,
  setOpen,
  selectedCustomers
}: Props) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <SendWhatsappForm
            setOpen={setOpen}
            selectedCustomers={selectedCustomers}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const translationAr = {
  // Header Start
  // header main nav
  HeaderNavPages: "الصفحات",
  HeaderNavLearning: "التعليم",
  HeaderNavEvents: "الأحداث",
  HeaderNavBlog: "المدونات",
  HeaderNavShop: "المتجر",
  HeaderNavSearch: "...ابحث",
  HeaderNavQA: "اسألنا",
  //header pages menu
  NavHeaderAboutUs: "عنّا",
  NavHeaderContactUs: "تواصل معنا",
  CreateVrc: "انشاء غرفة صف افتراضية",
  EditVrc: "تعديل غرفة الصف الافتراضية",
  NavHeaderPurchase: "دليل الشراء",
  NavHeaderPrivacy: "سياسة الخصوصية والأحكام",
  NavHeaderTerms: "شروط الخدمة",
  //header courses menu
  NavHeaderVRC: "غرفة الصف االافتراضية",
  NavHeaderCoursesGrid: "الدورات",
  NavHeaderQuizzesGrid: "الاختبارات",
  NavHeaderCasesGrid: "عرض كل الحالات",
  Cases: "الحالات",
  NavHeaderMembership: "مستويات العضوية",
  NavHeaderBecome: "المدربون",
  NavHeaderProfile: "الملف الشخصي",
  NavHeaderCheckout: "الدفع",
  //header events menu
  NavHeaderEvent: "الأحداث",
  NavHeaderZoom: "Zoom اجتماعات",
  NavHeaderEventDetails: "تفاصيل الحدث",
  NavHeaderZoomDetail: "Zoom  تفاصيل اجتماعات",
  //header blog menu
  NavHeaderBlogGrid: "المدونات",
  NavHeaderBlogList: "قائمة المدونات",
  EmptyBlogs: "لا يوجد مدونات",
  //header shop menu
  NavHeaderShop: "المتجر",
  NavHeaderCart: "عرض عربة التسوق",
  NavHeaderWishlist: "قائمة الرغبات",
  NavHeaderMyAccount: "حسابي",
  NavHeaderLoginRegister: "تسجيل الدخول/نشاء حساب",
  // Header End

  // Langind Start
  LandingTitleSmall: " UH Training هو عوامة النجاة",
  LandingTitleMain: "لارشادك لتصبح شخصاً أفضل",
  LandingDescription:
    "يمكن أن تكون علاقة التوجيه الصحيحة أداة قوية للنمو المهني",
  LandingDownload: "حمل الدليل المجاني",
  // Langind End

  // Feature Start
  FeatureSecHeader: "التعليم للجميع",
  FeatureMainHeader: "دروس التدريب عبر الإنترنت للتعلم عن بعد",
  Feature1Title: "إرشاد الحياة",
  Feature1Description:
    "احصل على بعض الراحة النفسية والإرشاد لتوجهاتك المهنية في المستقبل",
  Feature1Link: "ابدأ هنا",
  Feature2Title: "تطوير الذات",
  Feature2Description:
    "تطوير المهارات المهنية في مختلف التخصصات بما في ذلك علوم الكمبيوتر والكفاءة اللغوية",
  Feature2Link: "اكستشف الآن",
  Feature3Title: "اسأل المستشارين",
  Feature3Description:
    "قم بالحجز مع مستشارنا الخاص لمعرفة ما المهنة الملائمة لك",
  Feature3Link: "حكمة مجانية",
  Feature4Title: "التعلم عن بعد",
  Feature4Description:
    "تعلم من أي مكان في العالم على سطح المكتب أو الكمبيوتر اللوحي أو الهاتف المحمول من خلال اتصال بالإنترنت",
  Feature4Link: "ابدأ الآن",
  // Feature End

  // TeacherQuote Start
  TeacherQuoteTitle: "تخلص من الافتراضات القديمة",
  TeacherQuoteQuote:
    "أساعد المتعلمين في إيجاد معتقدات جديدة فعالة والتخلص من المواقف الحياتية التي عفا عليها الزمن والمحبطة والبعيدة عن الواقع. أنت تتطور الى نسخة افضل منك.",
  TeacherQuoteYear: "سنة",
  TeacherQuoteWork: "خبرة عمل",
  TeacherQuoteLink: "احصل على الدليل المجاني",
  // TeacherQuote End

  // Testimonials Start
  TestimonialsSubTitle: "تنمية إمكاناتك وقدراتك",
  TestimonialsTitle:
    "تعرف على <1>أسرار النجاح في الحياة</1> <br/> هؤلاء الأشخاص لديهم المفتاح",
  TestimonialsRegistered: "التسجيلات المحصيّة",
  TestimonialsFinished: "الجلسات المنتهية",
  TestimonialsSatisfaction: "معدّل الرّضى",
  // Testimonials End

  // Video Start
  VideoSubTitle: "دمج كل الحواس في التعلم",
  VideoTitle: "تعلم بكل ما لديك من <1> حواس </ 1>",
  // Video End

  // Course Section Start
  CourseSectionSubTitle: "تعلم على وتيرتك",
  CourseSectionTitle: "دورات التوقيع الحالية",
  CourseSectionOffer:
    "احصل على الاستشارة الأكثر تخصصًا لتغيير حياتك. احصل على شهادة لجهودك وشغفك",
  CourseSectionGuide: "احصل على الدليل المجاني",
  // Course Section End

  // ShopItems Section Start
  ShopItemsSectionSubTitle: "تصفح متجرنا",
  ShopItemsSectionTitle: "منتجاتنا المميزة",
  ShopDetailsRecentItems: "آخر المنتجات",
  GlobalShopItems: "المتجر",
  // ShopItems Section End

  // Category Section Start
  CategorySectionSubTitle: "تصفح أصنافنا",
  CategorySectionTitle: "أصنافنا المميزة",
  // Category Section End

  // Instructors Section Start
  InstructorsSectionSubTitle: "تصفح مدرسينا",
  InstructorsSectionTitle: "مدرسينا المميزين",
  // Instructors Section End

  // Blog Section Start
  BlogSectionSubTitle: "ادرس مع المعلمين المفضلين لديك",
  BlogSectionTitle: "كشف أسرار التعلم في المدونات",
  BlogSectionCard1Title: "التعليم عن بعد",
  BlogSectionCard1Description: "كن مدونًا أفضل: تخطيط المحتوى",
  BlogSectionCard2Title: "استراتيجيات التعليم",
  BlogSectionCard2Description: "خمس استراتيجيات أساسية للتعلم عبر الإنترنت",
  BlogSectionCard3Title: "استراتيجيات التعليم",
  BlogSectionCard3Description: "خمس استراتيجيات أساسية للتعلم عبر الإنترنت",
  BlogSectionOffer: "هل تريد الحصول على التفاصيل الآن؟",
  BlogSectionGuide: "مشاهدة جميع المنشورات",
  BlogSectionViews: "{{count}} مشاهدة",
  BlogRecentBlogs: "أحدث المقالات",

  // Blog Section End

  // Success Section Start
  SuccessSectionSubTitle: "أربع خطوات رئيسية للنجاح",
  SuccessSectionTitle: "أفضل الفرص لتوسيع <br /> نجاحك إلى أقصى الحدود",
  SuccessSectionCard1Title: "الاتساع",
  SuccessSectionCard1Head: "#1 التقدم الوظيفي والهوايات",
  SuccessSectionCard1Description:
    "تمنحك الدراسة عبر الإنترنت مزيدًا من المرونة. يمكنك العمل وتنظيم جدول عملك مع واجباتك الدراسية بسهولة أكبر ؛ والأكثر من ذلك ، إذا كنت تحضر دورة متزامنة: دورة عبر الإنترنت حيث يمكنك الدراسة والتفاعل مع مدرسك وزملائك في الفصل حسب وتيرتك الخاصة",
  SuccessSectionCard2Title: "الجدولة",
  SuccessSectionCard2Head: "#2 جدول مرن وبيئة مرنة",
  SuccessSectionCard2Description:
    " أخذ دورة تدريبية عبر الإنترنت يعني أيضًا أنك لست مضطرًا للذهاب إلى الفصل ، مما يعني قضاء وقت أقل في الحافلة ووقتًا أطول للدراسة جالسًا على أريكتك ، وصوت المدفأة في الخلفية. لم يعد لديك ما يدعو للقلق بشأن فقدان فصل دراسي مهم",
  SuccessSectionCard3Title: "الانضباط الذاتي",
  SuccessSectionCard3Head: "#3 الانضباط الذاتي والمسؤولية",
  SuccessSectionCard3Description:
    "من قال أن الاضطرار إلى مزيد من الانضباط الذاتي هو عيب؟ الدراسة عبر الإنترنت تتطلب المزيد من مهارات إدارة الوقت ، لأنك ستقضي الكثير من الوقت بمفردك دون أن يكون هناك شخص قريب منك جسديًا للحفاظ على تركيزك على المواعيد النهائية.",
  SuccessSectionCard4Title: "استثمار",
  SuccessSectionCard4Head: "#4 استثمار",
  SuccessSectionCard4Description:
    "تعني الدراسة عبر الإنترنت أنك تدفع الرسوم الدراسية ، وربما لوازم الكتب ، ورسوم التقديم عبر الإنترنت ، وبعض التكاليف الأخرى. ومع ذلك ، لا تتحمل تكاليف السكن (التي يمكن أن تصل إلى 12000 دولار في السنة) والنقل ، مما يترجم إلى انخفاض الديون والمزيد من المدخرات",
  // Success Section End

  CoursesCount: "الكورسات المنتمية",

  // CTA Section Start
  CTASubTitle: " UH Training احصل على رقم شخصي من",
  CTATitle:
    " سجل على فصل عبر الإنترنت <br /> اليوم للاستمتاع بالحياة بشكل كامل",
  CTAGuide: "ابدأ الان مجاناً",
  // CTA Section End

  //  Faq Section Start
  FaqSectionSubTitle: "أربع خطوات رئيسية للنجاح",
  FaqSectionTitle: "أفضل الفرص لتوسيع <br /> نجاحك إلى أقصى الحدود",
  // Faq Section End

  // Blog Details Page Start
  BlogPageBlogDetails: "تفاصيل المدونة",
  BlogPagePopularPosts: "أشهر المنشورات",
  BlogPagePopularTags: "ذات صلة",
  BlogPageShareThis: "شارك هذا المنشور",
  BlogPageComments: "التعليقات",
  BlogPageReply: "رد",
  BlogPageThought: "شارك افكارك هنا",
  BlogPageEmailNote: "عنوان بريدك لن يتم نشره, الحقول * مطلوبة",
  BlogPageCashCredNode:
    "احفظ الاسم والبريد الالكتروني حتى لا ادخلهم عندما اعلّق المرة القادمة",
  // Blog Details Page End

  // Wishlist Page Start
  WishListStockStatus: "الحالة",
  WishListAddToCart: "اضافة الى السّلة",
  WishListAddToWishlist: "اضافة الى المفضلة",
  // Wishlist Page End

  // CheckOut Page Start
  CheckOutTitle: "الدفع",
  CheckOutSubTotal: "المبلغ بدون مصاريف الشحن",
  CheckOutShipping: "مصاريف الشحن",
  CheckOutPaymentMethod: "طرق الدفع",
  CheckOutInsideSyria: "داخل سوريا",
  CheckOutGlobal: "عالمي",
  CheckOutConditionsNote: "قرأت ووافقت على الشروط والقواعد",
  CheckOutOrder: "أرسل الطلب",
  // CheckOut Page End

  // Profile Start
  ProfileCoursesCompleted: "الدورات المنتهية",
  ProfileCoursesInProgress: "الدورات قيد التقدّم",
  ProfileQuizzesCompleted: "الاختبارات المنتهية",
  ProfileQuizzesInProgress: "الاختبارات قيد التقدّم",
  ProfileOwned: "مملوك",
  ProfilePurchased: "المشتريات",
  ProfilePassed: "تم الاجتياز",
  ProfileFailed: "راسب",
  ProfileCreateCourse: "إنشاء كورس",
  ProfileEditCourse: "تعديل الدورة",
  ProfileCreateTest: "إنشاء اختبار",
  ProfileEditProfile: "تعديل الملف الشخصي",
  ProfileBecomeStudent: "التحول إلى طالب",
  ProfileDeleteAccount: "حذف الحساب",
  ProfileRevieweMessage: "تتم مراجعة حسابك الآن",
  ProfileApproveMessage: "لم تتم الموافقة على معلومات حسابك",
  ProfileBlockMessage: "تم حظر حسابك لمدة <1>count</1> بسبب:",
  ToggleRole: "تبديل الدور",
  LessonsCount: "عدد الدروس",
  TestsCount: "عدد الاختبارات",
  // Profile End

  // Footer Start
  FooterAddress: "العناوين",
  FooterWidgetExplore: "استكشف",
  FootelListStart: "ابدأ هنا",
  FootelListSuccess: "قصة نجاح",
  FootelListBlog: "المدونة",
  FootelListCourses: "الدورات",
  FootelListAbout: "عنّا",
  FootelListContact: "تواصل معنا",
  FooterWidgetInformation: "معلومات",
  FootelListMembership: "العضوية",
  FootelListPurchase: "دليل الشراء",
  FootelListPrivacy: "سياسة الخصوصية",
  FootelListTerms: "شروط الخدمة",
  FooterRights: "جميع الحقوق محفوظة",
  // Footer End

  // Course Grid Start
  CourseGridSubTitle: "وجدنا {{count}} دورة متاحة من أجلك",
  CourseGridDefault: "افتراضي",
  CourseGridPopularity: "حسب الانتشار",
  CourseGridLatest: "الأحدث",
  CourseGridPriceLTH: "حسب السعر: تصاعدياّ",
  CourseGridPriceHTL: "حسب السعر: تنازلياّ",
  // Course Grid End

  // Teachers Grid Start
  TeachersGridSubTitle: "وجدنا {{count}} مدرب من أجلك",
  TeachersGridRating: "حسب التقييم",
  // Teachers Grid End

  // Quizzes Grid Start
  QuizzGridSubTitle: "وجدنا {{count}} مدرس من أجلك",
  // Quizzes Grid End

  // Blogs Grid Start
  BlogGridSubTitle: "وجدنا {{count}} مقالة متاحة من أجلك",
  // Blogs Grid End

  CaseGridSubTitle: "وجدنا {{count}} حالة من أجلك",

  // Course Details Start
  CourseDetails: "تفاصيل الدورات",
  CourseDetailsRelated: "الدورات المشابهة",
  CourseDetailsOverview: "ملخص",
  CourseDetailsCurriculum: "المنهاج الدراسي",
  CourseDetailsInstructor: "المدرب",
  CourseDetailsStudents: "طالب",
  CourseDetailsLessons: "دروس",
  CourseDetailsWeek: "أسبوع",
  CourseDetailsSearch: "ابحث",
  CourseDetailsRecentCourses: "الدورات الأخيرة",
  CourseDetailsBuyMembership: "شراء عضوية",
  CourseDetailsBuyCourse: "شراء هذه الدورة",
  CourseDetailsShareCourse: "مشاركة هذه الدورة",
  CourseDetailsReviews: "المراجعات",
  CourseDetailsAverageRating: "متوسط ​​التقييم",
  CourseDetailsDetailedRating: "التقييم بالتفصيل",
  CourseDetailsRating: "تقييمات",
  CourseDetailsDescription: "تفاصيل الدورة",
  CourseDetailsTests: "الاختبارات",
  CourseProgress: "التقدم في الدورة",
  NextLesson: "الدرس التالي",
  // Course Details End

  // Contact Us Start
  ContactUs: "تواصل معنا",
  ContactUsTitle:
    "لمزيد من المعلومات حول دوراتنا ، تواصل معنا عبر الطرق التالية ",
  ContactUsContact: "تواصل",
  ContactUsMobile: "هاتف",
  ContactUsHotline: "خط ساخن",
  ContactUsHour: "ساعات العمل",
  ContactUsFormTitle: "املأ هذه الاستمارة لحجز جلسة استشارية",
  // Contact Us End

  // Privacy Policy Start
  PrivacyPolicyApplicabled:
    "  UH Training تنطبق سياسة الخصوصية هذه على أي شخص قام بالتسجيل والمشاركة في الدورة التدريبية في مركز",
  PrivacyPolicyAvailability: "توافر الموقع",
  PrivacyPolicyMember:
    "من أجل شراء أي دورة أو أن تصبح عضوًا في مركزنا ، يجب على العملاء / المتعلمين التسجيل أولاً باستخدام حساب شخصي يوفر المعلومات التالية:",
  PrivacyPolicyRecognizes:
    "يقر العضو بأن حركة البيانات عبر الإنترنت قد تتسبب في حدوث تأخيرات <br /> أثناء تنزيل المعلومات من موقع الويب ، وبناءً عليه ، لن تتحمل الشركة مسؤولية التأخيرات العادية أثناء استخدام الإنترنت.",
  PrivacyPolicyAcknowledges:
    "كما يقر العضو ويوافق على أن الموقع الإلكتروني لن يكون متاحًا على مدار 24 ساعة متواصلة بسبب مثل هذه التأخيرات أو التأخيرات الناجمة عن التحديثات أو التعديلات أو تحديثات الصيانة الروتينية للموقع.",
  PrivacyPolicyProperty: "حقوق الملكية الفكرية",
  PrivacyPolicyProtected:
    "الدورات التدريبية عبر الإنترنت مملوكة للشركة ومحمية بموجب حقوق النشر الأمريكية والدولية والعلامات التجارية وبراءات الاختراع والأسرار التجارية وغيرها من قوانين حقوق الملكية الفكرية أو حقوق الملكية",
  PrivacyPolicyRight:
    "لا يتم نقل أي حق أو ملكية أو مصلحة في أو الى الدورة التدريبية عبر الإنترنت أو أي جزء منها إلى أي عضو ، وجميع الحقوق غير الممنوحة صراحةً هنا ، محفوظة من قبل الشركة",
  PrivacyPolicyCompany:
    "يُعد اسم الشركة وشعار الشركة وجميع الأسماء والشعارات وأسماء المنتجات والخدمات والتصاميم <br /> والشعارات ذات الصلة علامات تجارية مملوكة للشركة. لا يجوز للعضو استخدام هذه العلامات <br /> دون الحصول على إذن كتابي مسبق من الشركة",
  PrivacyPolicyObligations: "التزامات الشركة",
  PrivacyPolicyReasonable:
    "ستبذل الشركة جهودًا معقولة تجاريًا لتمكين الوصول إلى الدورة التدريبية عبر الإنترنت ، باستثناء الصيانة المجدولة والإصلاحات المطلوبة ، وباستثناء أي انقطاع ناتج عن أسباب خارجة عن السيطرة المعقولة أو غير متوقعة بشكل معقول من قبل الشركة",
  PrivacyPolicyGoverning: "القانون الحاكم ومكانه",
  PrivacyPolicyTerms:
    "تُفسّر شروط الخدمة هذه وتحكمها قوانين الولايات المتحدة الأمريكية.",
  PrivacyPolicyContract:
    "إذا كان أي من أحكام العقد ، كليًا أو جزئيًا ، غير صالح أو غير قابل للتنفيذ أو أصبح غير قابل للتنفيذ ، فلن يؤدي ذلك إلى إبطال الأحكام المتبقية منه",
  PrivacyPolicyEffective: "تاريخ النفاذ",
  // Privacy Policy End

  // Auth Form Start
  AuthFormLog: "تسجيل الدخول",
  LoginRegister: "انشاء حساب",
  AlreadyHaveAccount: "لديك حساب مسبقاً؟",
  DontHaveAccount: "ليس لديك حساب؟",
  LoginFormRegister: "إنشاء حساب",
  UpdateProfile: "تعديل الحساب",
  ResetPassword: "إعادة تعيين كلمة المرور",
  LoginFormRegisterInstructor: "انشاء حساب مدرس",
  ContinueRegisterInstructor: "اكمل ادخال المعلومات للتسجيل كمدرس",
  LoginFormNote:
    "يجب أن تتكون كلمة المرور من اثني عشر حرفًا على الأقل ، وأن تحتوي على أحرف كبيرة وصغيرة ، وتحتوي على أرقام ، وتحتوي على رموز",
  AuthFormUsername: "البريد الالكتروني",
  AuthFormRemember: "تذكرني",
  AuthFormLost: "هل نسيت كلمة المرور؟",
  // Auth Form End

  CourseShopSubTitle: "وجدنا {{count}} منتج متاح من أجلك",
  CartSummaryMessage: "<1>{{count}}</1>   :الإجمالي",

  // Global Start
  GlobalHome: "الصفحة الرئيسية",
  GlobalPrev: "السابق",
  GlobalNext: "التالي",
  GlobalLoadMore: "تحميل المزيد",
  GlobalMonday: "الاثنين ",
  GlobalFriday: "الجمعة",
  GlobalSunday: "الأحد",
  GlobalSaturday: "السبت",
  GlobalUsername: "اسم المستخدم",
  GlobalPassword: "كلمة المرور",
  GlobalOldPassword: "كلمة المرور الحالية",
  GlobalConfirmPassword: "تأكيد كلمة المرور",
  GlobalName: "الاسم",
  GlobalFirstName: "الاسم الأول بالعربية",
  GlobalFirstNameEnglish: "الاسم الأول بالإنجليزية",
  GlobalLastName: "الاسم الأخير بالعربية",
  GlobalLastNameEnglish: "الاسم الأخير بالإنجليزية",
  GlobalPhone: "الموبايل",
  GlobalMail: "البريد",
  GlobalAge: "العمر",
  GlobalAddress: "العنوان",
  GlobalSubject: "الموضوع",
  GlobalMessage: "رسالة",
  GlobalSubmit: "ارسال",
  GlobalYourComment: "تعليقك",
  GlobalTotal: "المبلغ كاملاً",
  GlobalImage: "الصورة",
  GlobalType: "النوع",
  GlobalProfileImage: "صورة البروفايل",
  GlobalProduct: "المنتج",
  GlobalPrice: "السّعر",
  GlobalRemove: "ازالة",
  GlobalOr: "أو",
  GlobalDashboard: "داشبورد",
  GlobalTestCorrection: "تصحيح الاختبارات",
  GlobalResults: "النتائج",
  GlobalQuizzes: "الاختبارات",
  GlobalQuiz: "الاختبار",
  GlobalAll: "الكل",
  GlobalPublish: "منشور",
  GlobalPending: "معلّق",
  GlobalFree: "مجاني",
  GlobalFinished: "منتهي",
  GlobalCourse: "الكورس",
  GlobalDate: "التاريخ",
  birthDate: "تاريخ الميلاد",
  GlobalAnsweredAt: "تاريخ الحل",
  GlobalCreatedAt: "تاريخ الإنشاء",
  GlobalDelete: "حذف",
  GlobalCategory: "الصنف",
  // Global End
  Certificates: "الشهادات",
  Male: "ذكر",
  Female: "أنثى",
  Gender: "الجنس",
  Videos: "فيديوهات",
  Readings: "مقالة",
  Audio: "تسجيل صوتي",
  Quizz: "اختبارات",
  Questions: "أسئلة",
  TypeMessagePlaceHolder:"اكتب رسالتك هنا",
  image: "صورة",
};

export default translationAr;

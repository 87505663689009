import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { vrcGroupResponse } from 'src/models/vrc';
import { getImageFromServer } from 'src/utils/helpers/get-image-from-server';
import { defaultTranslation } from 'src/utils/helpers/default-translation';
import { useAddGroupUsers } from 'src/hooks/query/vrc';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { fetchStudents } from 'src/services/students';
import { fetchInstructors } from 'src/services/instructors';

interface Props {
  open: boolean;
  onClose: () => void;
  groupId: number;
  activeConversation: vrcGroupResponse;
  onCloseParent: () => void;
}

export default function AddGroupMembersModal({
  open,
  onClose,
  groupId,
  activeConversation,
  onCloseParent
}: Props) {
  const handleClose = () => {
    onClose();
    setSelectedUsers([]);
  };

  const {
    data: students,
    isLoading: loadingStudents,
    isError,
    isFetching: fetchingStudents
  } = useQuery(['students'], () => fetchStudents());
  const {
    data: instructors,
    isLoading: loadingInstructors,
    isError: isErrorInstructors,
    isFetching: fetchingInstructors
  } = useQuery(['instructors'], () => fetchInstructors());

  const [filteredInstructors, setFilteredInstructors] = React.useState<any[]>(
    []
  );
  const [filteredStudents, setFilteredStudents] = React.useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);

  React.useEffect(() => {
    const filteredStudents = students?.filter(
      (student) =>
        !activeConversation.users.some((user) => user.id == student.id)
    );
    const filteredInstructors = instructors?.filter(
      (instructor) =>
        !activeConversation.users.some((user) => user.id == instructor.user_id)
    );

    setFilteredInstructors(filteredInstructors);
    setFilteredStudents(filteredStudents);
  }, [students, instructors, activeConversation]);
  const handleUserToggle = (userId: number) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const addGroupUsers = useAddGroupUsers();
  const queryClient = useQueryClient();

  const handleAddUsers = () => {
    addGroupUsers.mutate(
      {
        users: selectedUsers,
        id: groupId
      },
      {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success('Users Added Successfully');
          queryClient.invalidateQueries('groups');
          onClose();
          onCloseParent();
        }
      }
    );
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          {!loadingStudents && !loadingInstructors ? (
            <Stack flexDirection={'row'} gap={2}>
              <Stack
                sx={{
                  width: '50%',
                  maxHeight: 400,
                  overflowY: 'scroll'
                }}
              >
                <Typography variant={'h5'} sx={{ textAlign: 'center' }}>
                  Students
                </Typography>
                {filteredStudents?.length > 0 ? (
                  filteredStudents?.map((student) => (
                    <Stack
                      key={student.id}
                      flexDirection={'row'}
                      gap={1}
                      alignItems={'center'}
                      onClick={() => {
                        handleUserToggle(student.id);
                      }}
                      sx={{
                        cursor: 'pointer',
                        padding: 1,
                        transition: 'background-color 0.2s',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <Checkbox
                        checked={selectedUsers.includes(student.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUserToggle(student.id);
                        }}
                      />
                      <Avatar
                        src={getImageFromServer(student.profile_image)}
                        alt={
                          defaultTranslation(student, 'first_name') +
                          ' ' +
                          defaultTranslation(student, 'last_name')
                        }
                      />
                      <Typography>
                        {defaultTranslation(student, 'first_name') +
                          ' ' +
                          defaultTranslation(student, 'last_name')}
                      </Typography>
                    </Stack>
                  ))
                ) : (
                  <Typography sx={{ textAlign: 'center', pt: 2 }}>
                    No Students Available
                  </Typography>
                )}
              </Stack>
              <Stack
                sx={{
                  width: '50%',
                  maxHeight: 400,
                  overflowY: 'scroll'
                }}
              >
                <Typography variant={'h5'} sx={{ textAlign: 'center' }}>
                  Instructors
                </Typography>
                {filteredInstructors?.length > 0 ? (
                  filteredInstructors?.map((instructor) => (
                    <Stack
                      key={instructor.user_id}
                      flexDirection={'row'}
                      gap={1}
                      alignItems={'center'}
                      onClick={() => {
                        handleUserToggle(instructor.user_id);
                      }}
                      sx={{
                        cursor: 'pointer',
                        padding: 1,
                        transition: 'background-color 0.2s',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <Checkbox
                        checked={selectedUsers.includes(instructor.user_id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUserToggle(instructor.user_id);
                        }}
                      />
                      <Avatar
                        src={getImageFromServer(instructor.profile_image)}
                        alt={
                          defaultTranslation(instructor, 'first_name') +
                          ' ' +
                          defaultTranslation(instructor, 'last_name')
                        }
                      />
                      <Typography>
                        {defaultTranslation(instructor, 'first_name') +
                          ' ' +
                          defaultTranslation(instructor, 'last_name')}
                      </Typography>
                    </Stack>
                  ))
                ) : (
                  <Typography sx={{ textAlign: 'center', pt: 2 }}>
                    No Instructors Available
                  </Typography>
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack justifyContent={'center'} alignItems={'center'}>
              <CircularProgress size={32} />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {selectedUsers.length > 0 && (
            <LoadingButton
              onClick={handleAddUsers}
              color="success"
              variant="outlined"
              loading={addGroupUsers.isLoading}
            >
              Add Members
            </LoadingButton>
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

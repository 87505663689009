import { FC, useState, createContext } from 'react';
import * as React from 'react';
import { user } from '../models/user';

type AuthContext = {
  user: user;
  token: any;
  isAuth: boolean;
  login: any;
  logout: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthContext = createContext<AuthContext>({} as AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const initialToken = window.localStorage.getItem('token');
  const initialUser = JSON.parse(localStorage.getItem('user')!);
  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState(initialToken);

  const isAuth = !!token;

  const login = (user: any, token: any) => {
    setUser(user);
    setToken(token);
    window.localStorage.setItem('token', JSON.stringify(token));
    window.localStorage.setItem('user', JSON.stringify(user));
  };
  const logout = () => {
    setUser(null);
    setToken(null);
    window.localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, token, isAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

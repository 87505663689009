import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { testQuestion } from 'src/models/test';
import { RiDeleteBinLine } from 'react-icons/ri';

const Title = {
  padding: '8px',
  textAlign: 'center',
  position: 'stick'
};

interface Props {
  title: string;
  id: string;
  index: number;
  question_id: number;
  question: testQuestion;
  order: number;
  questionModalOpen: boolean;
  handleQuestionModalOpen: Function;
  handleQuestionModalClose: Function;
  setQuestionMethod: Function;
  setQuestionModalInfo: Function;
  questionReset: any;
  questionModalInfo: testQuestion;
  setType: Function;
  questionMethod: 'update' | 'create';
  handleQuestionDeleteModalOpen: Function;
}

const QuestionItem: React.FC<Props> = ({
  title,
  id,
  index,
  question_id,
  question,
  handleQuestionModalOpen,
  setQuestionMethod,
  setQuestionModalInfo,
  questionReset,
  questionModalInfo,
  setType,
  questionMethod,
  handleQuestionDeleteModalOpen
}) => {
  const [expande, setExpande] = useState(false);
  const handleResetOldValues = () => {
    const testData = {
      type: questionModalInfo.type,
      mark: questionModalInfo?.mark,
      id: questionModalInfo.id,
      order: questionModalInfo.order,
      questionable_id: questionModalInfo.questionable_id,
      name: questionModalInfo.translations?.ar?.name,
      name_en: questionModalInfo.translations?.en?.name,
      options: questionModalInfo.options?.length
        ? questionModalInfo.options.map((option) => ({
            id: option.id,
            question_id: option.question_id,
            key: option?.translations?.ar?.value,
            key_en: option?.translations?.en?.value,
            is_true: option.is_true
          }))
        : [],
      match: questionModalInfo.match_lefts?.length
        ? questionModalInfo.match_lefts.map((match) => ({
            match_left_id: match.id,
            question_id: match.question_id,
            match_right_id: match.match_rights[0].id,
            key: match.translations?.ar?.question,
            key_en: match.translations?.en?.question,
            value: match.match_rights[0].translations?.ar?.value,
            value_en: match.match_rights[0].translations?.en?.value
          }))
        : []
    };
    questionReset(testData); // This will reset the form to the testData values
  };

  useEffect(() => {
    if (questionModalInfo && questionMethod == 'update') {
      handleResetOldValues();
    }
  }, [questionModalInfo]);

  return (
    <>
      <Draggable draggableId={id} index={index} key={id}>
        {(provided) => (
          <Accordion
            {...provided.draggableProps}
            ref={provided.innerRef}
            expanded={expande}
          >
            <AccordionSummary {...provided.dragHandleProps}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography sx={Title} variant="h6">
                  {`#${index + 1}  ${title}`}{' '}
                  {question?.mark ? `/ ${question?.mark} Marks` : ''}
                </Typography>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setQuestionMethod('update');
                      setQuestionModalInfo(question);
                      setType(question.type);
                      handleQuestionModalOpen();
                    }}
                  >
                    <Typography fontSize="0.8rem" color="#fff">
                      {`Edit ${question.type} Question`}
                    </Typography>
                  </Button>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setQuestionModalInfo(question);
                      handleQuestionDeleteModalOpen();
                    }}
                    color="error"
                  >
                    <RiDeleteBinLine />
                  </IconButton>
                </Box>
              </Stack>
            </AccordionSummary>
          </Accordion>
        )}
      </Draggable>
    </>
  );
};

export default QuestionItem;
